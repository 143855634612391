@charset "utf-8";

.c-job-area-select {

  &__region-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__region-list\@desktop {
    @include mq($from: desktop) {
      flex-wrap: nowrap;
      width: 100%;
    }
  }

  &__region-item {
    padding-top: $space-l;
    flex: auto
  }

  &__region-item\@desktop {
    @include mq($from: desktop) {
      border-top: 1px solid $color-grey-6;
      flex: 1 1 calc(100% / 5);
      margin-right: $space-m;
      padding-top: $space-m;
    }
  }

  &__region-item\@mobile {
    @include mq($until: desktop) {
      flex: 0 1 100%;
      display: none;

      &.c-job-select__region-item--active {
        display: block;
      }
    }
  }
}
