@charset "utf-8";

.c-scout-balloon {
  padding-bottom: $space-m;

  &__inner {
    display: block;
    position: relative;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    color: $color-white;
    text-align: center;
    background: $color-primary;
    border-radius: $space-s;
    line-height: $line-height-l;
    padding: 0 $space-m;

    &::after {
      content: "";
      position: absolute;
      bottom: -$space-m;
      right: 0;
      left: 0;
      border-top: $space-s solid $color-primary;
      border-right: $space-s solid transparent;
      border-left: $space-s solid transparent;
      border-bottom: $space-s solid transparent;
      height: 0;
      width: 0;
      margin: auto;
    }
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      padding: 0 $space-s;
    }
  }

  &__inner--round {
    border-radius: $space-l;
  }
}
