@charset "utf-8";

.c-inner-check-list {
  border-radius: $space-s;
  background: $color-grey-6;

  &__item {
    border-top: 1px solid $color-white;
    transition: $btn-transition;
  }

  &__item:first-child {
    border: none;
    border-radius: $space-s $space-s 0 0;
    overflow: hidden;
  }

  &__item:last-child {
    border-radius: 0 0 $space-s $space-s;
    overflow: hidden;
  }

  &__item:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      background: $color-white;
    }
  }

  &__checkbox {
    font-size: $font-size-l;
    text-align: center;
    line-height: $line-height-l;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__checkbox-container--column {
    display: block;
    padding: $space-s $space-m;
  }

  &__label {
    flex-grow: 1;
    padding: $space-s $space-m;
    cursor: pointer;
    width: 100%;
  }

  &__label--space-none {
    padding: 0;
  }

  &__checkbox-input {
    display: none;
  }

  &__checkbox-mimic {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    transition: $btn-transition;
  }

  &__checkbox-mimic-inner {
    position: relative;
    display: block;
    flex-shrink: 0;
    width: $space-m;
    height: $space-m;
    border-radius: $space-xs;
    background: $color-grey-5;
    margin-right: $space-s;
  }

  &__checkbox-item-name {
    color: $color-placeholder;
    line-height: $line-height-l;
    text-align: left;
    font-weight: $font-weight-normal;
    transition: $btn-transition;
  }

  &__checkbox-item-name--small {
    opacity: $alpha-common;
    color: $color-grey-2;
    font-size: $font-size-m;
  }

  &__label:hover &__checkbox-item-name {
    @include mq($from: desktop) {
      color: $color-grey-0;
      font-weight: $font-weight-bold;
    }
  }

  &__checkbox-input:checked {
    background: $color-primary;
  }

  &__checkbox-input:checked ~ &__label &__checkbox-item-name {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__checkbox-input:checked + &__label &__checkbox-mimic > &__checkbox-mimic-inner {
    background: $color-primary;
    border: 1px solid $color-primary;
    position: relative;
    width: $space-m;
    height: $space-m;
    display: block;
  }

  &__checkbox-input:checked + &__label &__checkbox-mimic > &__checkbox-mimic-inner::before {
    position: absolute;
    left: 1px;
    top: 9px;
    display: block;
    content: '';
    width: 5px;
    height: 2px;
    background: $color-white;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  &__checkbox-input:checked + &__label &__checkbox-mimic > &__checkbox-mimic-inner::after {
    display: block;
    position: absolute;
    left: 5px;
    top: 9px;
    content: "";
    width: 8px;
    height: 2px;
    background: #FFFFFF;
    transform: rotate(-45deg);
    transform-origin: left center;
  }

  &__selectbox-container {
    display: flex;
    justify-content: center;
    pointer-events: none;
    margin: $space-s 0;
  }

  &__selectbox {
    margin-right: $space-s;
    display: flex;
    justify-content: center;
    position: relative;
    flex: 0 0 102px;
    pointer-events: none;
  }

  &__selectbox--space-none {
    margin-right: 0;
  }

  &__selectbox--large {
    flex: 1 0 50%;
  }

  &__checkbox-input:checked ~ &__selectbox,
  &__checkbox-input:checked ~ &__selectbox-container,
  &__checkbox-input:checked ~ &__selectbox-container > &__selectbox {
    pointer-events: auto;
  }

  &__selectbox::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: $space-m;
    transform: translateY(-50%) rotate($arrow-rotate);
    width: $space-s;
    height: $space-s;
    border-right: 2px solid $color-grey-3;
    border-bottom: 2px solid $color-grey-3;
  }

  &__selectbox-body {
    padding: 0 $space-xl 0 $space-m;
    background-color: transparent;
    border-radius: $space-s;
    display: flex;
    align-items: center;
    color: inherit;
    font-weight: $font-weight-bold;
    transition: $btn-transition;
    font-size: $font-size-s;
    width: 100%;
  }

  &__selectbox-body:hover {
    cursor: pointer;
    @include shadow-top;
    background-color: $color-white;
  }

  &__selectbox-body--large {
    padding: 0 $space-xl 0 $space-m;
    height: $space-3xl;
    font-size: $font-size-l;
  }

  &__checkbox-input ~ &__selectbox > &__selectbox-body {
    opacity: $alpha-common;
    font-weight: $font-weight-normal;
  }

  &__checkbox-input:checked ~ &__selectbox > &__selectbox-body {
    background-color: $color-white;
    opacity: 1;
    font-weight: $font-weight-bold;
    cursor: pointer;
  }

  &__checkbox-input ~ &__selectbox-container > &__selectbox > &__selectbox-body {
    opacity: $alpha-common;
    font-weight: $font-weight-normal;
  }

  &__checkbox-input:checked ~ &__selectbox-container > &__selectbox > &__selectbox-body {
    background-color: $color-white;
    opacity: 1;
    font-weight: $font-weight-bold;
    cursor: pointer;
  }

  &__checkbox-input:checked ~ &__selectbox-container &__label &__checkbox-mimic &__checkbox-item-name--small {
    opacity: 1;
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__textbox {
    width: 100%;
    height: $space-3xl;
    padding: 0 $space-m;
    background-color: $color-white;
    color: $color-grey-0;
    border-radius: $space-s;
    font-size: $font-size-l;
    font-weight: $font-weight-normal;
    transition: $btn-transition;
    opacity: $alpha-common;
    pointer-events: none;
  }

  &__checkbox-input:checked ~ &__textbox-container > &__textbox {
    opacity: 1;
    font-weight: $font-weight-bold;
    pointer-events: auto;

    &::-webkit-input-placeholder {
      color: $color-placeholder;
      font-weight: $font-weight-normal;
      line-height: normal;// https://stackoverflow.com/a/37799250
    }

    &::-moz-placeholder {
      color: $color-placeholder;
      font-weight: $font-weight-normal;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $color-placeholder;
      font-weight: $font-weight-normal;
    }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px $color-grey-6 inset;
    }

    &:focus {
      @include shadow-input;
      background-color: $color-white;
    }
  }
}
