@charset "utf-8";

.c-about-celebration-money {

  &__title {
    @include title-xl;
    padding: $space-l $space-m;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include title-2xl;
      line-height: 1;
      padding: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__body\@desktop {
    @include mq($from: desktop) {
      flex-direction: row-reverse;
      margin-top: -#{$font-size-2xl}
    }
  }

  &__img-wrapper {
    flex: 0 0 auto;
  }

  &__img-wrapper\@desktop {
    @include mq($from: desktop) {
      margin-left: $space-m;
      width: 297px;
    }
  }

  &__img {
    width: 100%;
  }

  &__text {
    font-size: $font-size-m;
    color: $color-grey-1;
    padding: $space-m;
    line-height: $line-height-m;
  }

  &__text\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-xl;
      padding: 0
    }
  }
}
