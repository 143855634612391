@charset "utf-8";

.c-additional-link {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__item {
    border-top: 1px solid $color-grey-6;
    padding: $space-l 0;

    &:first-child {
      border: none;
      padding: 0 0 $space-l;
    }

    &:last-child {
      padding: $space-l 0 0;
    }
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      justify-content: space-between;
      border-top: none;
      border-left: 1px solid $color-grey-6;
      padding: $space-l $space-m;
    }

    &:first-child {
      @include mq($from: desktop) {
        padding: $space-l $space-m $space-l 0;
      }
    }

    &:last-child {
      @include mq($from: desktop) {
        padding: $space-l 0 $space-l $space-m;
      }
    }
  }

  &__icon {
    margin: 0 auto;
    height: $space-2xl;
    width: $space-2xl;
  }

  &__title {
    text-align: center;
    margin-top: $space-m;
    @include title-l;
  }

  &__txt {
    color: $color-grey-2;
    font-size: $font-size-s;
    line-height: $line-height-s;
    margin-top: $space-m;
  }

  &__txt\@desktop {
    margin-top: $space-s;
  }

  &__txt--center {
    text-align: center;
    margin-top: $space-s;
  }

  &__note {
    color: $color-important;
    font-weight: $font-weight-bold;
  }

  &__btn-box {
    text-align: center;
    margin-top: $space-m;
  }

  &__btn {
    display: block;
    letter-spacing: 0;// winにて段落ちするための処置
  }

  &__qr-area {
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  &__qr-box {
    width: 78px;
    height: 78px;
  }

  &__qr {
    width: 100%;
  }

  &__qr-txt {
    margin: 0 0 0 $space-s;
  }

  &__line-link {
    display: block;
    text-align: center;
    font-size: $font-size-s;
  }
}
