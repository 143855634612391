@charset "utf-8";

.o-article {

  &__title + &__section {
    margin-top: $space-l;
  }

  &__title--small + &__text {
    margin-top: $space-s;
  }

  &__title--small + &__item {
    margin-top: $space-m;
  }

  &__section + &__section {
    margin-top: $space-m;
  }
}
