@charset "utf-8";

.c-arrowed-accordion {

  &__arrow {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: $space-xs;
    fill: $color-grey-3;
    width: $space-m;
    height: $space-m;
    transition: $btn-transition;
    transform-origin: center center;
  }

  &__title--open > &__arrow {
    transform: rotate($arrow-rotate-reverse);
  }

  &__link {
    display: block;
    position: relative;
    border-top: 1px solid $color-grey-6;
    color: $color-link;
    padding: $space-m $space-xl $space-m 0;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-card;
        border-radius: $space-s;
        color: $color-grey-0;
        text-decoration: none;
        padding-left: $space-m;

        .c-arrowed-accordion__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__item {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;

    &:last-child {
      border-bottom: 1px solid $color-grey-6;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
  }

  &__content {
    overflow: hidden;
    transition: $btn-transition;
    height: 0;
    background-color: $color-grey-6;
    border-radius: $space-s;
    font-weight: $font-weight-normal;
  }

  &__title--open + &__content--full {
    margin-bottom: $space-m;
  }

  &__content--transparent {
    background-color: transparent;
  }

  &__content-inner {
    overflow: hidden;
    padding: $space-m;
  }

  &__list {
    overflow: hidden;
    transition: $btn-transition;
    height: 0;
    border-radius: $space-s;
  }

  &__title--open {
    border-bottom: 0;
  }

  &__title--open + &__content {
    display: block;
    height: auto;
  }

  &__title--open + &__list {
    height: auto;
    margin-bottom: $space-m;
  }

  &__title--open + &__list--visible {
  }

  &--surround {
    border-top: 1px solid $color-grey-6;
    border-bottom: 1px solid $color-grey-6;
  }
}
