@charset "utf-8";

.c-average-salary {
  width: 100%;

  &__row {
    border-top: 1px solid $color-grey-6;
    display: grid;
    grid-template-columns: minmax(100px, 140px) 1fr;
    grid-template-areas:
      "c-average-salary__title c-average-salary__price"
      "c-average-salary__title c-average-salary__detail-box";
  }

  &__row:last-child {
    border-bottom: 1px solid $color-grey-6;
  }

  &__title {
    @include title-l;
    text-align: left;
    padding-right: $space-m;
    display: flex;
    align-items: center;
    grid-area: c-average-salary__title;
    vertical-align: middle;
  }

  &__price {
    display: block;//ie11でtable-cellにgridが効かない
    padding: $space-l 0 0;
    grid-area: c-average-salary__price;
  }

  &__price\@desktop {
    @include mq($from: desktop) {
      display: flex;
      padding: $space-m 0;
      align-items: flex-end;
      line-height: $line-height-m;
    }
  }

  &__average {
    display: flex;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__average-title {
    line-height: $line-height-m;
    white-space: nowrap;
  }

  &__average-price {
    display: flex;
    align-items: flex-end;
  }

  &__average-unit {
    display: block;
    margin-left: $space-xs;
    line-height: $line-height-m;
  }

  &__average-number {
    color: $color-attention;
    font-size: 46px;
    font-weight: $font-weight-bold;
    line-height: 1;
    padding-left: $space-s;
  }

  &__detail-box {
    display: block;//ie11でtable-cellにgridが効かない
    grid-area: c-average-salary__detail-box;
    padding-bottom: $space-l;
  }

  &__detail {
    font-size: $font-size-xs;
    color: $color-grey-1;
    display: flex;
    margin-top: $space-s;
    font-weight: $font-weight-bold;
    justify-content: space-between;
    width: 100%;
  }

  &__detail\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
      width: auto;
      justify-content: flex-start;
    }
  }

  &__max {
    padding-right: $space-xs;
  }

  &__min {
    padding-left: $space-xs;
  }

  &__max\@desktop {
    @include mq($from: desktop) {
      padding-right: $space-s;
      flex: 0 0 102px;
      white-space: nowrap;
    }
  }

  &__min\@desktop {
    @include mq($from: desktop) {
      padding-left: $space-m;
      border-left: 1px solid $color-grey-5;
      flex: 0 0 102px;
      white-space: nowrap;
    }
  }

  &__detail-title {
    font-weight: $font-weight-normal;
  }

  &__txt {
    width: 100%;
    font-size: $font-size-xs;
    color: $color-grey-2;
    margin-top: $space-s;
  }

  &\@desktop {
    @include mq($from:desktop) {

      .c-average-salary__row {
        display: table-row;
      }

      .c-average-salary__title {
        display: table-cell;
        white-space: nowrap;
      }

      .c-average-salary__price {
        padding: $space-l $space-m $space-l 0;
      }

      .c-average-salary__detail-box {
        display: table-cell;
        padding-bottom: 0;
      }
    }
  }
}
