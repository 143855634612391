@charset "utf-8";

.c-carousel {
  overflow: hidden;
  position: relative;
  height: 212px;

  &\@desktop {
    @include mq($from: desktop) {
      height: 353px;
    }
  }

  &__slide {
    overflow: hidden;
    position: relative;
    display: block;
    border-radius: 0;

    // siemaで、内包するitem数が100で割り切れないときにラッパー要素にフィットする幅を計算できず、隣接する画像が見えてしまうケースがある
    // 回避策として、ルートの要素の直下で100%より大きい幅になるようにして、各itemが可視領域より幅が狭い状態を作らないようにする
    width: calc(100% + 1px);
    height: 100%;
  }

  &__slide\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s;
    }
  }

  &__slide-img {
    max-width: 100%;
    overflow: hidden;
    background-color: $color-grey-7;
    flex-shrink: 0;
  }

  &__image-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 212px;
    background-color: $color-grey-7;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image-frame\@desktop {
    @include mq($from: desktop) {
      height: 353px;
    }
  }

  &__btn-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    border-top-left-radius: $space-s;
    border-bottom-left-radius: $space-s;
    background: rgba($color-grey-0, $alpha-common);
    height: $space-xl;
    width: $space-xl;
  }

  &__btn-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;
    border-top-right-radius: $space-s;
    border-bottom-right-radius: $space-s;
    background: rgba($color-grey-0, $alpha-common);
    height: $space-xl;
    width: $space-xl;
  }

  &__caption-box {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba($color-grey-0, $alpha-common);
    width: 100%;
  }

  &__caption-box\@desktop {
    @include mq($from: desktop) {
      border-radius: 0 0 $space-s $space-s;
    }
  }

  &__caption {
    padding: $space-s $space-m;
    color: $color-white;
    font-size: $font-size-s;
    text-overflow: clip;
    line-height: $line-height-m;
    text-align: center;
  }

  &__caption\@desktop {
    @include mq($from: desktop) {
      padding: $space-s $space-m;
    }
  }

  &__caption:empty {
    display: none;
  }
}
