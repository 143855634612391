@charset "utf-8";

.c-search-condition-button-group {
  display: flex;
  justify-content: space-between;

  &__item {
    border-radius: $space-s;
    background: $color-grey-6;
    flex: 1 1 0;
  }

  &__item + &__item {
    margin-left: $space-s;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $space-l $space-s;
    color: $color-link;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top;
        background: $color-white;
        color: $color-grey-0;
        border-radius: $space-s;
      }
    }
  }

  &__icon {
    @include icon-l();
    display: block;
  }

  &__text {
    font-weight: $font-weight-bold;
    text-align: center;
    margin-top: $space-s;
  }

  &__text\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-m - $space-xs;
    }
  }
}
