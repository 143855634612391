@charset "utf-8";

.c-setting-detail {
  font-size: $font-size-m;
  color: $color-grey-1;
  font-weight: $font-weight-normal;

  &__content {
    margin-top: $space-m;
    padding: 0 0 $space-l;
    border-top: 1px solid $color-grey-4;
    border-bottom: 1px solid $color-grey-4;
  }

  &__item {
    padding: $space-l 0;
  }

  &__item + &__item {
    border-top: 1px solid $color-grey-4;
  }

  &__item:last-child {
    padding-bottom: 0;
  }

  &__head {
    font-size: $font-size-m;
    text-align: left;
  }

  &__head--warn {
    color: $color-important;
    text-align: center;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
  }

  &__title {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__sub-title {
    margin-top: $space-s;
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__txt {
    margin-top: $space-s;
    line-height: $line-height-m;
  }

  &__strong {
    color: $color-important;
  }

  &__link {
    font-size: $font-size-s;
    text-align: right;
    margin-top: $space-m;
  }

  &__small-txt {
    @include par-s;
    color: $color-grey-2;
  }

  &__input-box {
    margin-top: $space-s;
  }

  &__input-box + &__small-txt {
    margin-top: $space-s;
  }

  &__mail-address {
    color: $color-grey-0;
  }

  &__button-area {
    margin-top: $space-m;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button-area\@desktop {
    @include mq($from: desktop) {
      justify-content: center;
      flex-direction: row;
    }
  }

  &__button {
    align-self: center;
  }

  &__button--large {
    align-self: stretch;
  }

  &__button\@desktop {
    @include mq($from: desktop) {
      align-self: center;
    }
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    .c-setting-detail__button--large + .c-setting-detail__button {
      margin-top: $space-s;
    }
  }

  &__button-group\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      width: auto;

      .c-setting-detail__button--large + .c-setting-detail__button {
        margin: 0 0 0 $space-s;
      }
    }
  }

  &__list {
    margin-top: $space-s;
    list-style-type: disc;
    list-style-position: inside;
    line-height: $line-height-m;
  }

  &__message-link {
    margin-left: $space-l;
  }
}
