@charset "utf-8";

.default-background-color {
  background-color: #FFFFFF;
}

.steps-background-color {
  background-color: #FAF7F0;
}

.c-landing-page-dental-users {
  $white: #FFFFFF;
  $black: #222222;
  $gray:  #888888;
  $primary: #39BFB9;
  $back: #FAF7F0;
  $text-link: #40BFB9;
  letter-spacing: 0.01em;
  line-height: 1.4;

  header {

    .logo {
      position: absolute;
      z-index: 10;
      padding: 12px 0 0 0;
      @include mq($from: desktop) {
        padding: 24px 0 0 0;
      }

      &-img {
        height: 36px;
      }

      &--position-unset {
        position: unset;
      }
    }
  }

  .o-wrapper__head {
    display: none;
  }

  .o-wrapper__foot,
  .c-pagetop {
    display: none;
  }

  .o-container__column {
    padding-left: 16px;
    padding-right: 16px;
    @include mq($from: desktop) {
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  .l-container {
    width: 100%;
    @include mq($from: desktop) {
      max-width: 1288px;
      margin: auto;
    }
  }

  .c-heading--3xl {
    font-size: 26px;
  }

  .c-button-users {
    height: 100%;
    align-self: center;
    border-radius: 32px;
    padding: 16px 68px;
    position: relative;
    width: 100%;
    @include mq($from: desktop) {
      width: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      background-color: #FF7B97;
      border-radius: 50vh;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 23px;
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      border-top: 2px solid $white;
      border-right: 2px solid $white;
    }
  }

  .c-mainvisual {
    background: $white;

    @include mq($from: desktop) {
      height: 540px;
    }

    &__inner {
      padding: 0;
      width: 100%;
      @include mq($from: desktop) {
        width: 55%;
        margin-top: -378px;
      }

      &-Ttl {
        color: $primary;
        font-size: 36px;
        line-height: 1.2;
        font-weight: 600;
        text-shadow: 0 0 30px rgba(255, 255, 255, 0.4);
        margin: 0 0 16px 0;
        @include mq($from: desktop) {
          font-size: 46px;
          margin: 0 0 24px 0;
        }
      }

      &-Txt {
        color: $black;
        font-size: 16px;
        margin: 0 0 32px 0;
        text-shadow: 0 0 8px rgba(255, 255, 255, 1);
      }
    }

    &__search {
      box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      background: $white;
      @include mq($from: desktop) {
        background: $white;
        margin: 48px 0 0 0;
      }
    }
  }

  .about {
    background: $back;
    padding-bottom: 2px;
    @include mq($from: desktop) {
      height: 460px;
      margin: 150px auto 0;
      border-radius: 28px;
    }

    &-header {
      display: block;
      @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
      }

      a {
        align-self: flex-end;
      }
    }

    &-Ttl {
      padding: 50px 0 0;
      @include mq($from: desktop) {
      }

      h3 {
        color: $primary;
        padding: 0 0 12px 0;
      }
    }

    &-cards {
      display: block;
      @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
      }
      margin: 32px 0 50px;

      &-item {
        background: $white;
        padding: 16px;
        border-radius: 20px;
        box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.06);
        margin: 20px 0 24px;
        @include mq($from: desktop) {
          width: 33.33%;
          margin: 0 32px 0 0;

          &:last-child {
            margin: 0;
          }
        }

        img {
          width: 100%;
          border-radius: 12px;
        }

        &-text {
          padding: 0 8px;
          letter-spacing: 0.2px;

          h3 {
            text-align: center;
            margin: 24px 0 0 0;
          }

          p {
            margin: 12px 0 0 0;
          }
        }
      }
    }
  }

  .howto {
    margin: 32px 0 0 0;
    @include mq($from: desktop) {
      margin: 348px 0 0 0;
    }

    &-Ttl {
      padding: 0 0 24px 0;
      @include mq($from: desktop) {
        padding: 0 0 40px 0;
      }

      h2 {
        @include mq($from: desktop) {
          text-align: center;
        }
      }

      p {
        @include mq($from: desktop) {
          text-align: center;
        }
      }

      span {
        color: $primary;
      }
    }

    &-img {
      width: 350px;
      border-radius: 16px;
      margin: 0 0 28px 20px;
      box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.06);
    }

    .c-flow-list__detail {
      margin-bottom: 20px;
      @include mq($from: desktop) {
        margin-bottom: 0;
      }
    }

    &-link {
      color: $text-link;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .c-steps {
    background-color: $back;
    margin: 8px auto 0;
    padding: 48px 0 30px;
    @include mq($from: desktop) {
      margin: 82px auto 0;
      border-radius: 28px;
      padding-top: 48px;
    }

    &__content {
      padding: 16px;
      border-radius: 16px;
      background: white;
      box-shadow: 8px 8px 80px 0 rgba(0, 0, 0, 0.06);
      @include mq($from: desktop) {
        padding: 36px 64px;
        display: inline-block;
        width: 100%;
      }
    }

    &__item-title {
      font-size: 14px;
    }

    &__caution {
      font-size: 12px;
    }

    .c-step-box {

      &__item::after {
        position: absolute;
        z-index: 0;
        top: 13px;
        left: -80%;
        width: 120%;
        height: 2px;
        content: '';
        background-color: $color-grey-5;
      }

      &__item:first-child::after {
        content: none;
      }

      &__item--active,
      &__item--complete {
        color: $color-grey-3;
      }

      &__item--active::before,
      &__item--complete::before {
        background-color: $color-primary;
        color: $color-white;
      }

      &__item--active::after,
      &__item--complete::after {
        background-color: $color-primary;
      }
    }
  }

  .c-footer {
    padding: 20px 0 0;
    margin: 0 auto;
    @include mq($from: desktop) {
      padding: 40px 0 0 0;
    }

    &__inner {
      display: block;
      width: 90%;
      margin: 0 auto;
      @include mq($from: desktop) {
        padding-top: 25px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__logo {
      width: 122px;
      margin: 25px auto 0;
      @include mq($from: desktop) {
        margin: 0;
      }
    }

    &__copy {
      font-size: 12px;
      font-weight: 300;
      color: $gray;
      text-align: center;
      margin-top: 25px;
      @include mq($from: desktop) {
        text-align: left;
        margin-top: 0;
      }
    }

    &__link {
      display: block;
      @include mq($from: desktop) {
        display: flex;
        gap: 6px;
      }

      &__item {
        font-size: 12px;
        display: block;
        text-align: center;
        margin-top: 25px;

        &:last-child {
          margin-left: 0;
        }
        @include mq($from: desktop) {
          display: inline-block;
          margin-top: 0;

          &:last-child {
            margin-left: 10px;
          }
        }

        &__text {
          color: $text-link;
          font-weight: 600;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__company {
      font-size: 12px;
      font-weight: 300;
      color: $gray;
      text-align: center;
      padding: 25px 16px 100px;
      @include mq($from: desktop) {
        text-align: center;
        padding: 30px 0 40px;
      }
    }
  }
}
