@charset "utf-8";

.c-search-grid {
  display: grid;
  grid-auto-rows: min-content;
  background-color: $color-white;

  &\@desktop {
    @include mq($from: desktop) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: $space-s;
    }
  }

  &--four-columns\@desktop {
    @include mq($from: desktop) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__item {
    display: flex;
    align-items: stretch;
    border-top: 1px solid $color-grey-6;
  }

  &__item:last-child {
    border-bottom: 1px solid $color-grey-6;
  }

  &\@desktop > &__item {
    @include mq($from: desktop) {
      border-bottom: 1px solid $color-grey-6;
    }
  }

  &\@desktop > &__item:nth-child(n+4) {
    @include mq($from: desktop) {
      margin-top: -1px;
    }
  }
}

@media all and (-ms-high-contrast: none) {

  .c-search-grid {
    display: flex;
    flex-wrap: wrap;

    &__item {
      $total_margin: ($space-s * 2) + 1;
      width: calc((100% - #{ $total_margin }) / 3);
      margin-left: $space-s;

      &--four-columns {
        $total_margin: ($space-s * 3) + 1;
        width: calc((100% - #{ $total_margin }) / 4 - #{ $space-xs });
      }
    }

    &__item:nth-child(3n+1) {
      margin-left: 0;
    }
  }
}
