@charset "utf-8";

.c-operator-login-label {

  &__bg {
    position: fixed;   /* 常に画面に固定 */
    top: 0;           /* 画面上端に配置 */
    right: 0;         /* 画面右端に配置 */
    width: 250px;
    height: 140px;
    background-color: #ED5F78;
    z-index: 9999;    /* 最前面に表示 */
    color: white;
    font-size: 16px;
    font-weight: bold;
  }

  &__id {
    padding-top: 22px;
    padding-left: 16px;
  }

  &__name {
    padding-top: 12px;
    padding-left: 16px;
  }

  &__button {
    margin-top: 16px;
    margin-left: 16px;
    padding: 8px 16px !important;
    background-color: #FDEFF1;
    color: #ED1D58;
    text-decoration: underline;
  }
}
