@charset "utf-8";

.c-dropdown {
  position: relative;

  &__button {
    background-color: $color-grey-6;
    border-radius: 8px;
    border: 1px solid $color-grey-5;
    color: $color-link;
    font-size: $font-size-s;
    font-weight: 600;
    padding: $space-s $space-l $space-s 10px;
    display: inline-block;
    cursor: pointer;
    transition: $btn-transition;

    &:hover {
      background: $color-white;
      color: $color-grey-0;
      @include shadow-top;
    }
  }

  &__arrow {
    pointer-events: none;
    fill: $color-grey-3;
    position: absolute;
    width: 12px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__modal {
    position: absolute;
    left: 0;
    top: 33px;
  }

  .c-sort-options {
    background-color: white;
    width: 140px;
  }

  .c-sort-options-modal {
    z-index: 5;
    background-color: white;
    max-height: 100px;
    border-radius: 8px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    width: 140px;

    &__item {
      font-size: $font-size-s;
      color: $color-grey-1;
      padding: $space-s $space-s;
      font-weight: normal;
      cursor: pointer;

      label {
        display: flex;
      }

      &:first-child {
        padding-top: 10px;
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        padding-bottom: 10px;
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        background: $color-grey-6;
      }
    }
  }

  .c-distance-options {
    display: flex;
    align-items: center;
    height: 48px;
    font-size: 16px;
    white-space: nowrap;
    color: #222;
  }

  .c-distance-options-city-not-selected {
    pointer-events: none;
    color: $color-grey-3;
    font-weight: $font-weight-normal;
  }

  .c-distance-options-modal {
    padding-bottom: 5px;
    top: 47px;
    z-index: 5;
    background-color: white;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    width: 100%;

    &__item {
      font-size: 16px;
      color: $color-link;
      font-weight: $font-weight-bold;
      cursor: pointer;
      padding: $space-s $space-m;
      @include mq($from: desktop) {
        padding: $space-m;
      }

      label {
        display: flex;
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        padding-bottom: 10px;
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        background: $color-grey-6;
      }
    }

    &__category-not-selected {
      font-size: 16px;
      color: $color-grey-1;
    }
  }

  .c-dropdown-modal {
    z-index: 5;
    background-color: white;
    max-height: 248px;
    border-radius: 8px;
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.08);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: max-content;

    &__item {
      font-size: $font-size-s;
      color: $color-grey-1;
      padding: $space-s $space-m;
      font-weight: normal;
      cursor: pointer;

      &:first-child {
        padding-top: 10px;
      }

      &:last-child {
        padding-bottom: 10px;
      }

      label {
        display: flex;
      }

      &:hover {
        background: $color-grey-6;
      }
    }
  }
}

.toggle-arrow {
  transform: translateY(-50%) rotate($arrow-rotate-reverse) !important;
}
