@charset "utf-8";

.c-profile-edit-group {
  background: $color-primary;
  padding: $space-l $space-m;
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s;
      padding: $space-l;
      flex-direction: row;
      align-items: center;
    }
  }

  &__item {
    flex: 1 1 calc(100% / 2);
  }

  &__item\@desktop:last-child {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }

  &__title {
    @include title-xl;
    color: $color-white;
  }

  &__lead {
    @include par-m;
    color: $color-white;
    margin-top: $space-m;
  }

  &__lead\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-s;
    }
  }

  &__button {
    margin-top: $space-m;
  }

  &__button\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }
}
