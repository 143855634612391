@charset "utf-8";

button,
[type="button"],
[role="button"] {
  appearance: none;
  outline: 0;
  border: none;
  cursor: pointer;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: initial;
}
