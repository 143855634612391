@charset "utf-8";

.c-footer {
  background: $color-white;
  padding: $space-xl 0;

  &__inner\@mobile {
    @include mq($until: desktop) {
      padding: 0 $space-m;
    }
  }

  &__logo {
    width: 123px;
    height: 30px;
    fill: $color-primary;
  }

  &__logo\@desktop {
    @include mq($from: desktop) {
      width: 140px;
      height: 34px;
    }
  }

  &__copyright {
    margin-top: $space-xl;
    text-align: center;
  }

  &__copyright\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
      text-align: left;
    }
  }

  &__minimum-copyright {
    text-align: left;
  }

  &__minimum-copyright\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
      text-align: left;
    }
  }
}
