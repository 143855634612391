@charset "utf-8";

.c-job-select {

  &__region-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__region-list\@desktop {
    @include mq($from: desktop) {
      width: 100%;
      padding: 0;
      padding-bottom: $space-xl;
    }
  }

  &__region-item {
    border-top: 1px solid $color-grey-6;
  }

  &__region-item\@desktop {
    @include mq($from: desktop) {
      width: calc((100% - #{$space-m} * 3) / 2);
      margin-right: $space-m;
      border-top: 0;
      border-bottom: 1px solid $color-grey-6;
    }
  }

  &__region-item\@desktop:nth-child(2n) {
    @include mq($from: desktop) {
      margin-right: 0;
    }
  }

  &__region-item\@desktop:nth-child(-n+2) {
    @include mq($from: desktop) {
      border-top: 1px solid $color-grey-6;
    }
  }

  &__region-item\@mobile {
    @include mq($until: desktop) {
      flex: 0 1 100%;
      display: none;

      &.c-job-select__region-item--active {
        display: block;
      }
    }
  }
}
