@charset "utf-8";

.c-leadvisual {
  background: $color-primary;

  &__img {
    width: 100%;
  }

  &__txt-area {
    padding: $space-l $space-m;
  }

  &__heading {
    color: $color-white;
    font-size: $font-size-2xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
    margin-bottom: $space-m;
  }

  &__txt {
    color: $color-white;
    font-size: $font-size-m;
    line-height: $line-height-m;
  }

  &__txt--xs {
    margin-top: $space-l;
  }

  &__link {
    color: $color-white;
    font-size: $font-size-m;
    line-height: $line-height-m;
    text-decoration: underline;
  }

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-direction: row-reverse;
      border-radius: $space-s $space-s 0 0;
      overflow: hidden;

      .c-leadvisual__img-area {
        flex-shrink: 0;
      }

      .c-leadvisual__txt-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        padding: $space-m $space-3xl;
      }
    }
  }

  &--red {
    background: rgba($color-important, $alpha-high-common);
  }

  &--yellow {
    background: $color-lead-yellow;

    .c-leadvisual__heading {
      color: $color-grey-0;
    }

    .c-leadvisual__txt {
      color: $color-grey-1;
    }
  }

  &__usage-image {
    @media screen and (min-width: 320px) {
      width: 136px;
    }

    @media screen and (min-width: 375px) {
      width: 163px;
    }

    @media screen and (min-width: 414px) {
      width: 183px;
    }
  }
}
