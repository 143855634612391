@charset "utf-8";

.c-tag-list {
  display: flex;
  flex-wrap: wrap;
  line-height: $line-height-xl;
  margin-top: -$space-xs;

  &--center {
    justify-content: center;
  }

  &__item {
    margin-right: $space-s;
  }

  &__item:last-child {
    margin-right: 0;
  }
}

// flex-wrap with display: table not work properly on less than IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

  .c-tag-list {
    display: block;
    overflow: hidden;
    flex-wrap: unset;

    &__item {
      display: block;
      float: left;
    }
  }
}
