@charset "utf-8";

.c-notification-bar {
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: $color-white;
  padding: $space-m;
  z-index: index($zindex, toaster);
  transition: $btn-transition;

  // tabletでのレイアウト崩れ回避
  @include mq(desktop, $tablet-portrait-width) {
    width: 100%;
  }

  &\@desktop {
    @include mq($from: desktop) {
      padding: $space-l 0;
    }
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-column;
      margin: 0 auto;
    }
  }

  &__icon {
    @include icon-l;
    display: block;
    flex: 0 0 $space-l;
  }

  &__icon--important {
    fill: $color-important;
  }

  &__icon--primary {
    fill: $color-primary;
  }

  &__message {
    @include title-l;
    color: $color-grey-0;
    margin-left: $space-m;
  }
}
