@charset "utf-8";

.c-log-in-button-group {
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__link {
    font-size: $font-size-s;
    text-align: center;
    margin-top: $space-m;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      text-align: left;
      margin-top: 0;
      margin-left: $space-m;
    }
  }
}
