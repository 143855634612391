@charset "utf-8";

.c-selectbox {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: $space-m;
    transform: translateY(-50%) rotate($arrow-rotate);
    border-color: $color-grey-3;
    width: $space-s;
    height: $space-s;
    border-width: 0 2px 2px 0;
    border-style: none solid solid none;
  }

  &__body {
    padding: 0 $space-xl 0 $space-m;
    height: $space-3xl;
    background-color: $color-grey-6;
    border-radius: $space-s;
    display: flex;
    align-items: center;
    color: inherit;
    font-weight: $font-weight-bold;
    transition: $btn-transition;
    font-size: $font-size-l;
    width: 100%;
    line-height: 1;
  }

  &__body:hover {
    @include mq($from: desktop) {
      cursor: pointer;
      @include shadow-top;
      background-color: $color-white;
    }
  }

  &__body:focus {
    @include shadow-top;
    background-color: $color-white;
  }

  &--error > .c-selectbox__body {
    background: rgba($color-important, 0.25);
  }

  &__body--disable {
    opacity: $alpha-low-common;
    pointer-events: none;
  }

  &--full {
    width: 100%;
  }

  &__body--white {
    background-color: $color-white;
    opacity: 1;
    font-weight: $font-weight-bold;
    cursor: pointer;
  }
}
