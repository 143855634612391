@charset "utf-8";

.c-job-tab {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &__shadow\@mobile {
    @include mq($until: desktop) {
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        bottom: -#{$space-m};
        @include gradient-bottom;
      }
    }
  }

  &__wrapper {
    display: table;
    padding: 0 $space-m;
  }

  &__wrapper\@desktop {
    @include mq($from: desktop) {
      display: flex;
      padding: 0;
    }
  }

  &__item {
    display: table-cell;
    white-space: nowrap;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      display: block;
      flex: 1 1 calc(100% / 5);
      margin-right: $space-m;
    }
  }

  &__item\@desktop:first-of-type {
    @include mq($from: desktop) {
      margin-left: $space-s;
    }
  }

  &__item\@desktop:last-of-type {
    @include mq($from: desktop) {
      margin-right: $space-s;
    }
  }

  &__link {
    display: block;
    border-bottom: 0 solid $color-primary;
    font-weight: $font-weight-bold;
    line-height: 1;
    transition: $border-transition;
    text-align: center;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      pointer-events: none;
      color: $color-grey-0;
      font-size: $font-size-2xl;
      padding: $space-l $space-xs;
    }
  }

  &__link\@mobile {
    @include mq($until: desktop) {
      color: $color-link;
      font-size: $font-size-l;
      padding: $space-l $space-m;

      &.c-job-tab__link--active {
        border-bottom: $space-xs solid $color-primary;
        color: $color-grey-0;
      }
    }
  }

  &__link:hover {
    @include mq($from: desktop) {
      color: $color-grey-0;
    }
  }
}
