@charset "utf-8";

.c-mission-thought-detail {
  padding: $space-l $space-m;

  &__small-title {
    @include title-m;
  }

  &__txt {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-l;
    margin-top: $space-m;
  }

  &__sign {
    margin-top: $space-m;
    text-align: right;
  }

  &__name {
    color: $color-grey-0;
    font-size: $font-size-2xl;
    font-weight: $font-weight-bold;
  }

  &\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }
}
