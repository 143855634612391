@charset "utf-8";

.c-modal-small {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: index($zindex, modal);
  opacity: 1;
  transition: $btn-transition;
  transform: translateY(0);

  &--close {
    transform: translateY(200%);
  }

  &__frame {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100%;
    z-index: index($zindex, modal_frame);
    border-radius: $space-s;
    align-items: center;
    justify-content: center;
  }

  &__frame\@desktop {
    @include mq($from: desktop) {
      min-width: $width-desktop-container;
      align-items: center;
      justify-content: center;
    }

    // tabletでのレイアウト崩れ回避
    @include mq(desktop, $tablet-portrait-width) {
      min-width: $width-desktop-column;
    }
  }

  &__body {
    @include shadow-top;
    background-color: #FFF;
    position: relative;
    top: $space-m;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: calc(100vw - #{ $space-l * 2 });
    height: auto;
    max-height: 100%;
    border-radius: $space-s;
  }

  &__body\@desktop {
    @include mq($from: desktop) {
      width: $width-modal-small;
    }
  }

  &__closer {
    position: absolute;
    top: $space-m;
    right: $space-m;
    z-index: index($zindex, modal_closer);
  }

  &__button-close {
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top-green;
      }
    }
  }

  &__title {
    @include title-xl;
    padding: $space-l $space-3xl $space-l $space-l;
    line-height: $line-height-m;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include title-2xl;
      line-height: $line-height-m;
    }
  }

  &__content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: $space-3xl $space-l $space-l;
  }

  &__title + &__content {
    padding: 0 $space-l $space-l;
  }

  &__content--l {
    padding: $space-3xl $space-l;
  }

  &__icon {
    @include icon-xl;
    display: block;
  }

  &__icon--attention {
    fill: $color-attention;
  }

  &__icon--important {
    fill: $color-important;
  }

  &__icon--primary {
    fill: $color-primary;
  }

  &__text {
    @include title-xl;
    color: $color-grey-0;
  }

  &__text--attention {
    @include title-2xl;
    color: $color-attention;
  }

  &__checkbox-text {
    @include title-s;
    color: $color-link;

    &--black {
      color: #000;
    }
  }

  &__form-button-center {
    text-align: center;
  }
}
