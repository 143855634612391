@charset "utf-8";

.c-guide-merits {
  line-height: $line-height-m;

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__list\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__item {
    text-align: center;
    width: 100%;
  }

  &__item + &__item {
    margin-top: $space-l;
  }

  &__list\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin: 0 0 0 $space-2xl;
    }
  }

  &__img {
    display: flex;
    height: 202px;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    margin-top: $space-s;
  }

  &__text {
    font-size: $font-size-m;
    margin-top: $space-s;
    color: $color-grey-1;
    text-align: left;
  }

  &__small-text {
    font-size: $font-size-xs;
    color: $color-grey-1;
    margin-top: $space-xs;
    text-align: left;
  }

  &__attention {
    font-size: $font-size-xs;
    color: $color-grey-1;
    margin-top: $space-m;
  }

  &__attention\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-l;
    }
  }
}
