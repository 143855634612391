@charset "utf-8";

.c-user-voice-search-contents {

  &__graph {
    margin-top: $space-m;
  }

  &__graph\@mobile {
    @include mq($until: desktop) {
      height: 480px;
    }
  }

  &__rating {
    text-align: center;
    margin-top: $space-m;

    span {
      font-size: $font-size-s;
      font-weight: $font-weight-bold;
    }

    strong {
      color: $color-attention;
      font-size: 46px;
      font-weight: $font-weight-bold;
      line-height: 1;
      padding-left: $space-s;
    }
  }

  &__item {
    border-top: 1px solid $color-grey-6;
    padding-top: $space-m;
  }

  & .c3-axis-x text {
    color: $color-grey-0;
    line-height: 12px;
    font-size: $font-size-xs;
  }
}
