@charset "utf-8";

.c-resume-list {

  &__item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px  solid $color-grey-6;
    padding: $space-s 0;

    &:first-child {
      border-top: 1px solid $color-grey-6;
    }
  }

  &__info {
    overflow: hidden;
  }

  &__name {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
    white-space: nowrap;
  }

  &__date {
    color: $color-grey-2;
    font-size: $font-size-xs;
    line-height: $line-height-m;
  }

  &__buttons {
    margin-top: $space-xs;
  }

  &__menus {
    display: flex;
  }

  &__menu-item {
    position: relative;
    white-space: nowrap;
    margin: 0 0 0 $space-s;
  }
}
