@charset "utf-8";

.c-speed-reply-badge {
  padding: $space-s;
  border-radius: $space-s;
  background-color: rgba($color-important, $alpha-low);
  font-size: $font-size-s;
  line-height: $line-height-s;

  &__icon {
    width: 30px;
  }

  &__title {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__txt {
    color: $color-grey-2;
  }
}
