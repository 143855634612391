@charset "utf-8";

.c-form-fieldset {
  display: flex;
  flex-direction: column;

  &--full {
    width: 100%;
  }

  &__error-message {
    color: $color-important;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-top: -$space-s;
    margin-bottom: $space-s;
    @include mq($from: desktop) {
      margin-top: $space-s;
      margin-bottom: 0;
    }
  }

  &__error-message + &__body,
  &__body + &__error-message {
    margin-top: $space-xs;
  }

  &__error-message:empty + &__body,
  &__body + &__error-message:empty {
    margin-top: 0;
  }

  &__body--inline {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }
}
