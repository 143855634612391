@charset "utf-8";

.c-form-title {

  &\@desktop {
    @include mq($from: desktop) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__btn-area {
    margin: $space-s 0 0;
  }

  &__btn-area\@desktop {
    @include mq($from: desktop) {
      margin: 0;
    }
  }
}
