@charset "utf-8";

.c-map-search-title {
  position: relative;
  margin-top: $space-m;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    padding: $space-l $space-m;
    border-bottom: solid 1px $color-grey-5;
  }

  &__heading\@desktop {
    @include mq($from: desktop) {
      width: 100%;
      padding: $space-m $space-l;
      border-bottom: 0;
    }
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-s 0 $space-s 0;
    font-size: $font-size-s;
    letter-spacing: $letter-spacing-wide;
    font-weight: $font-weight-normal;
    line-height: $line-height-s;
    color: $color-grey-1;
  }

  &__headline\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-s;
    }
  }

  &__result {
    white-space: nowrap;
    text-align: right;
  }

  &__inner\@mobile {
    @include mq($until: desktop) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__search_condition {
    font-size: $font-size-s;
  }

  &__corresponding {
    @include title-xs;
  }

  &__corresponding\@desktop {
    @include mq($from: desktop) {
      margin-right: $space-s;
    }
  }

  &__important {
    @include title-xl;
    color: $color-important;
  }
}
