@charset "utf-8";

.c-mission-task {
  padding-bottom: $space-s;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: flex-start;
    }
  }

  &__txt-area {
    @include par-m;
    padding: $space-l $space-m;
  }

  &__txt-area\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }

  &__title {
    @include title-2xl;
    color: $color-important;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include title-xl;
    }
  }

  &__small-title {
    @include title-m;
    margin-top: $space-l;
  }

  &__small-title\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-2xl;
    }
  }

  &__txt {
    color: $color-grey-1;
    line-height: $line-height-l;
  }

  &__small-title + &__txt {
    margin-top: $space-xs;
  }

  &__attention-block {
    margin-top: $space-m;
    color: $color-grey-2;
  }

  &__attention-block\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-xl;
    }
  }

  &__attention-title {
    @include title-s;
  }

  &__attention-txt {
    @include par-xs;
    line-height: $line-height-m;
    margin-top: $space-s;
  }

  &__attention-txt\@desktop {
    @include mq($from: desktop) {
      @include par-xs;
      line-height: $line-height-m;
    }
  }

  &__img-area {
    margin: 0 $space-m;
    color: $color-grey-2;
  }

  &__img-area\@desktop {
    @include mq($from: desktop) {
      width: auto;
      margin: 0 $space-xl;
      flex: 0 0 365px;
    }
  }

  &__img-wrapper {
    width: 100%;
    margin: $space-m 0 0 0;
  }

  &__img-wrapper\@desktop {
    @include mq($from: desktop) {
      width: 365px;
    }
  }

  &__img {
    width: 100%;
  }
}
