@charset "utf-8";

.c-staff-voice {
  border-top: 1px solid $color-grey-6;
  padding-top: $space-m;

  &__heading {
    display: flex;
    align-items: center;
  }

  &__image-box {
    height: 64px;
    width: 64px;
    margin-right: $space-m;
    background-color: $color-grey-7;
    overflow: hidden;
    border-radius: $space-s;

    &--circle {
      border-radius: 100%;
    }
  }

  &__image {
    max-width: 100%;
  }

  &__info {
    line-height: $line-height-s;
  }

  &__title {
    margin-top: $space-l;
  }

  &__txt {
    margin-top: $space-l;

    &:nth-of-type(2) {
      margin-top: $space-m;
    }
  }

  &__txt-inner {
    display: block;
    color: $color-grey-1;
    @include ellipsis-multiple-line(3, $font-size-m, $line-height-m, $color: $color-grey-2);
    letter-spacing: normal;
  }

  &__txt-inner\@desktop {
    @include mq($from: desktop) {
      height: auto;
      max-height: 100%;
    }
  }

  &__txt-inner--visible {
    height: auto;
    max-height: 100%;
  }

  &__txt-inner--visible::before,
  &__txt-inner--visible::after {
    display: none;
  }

  &__txt-inner\@desktop::before,
  &__txt-inner\@desktop::after {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__txt-show-more {
    display: block;
    text-align: right;
    color: $color-link;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    background-color: $color-white;
    line-height: $line-height-m;
  }

  &__txt-show-more--opened::before {
    display: none;
  }

  &__inner + &__txt-show-more {
    display: block;
  }
}
