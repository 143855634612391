@charset "utf-8";

.c-map-search-select {

  &\@desktop {
    @include mq($from: desktop) {
      padding: 0;
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__select-area {
    display: block;
    border-bottom: 1px solid $color-grey-6;
    padding: 0;
  }

  &__select-area\@desktop {
    @include mq($from: desktop) {
      width: 100%;
      border-bottom: none;
    }
  }

  &__selectbox {

    &:last-child {
      margin: $space-s 0 0;
    }
  }

  &__selectbox\@desktop {
    @include mq($from: desktop) {
      width: 50%;

      &:last-child {
        margin: 0 0 0 $space-s;
      }
    }
  }

  &__btn-area {
    padding: $space-m;
    white-space: nowrap;
  }

  &__btn-area\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 0 $space-s;
    }
  }

  &__submit {
    padding: $space-m 0 0 0;
  }

  &__submit\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 0 $space-s;
      margin-left: $space-s;
      border-left: 1px solid $color-grey-5;
      white-space: nowrap;
    }
  }
}
