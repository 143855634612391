@charset "utf-8";

.c-full-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;

  &__title {
    position: fixed;
    height: 48px;
    width: 100%;
    left: 0;
    top: 0;
    background: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    letter-spacing: 0.2px;
    z-index: 10;

    .c-full-modal-close {
      fill: $color-grey-3;
      position: absolute;
      width: 18px;
      height: 18px;
      right: 12px;
      top: 3px;
    }
  }

  &__content {
    padding: $space-2xl 0;
    box-sizing: content-box;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }

  &__footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    background: white;
    padding: $space-s;
    z-index: 20;
  }
}
