@charset "utf-8";

@media print {

  // https://stackoverflow.com/a/15253634
  body {
    -webkit-print-color-adjust: exact;
  }

  .c-drawer,
  .c-modal,
  .c-modal-small {
    display: none;
  }

  // fixed; だとページ毎にヘッダーが入るので static; にしておく
  .c-header {
    position: static;
  }

  // https://stackoverflow.com/a/21139139
  .c-featured-job-offer-card {
    -webkit-filter: opacity(1);
  }
}
