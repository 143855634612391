@charset "utf-8";

.c-textbox {
  position: relative;
  border-radius: $space-s;
  height: $space-3xl;
  padding: 0 $space-m;
  background-color: $color-grey-6;
  color: $color-grey-0;
  font-size: $font-size-l;
  font-weight: $font-weight-bold;
  transition: $btn-transition;

  &::-webkit-input-placeholder {
    color: $color-placeholder;
    font-weight: $font-weight-normal;
    line-height: normal;// https://stackoverflow.com/a/37799250
  }

  &::-moz-placeholder {
    color: $color-placeholder;
    font-weight: $font-weight-normal;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: $color-placeholder;
    font-weight: $font-weight-normal;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px $color-grey-6 inset;
  }

  &:focus {
    @include shadow-input;
    background-color: $color-white;
  }

  &--full {
    width: 100%;
  }

  &--error {
    background-color: rgba($color-important, 0.25);
  }

  &--white {
    background-color: $color-white;
  }
}
