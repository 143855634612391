@charset "utf-8";

.c-job-mail-title {
  display: flex;
  justify-content: space-between;

  &__heading {
    @include title-xl;
  }

  &__heading\@desktop {
    @include mq($from: desktop) {
      @include title-2xl;
    }
  }

  &__available {
    @include par-s;
    color: $color-grey-1;
    align-self: flex-end;
  }

  &__num-left {
    font-size: $font-size-m;
    color: $color-important;
    font-weight: $font-weight-bold;
  }
}
