@charset "utf-8";

.o-container {
  flex-grow: 1;
  padding: 0 0 $space-m;

  &--large {
    padding: 0 0 $space-l;
  }

  &\@desktop {
    @include mq($from: desktop) {
      padding: $space-l 0;
    }
  }

  &__column {
    width: 100%;
    margin: 0 auto;
  }

  &__column\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-column;
    }
  }

  &__column--large\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-container;
    }

    // tabletでのレイアウト崩れ回避
    @include mq(desktop, $tablet-portrait-width) {
      width: $width-desktop-column;
    }
  }

  &__column--small\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-main-column;
    }
  }

  &__column + &__column {
    margin-top: $space-l;
  }

  &__column + &__column--no-margin\@mobile {
    @include mq($until: desktop) {
      margin: 0;
    }
  }

  &__main-column\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-main-column;
    }
  }

  &__sub-column {
    margin: $space-l 0 0;
  }

  &__sub-column\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-sub-column;
      margin: 0;
    }
  }

  &__item {
    width: 100%;
  }

  &__sub-column > &__item {
    padding-left: $space-l;
    padding-right: $space-l;
  }

  &__sub-column\@desktop > &__item {
    @include mq($from: desktop) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__sub-column > &__item--full {
    padding: 0;
  }

  &__item--small\@mobile {
    @include mq($until: desktop) {
      margin: 0 $space-m;
      width: auto;
    }
  }

  &__item + &__item {
    margin-top: $space-m;
  }

  &__item + &__item--no-margin {
    margin: 0;
  }

  &__item + &__item--no-margin\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-m;
    }
  }

  &__item--margin-large + &__item--margin-large {
    margin-top: $space-l;
  }

  &__item--margin-large\@desktop + &__item--margin-large\@desktop {
    margin-top: $space-l;
  }

  &__item:empty + &__item {
    margin-top: 0;
  }

  &__item--margin-medium {
    margin-top: $space-m;
    margin-bottom: $space-m;
  }
}
