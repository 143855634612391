@charset "utf-8";

.c-search-change {

  &__top {
    padding-bottom: $space-l;
    border-bottom: 1px solid $color-grey-6;
  }

  &__top\@desktop {
    @include mq($from: desktop) {
      padding-bottom: $space-m;
    }
  }

  &__top + &__bottom {
    padding-top: $space-l;
  }

  &__top\@desktop + &__bottom\@desktop {
    @include mq($from: desktop) {
      padding-top: $space-m;
    }
  }

  &__item {
    width: 100%;
  }

  &__item + &__item {
    margin-top: $space-m;
  }

  &__item--margin-medium {
    margin-top: $space-m;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }
  }

  &__link {
    @include par-m;
    color: $color-link;
    font-weight: $font-weight-bold;

    &:hover {
      @include mq($from: desktop) {
        text-decoration: underline;
      }
    }
  }

  &--column\@desktop {
    @include mq($from: desktop) {

      .c-search-change__item {
        margin-top: 0;
      }
    }
  }
}
