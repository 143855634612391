@charset "utf-8";

.c-title-txt-button-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;

  &--column {
    flex-direction: column;
  }

  &--surround-padding {
    padding: $space-l $space-m 0;
  }

  &--surround-padding\@desktop {
    @include mq($from: desktop) {
      padding: $space-l $space-l 0;
    }
  }

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__txt {
    width: 100%;
  }

  &__button {
    flex: 0 0 auto;
  }

  & > &__txt + &__button {
    margin: 0 0 0 $space-l;
  }

  &--column > &__txt + &__button {
    margin: $space-l 0 0;
  }

  &\@desktop > &__txt + &__button {
    @include mq($from: desktop) {
      margin: 0 0 0 $space-l;
    }
  }
}
