@charset "utf-8";

.c-bordered-list {

  & > &__item {
    border-top: 1px solid $color-grey-6;
  }

  &--white-alpha > &__item {
    border-color: rgba($color-white, $alpha-low-common);
  }

  &--grey-4 > &__item {
    border-color: $color-grey-4;
  }

  &--grey-5 > &__item {
    border-color: $color-grey-5;
  }

  &--white > &__item {
    border-color: $color-white;
  }

  &--column > &__item {
    border-left-width: 1px;
    border-left-style: solid;
  }

  &--top-opened > &__item {
    border-top: 0;
  }

  &--top-opened > &__item + &__item {
    border-top: 1px solid $color-grey-6;
  }

  &--surround > &__item:last-child {
    border-bottom: 1px solid $color-grey-6;
  }

  &--column-surround > &__item:last-child {
    border-right-width: 1px;
    border-right-style: solid;
  }
}
