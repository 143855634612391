@charset "utf-8";

.c-landing-page-users {
  $white: #FFFFFF;
  $black: #222222;
  $gray:  #888888;
  $primary: #39BFB9;
  $back: #FAF7F0;
  letter-spacing: 0.01em;
  line-height: 1.4;

  main {
    background: $white;
  }

  header {
    height: 52px;
    @include mq($from: desktop) {
      height: 0;
    }

    .logo {
      position: absolute;
      z-index: 10;
      padding: 12px 0 0 0;
      @include mq($from: desktop) {
        padding: 24px 0 0 0;
      }

      &-img {
        height: 28px;
        @include mq($from: desktop) {
          height: 36px;
        }
      }
    }
  }

  .o-wrapper__head {
    display: none;
  }

  .o-wrapper__foot,
  .c-pagetop {
    display: none;
  }

  .o-container__column {
    padding-left: 16px;
    padding-right: 16px;
    @include mq($from: desktop) {
      padding-left: 28px;
      padding-right: 28px;
    }
  }

  .l-container {
    width: 100%;
    @include mq($from: desktop) {
      max-width: 1120px;
      margin: auto;
    }
  }

  .c-heading--3xl {
    font-size: 26px;
  }

  .c-button-users {
    height: 100%;
    align-self: center;
    border-radius: 32px;
    padding: 16px 68px;
    position: relative;
    width: 100%;
    @include mq($from: desktop) {
      width: auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 32px;
      height: 32px;
      background-color: #FF7B97;
      border-radius: 50vh;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 23px;
      transform: translateY(-50%) rotate(45deg);
      width: 8px;
      height: 8px;
      border-top: 2px solid $white;
      border-right: 2px solid $white;
    }
  }

  .c-mainvisual {
    height: 540px;
    background: $white;

    &__inner {
      padding: 0;
      margin-top: -200px;
      width: 100%;
      @include mq($from: desktop) {
        width: 54%;
        margin-top: -378px;
      }

      &-Ttl {
        color: $primary;
        font-size: 42px;
        line-height: 1.2;
        font-weight: 600;
        text-shadow: 0 0 30px rgba(255, 255, 255, 0.4);
        margin: 0 0 16px 0;
        @include mq($from: desktop) {
          font-size: 44px;
          margin: 0 0 24px 0;
        }
      }

      &-Txt {
        color: $black;
        margin: 0 0 32px 0;
        text-shadow: 0 0 8px rgba(255, 255, 255, 1);
      }
    }

    &__search {
      box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.06);
      border-radius: 20px;
      background: $white;
      @include mq($from: desktop) {
        background: $white;
        margin: 48px 0 0 0;
      }
    }
  }

  .feature {
    display: block;
    @include mq($from: desktop) {
      display: flex;
      justify-content: space-between;
    }

    &-item {
      display: flex;
      padding: 0 0 20px 0;

      &:last-child {
        padding-bottom: 0;
      }
      @include mq($from: desktop) {
        width: 33.33%;
        padding: 24px 4px;

        &:first-child {
          padding-left: 24px;
        }
      }

      img {
        width: 68px;
        height: 68px;
        border-radius: 16px;
      }

      &-text {
        display: block;
        padding: 0 8px 0 20px;

        p {
          padding: 4px 0 0 0;
          font-weight: 300;
        }
      }
    }
  }

  .about {
    background: $back;
    border-radius: 28px;
    margin: -268px auto 0;
    padding-bottom: 2px;
    @include mq($from: desktop) {
      height: 600px;
      margin: -108px auto 0;
    }

    &-header {
      display: block;
      @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
      }

      a {
        align-self: flex-end;
        margin: 0 0 40px 0;
      }
    }

    &-Ttl {
      padding: 288px 0 0;
      @include mq($from: desktop) {
        padding: 108px 0 40px;
      }

      h3 {
        color: $primary;
        padding: 0 0 12px 0;
      }
    }

    &-cards {
      display: block;
      @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
      }

      &-item {
        background: $white;
        padding: 16px;
        border-radius: 20px;
        box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.06);
        margin: 20px 0 24px;
        @include mq($from: desktop) {
          width: 33.33%;
          margin: 0 32px 0 0;

          &:last-child {
            margin: 0;
          }
        }

        img {
          width: 100%;
          border-radius: 12px;
        }

        &-text {
          padding: 0 8px;
          letter-spacing: 0.2px;

          h3 {
            text-align: center;
            margin: 24px 0 0 0;
          }

          p {
            margin: 12px 0 0 0;
          }
        }
      }
    }
  }

  .howto {
    margin: 32px 0 0 0;
    @include mq($from: desktop) {
      margin: 108px 0 0 0;
    }

    &-Ttl {
      padding: 0 0 24px 0;
      @include mq($from: desktop) {
        padding: 0 0 40px 0;
      }

      h2 {
        text-align: center;
      }

      span {
        color: $primary;
      }
    }

    &-img {
      width: 280px;
      border-radius: 16px;
      margin: 0 0 28px 20px;
      box-shadow: 8px 8px 80px rgba(0, 0, 0, 0.06);
    }

    .c-flow-list__detail {
      margin-bottom: 20px;
      @include mq($from: desktop) {
        margin-bottom: 0;
      }
    }
  }

  .ribbon {
    background: $back;
    border-radius: 0;
    margin: auto;
    padding: 0;
    @include mq($from: desktop) {
      border-radius: 100px;
    }

    &-inner {
      display: block;
      padding: 32px 0;
      text-align: center;
      @include mq($from: desktop) {
        display: flex;
        justify-content: space-between;
        padding: 6% 0;
        text-align: left;
      }

      &-Ttl {
        margin-bottom: 32px;
        @include mq($from: desktop) {
          margin-bottom: 0;
        }

        h2 {
          padding: 0 0 8px 0;
        }
      }
    }
  }

  .c-footer {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    @include mq($from: desktop) {
      border-top: 1px solid #EBE9E6;
      margin: 80px auto 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 28px;
      padding-right: 28px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mq($from: desktop) {
        justify-self: unset;
      }
    }

    &__logo {
      padding: 0 12px 0 0;
    }

    &__minimum-copyright {
      padding: 0 0 0 40px;

      &-sp {
        text-align: center;
        padding: 20px 0 0 0;
        margin: 20px 0 44px 0;
        border-top: 1px solid #EBE9E6;
      }
    }

    &__links {
      text-align: left;
      white-space: nowrap;
      padding: 20px 0 0 0;
      @include mq($from: desktop) {
        text-align: right;
        padding: 0;
      }

      &-item {
        padding: 0 16px 0 0;
      }
    }

    &__sns {

      &-item {
        padding: 0 0 0 8px;

        img {
          width: 40px;
          height: 40px;
          @include mq($from: desktop) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}
