@charset "utf-8";

.c-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  &__tr {
    border-bottom: 1px solid $color-grey-6;

    &:first-child {
      border-top: 1px solid $color-grey-6;
    }
  }

  &__th {
    display: block;
    color: $color-grey-0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;
    padding: $space-l 0 $space-m;
  }

  &__th\@desktop {
    @include mq($from: desktop) {
      display: table-cell;
      vertical-align: top;
      width: 180px;
      padding: $space-l $space-3xl $space-l 0;
    }
  }

  &__td {
    display: block;
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
    padding: 0 0 $space-l;
  }

  &__td\@desktop {
    @include mq($from: desktop) {
      display: table-cell;
      vertical-align: top;
      padding: $space-l 0;
    }
  }

  &__td--cell {
    display: table-cell;
    vertical-align: top;
    padding: $space-m $space-s;
  }

  &--accordion {

    .c-table__tr {
      border-bottom: 1px solid $color-grey-4;

      &:first-child {
        border-top: 0;
      }

      &:first-child > .c-table__th {
        padding: $space-s 0;
      }

      &:first-child > .c-table__th\@desktop {
        @include mq($from: desktop) {
          padding: $space-s $space-3xl $space-l 0;
        }
      }

      &:first-child > .c-table__td\@desktop {
        @include mq($from: desktop) {
          padding: $space-s 0 $space-l;
        }
      }
    }
  }
}
