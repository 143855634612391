@charset "utf-8";

.c-dentistry-states {
  position: relative;
  @include mq($from: desktop) {
    margin-top: -160px;
    padding: 0 0 20px;
  }

  &__jc-list {
    gap: 0 20px;
    display: flex;
    flex-direction: column;

    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__item {
    text-align: center;
    width: 100%;
    padding: 0;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    @include mq($from: desktop) {
      margin-bottom: 0;
    }
  }

  &__img {
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;

    img {
      border-radius: 8px;
    }

    &__overlay-text {
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translate(0, -50%);
      text-align: left;
      color: #000;

      &__white {
        color: $color-white;
      }
    }

    &:hover {
      @include shadow-top;
      border-radius: $space-s;
    }
  }

  &__states_list {
    flex-direction: column;
    flex-wrap: wrap;
  }

  &__states-link\@mobile {
    @include mq($until: desktop) {
      // c-grouped-link-box__mainのfontsizeを上書きする必要がある
      font-size: 12px !important;
    }
  }

  &__region-item {
    border-top: 1px solid $color-grey-6;
    border-top: 0;
    border-bottom: 1px solid $color-grey-6;
  }

  &__region-item\@desktop {
    @include mq($from: desktop) {
      width: 100%;
      margin-right: $space-m;
    }
  }

  &__region-item\@desktop:first-child {
    border-top: 1px solid $color-grey-6;
  }

  &__region-item\@desktop:nth-child(2n) {
    @include mq($from: desktop) {
      margin-right: 0;
    }
  }
}
