@charset "utf-8";

.c-guide-incentive {
  border-radius: $space-s;
  background: $color-grey-6;
  background-size: 100% auto;
  padding: $space-l;

  &__img-area {
    text-align: center;
    margin-bottom: $space-l;
  }

  &__heading {
    color: $color-grey-0;
    font-size: $font-size-2xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-m;
  }

  &__txt {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
    margin-bottom: $space-m;
  }

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      margin: $space-3xl 0 0 0;

      .c-guide-incentive__img-area {
        margin: 0 $space-l 0 0;
      }
    }
  }
}
