@charset "utf-8";

.c-page-title {

  &__headline {
    line-height: $line-height-s;
    color: $color-grey-1;
    font-size: $font-size-xl;

    &\@desktop {
      @include mq($from: desktop) {
        font-size: $font-size-2xl;
      }
    }
  }

  &__emphasis {
    font-weight: $font-weight-bold;
    font-size: $font-size-3xl;
    color: $color-grey-0;

    &\@desktop {
      @include mq($from: desktop) {
        font-size: $font-size-4xl;
      }
    }
  }

  &__bold {
    font-weight: $font-weight-bold;
    color: $color-grey-0;
  }
}
