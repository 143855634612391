@charset "utf-8";

.c-validation-error-message {
  color: $color-important;
  font-size: $font-size-s;
  font-weight: $font-weight-bold;

  &:empty {
    display: none;
  }
}
