@charset "utf-8";

.c-analysis-table {
  display: table;
  table-layout: fixed;
  width: 100%;

  &__head {
    padding: 0 0 $space-m;
    color: $color-grey-0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
    text-align: left;
  }

  &__body {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
  }

  &__head--50 {
    width: 50px;
  }

  &__head--50\@mobile {
    @include mq($until: desktop) {
      width: 50px;
    }
  }

  &__head--200\@desktop {
    @include mq($from: desktop) {
      width: 200px;
    }
  }
}
