@charset "utf-8";

.u-margin {

  &--top-2 {
    margin-top: 2px !important;
  }

  &--top-4 {
    margin-top: 4px !important;
  }

  &--top-6 {
    margin-top: 6px !important;
  }

  &--top-8 {
    margin-top: 8px !important;
  }

  &--left-8 {
    margin-left: 8px !important;
  }

  &--left-8\@mobile {
    @include mq($until: desktop) {
      margin-left: 8px !important;
    }
  }

  &--top-10 {
    margin-top: 10px !important;
  }

  &--top-12 {
    margin-top: 12px !important;
  }

  &--top-14 {
    margin-top: 14px !important;
  }

  &--top-16 {
    margin-top: 16px !important;
  }

  &--top-16\@mobile {
    @include mq($until: desktop) {
      margin-top: 16px !important;
    }
  }

  &--top-20 {
    margin-top: 20px !important;
  }

  &--top-24 {
    margin-top: 24px !important;
  }

  &--top-40\@desktop {
    @include mq($from: desktop) {
      margin-top: 40px !important;
    }
  }

  &--top-40\@mobile {
    @include mq($until: desktop) {
      margin-top: 40px !important;
    }
  }

  &--left-4 {
    margin-left: 4px !important;
  }

  &--left-4\@mobile {
    @include mq($until: desktop) {
      margin-left: 4px !important;
    }
  }

  &--bottom-1 {
    margin-bottom: 1px !important;
  }

  &--bottom-5 {
    margin-bottom: 5px !important;
  }

  &--bottom-8 {
    margin-bottom: 8px !important;
  }

  &--bottom-18 {
    margin-bottom: 18px !important;
  }

  &--right-8 {
    margin-right: 8px !important;
  }

  &--auto {
    margin: auto !important;
  }
}
