@charset "utf-8";

.c-flow-list {

  &__box {
    display: flex;
  }

  &__step {
    position: relative;

    &::before {
      border-right: 1px solid $color-grey-5;
      content: "";
      height: 100%;
      position: absolute;
      left: 50%;
      z-index: 0;
    }
  }

  &__number {
    align-items: center;
    background: $color-primary;
    border-radius: 50%;
    color: $color-white;
    display: flex;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    height: 24px;
    justify-content: center;
    position: relative;
    width: 24px;
    z-index: 1
  }

  &__number--red {
    background: $color-important;
  }

  &__number--yellow {
    background: $color-attention;
  }

  &__detail {
    margin-left: $space-s;
    width: 100%;
  }

  &__detail\@desktop {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }

  &__image {
    border-radius: $space-s;
    margin-left: $space-s;
    overflow: hidden;
    height: 144px;
    margin-bottom: $space-xl;
    flex: 0 0 256px;
  }

  &__image\@desktop {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }

  &__text {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
    margin-top: $space-s;
  }

  &__text--small {
    font-size: $font-size-s;
  }

  &__text:last-child {
    padding-bottom: $space-xl;
  }

  &__box:last-child {

    .c-flow-list__step::before {
      display: none;
    }

    .c-flow-list__text:last-child {
      padding-bottom: 0;
    }

    .c-flow-list__image {
      margin-bottom: 0;
    }
  }
}
