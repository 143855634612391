@charset "utf-8";

.c-segment {
  background-color: $color-white;
  border-radius: $space-s;

  &--square\@mobile {
    @include mq($until: desktop) {
      border-radius: 0;
    }
  }

  &--radius-bottom\@desktop {
    @include mq($from: desktop) {
      border-radius: 0 0 $space-s $space-s;
    }
  }

  &__inner {
    padding: $space-l $space-m;
  }

  &__inner--fit\@mobile {
    @include mq($until: desktop) {
      padding: 0;
    }
  }

  &__inner--fit\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      padding: $space-l;

      &--padding-top-x2 {
        padding-top: 40px;
      }
    }
  }

  &__inner--large\@desktop {
    @include mq($from: desktop) {
      padding: $space-m $space-l;
    }
  }

  &__inner--side {
    padding: 0 $space-m;
  }

  &__inner--side\@mobile {
    @include mq($until: desktop) {
      padding: 0 $space-m;
    }
  }

  &__inner--side\@desktop {
    @include mq($from: desktop) {
      padding: 0 $space-l;
    }
  }

  &__inner--lid {
    padding: $space-l 0;
  }

  &__inner--lid\@mobile {
    @include mq($until: desktop) {
      padding: $space-l 0;
    }
  }

  &__inner--lid\@desktop {
    @include mq($from: desktop) {
      padding: $space-l;
    }
  }

  &__inner--small {
    padding: $space-m;
  }

  &__inner--small\@desktop {
    @include mq($from: desktop) {
      padding: $space-m;
    }
  }

  &__inner--small\@mobile {
    @include mq($until: desktop) {
      padding: $space-m;
    }
  }

  &__inner--3large\@desktop {
    @include mq($from: desktop) {
      padding: $space-3xl;
    }
  }

  &--fit {
    border-radius: 0;
  }

  &--fit\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s;
    }
  }

  &--no-bg {
    background-color: inherit;
  }

  &--no-bg\@desktop {
    @include mq($from: desktop) {
      background-color: inherit;
    }
  }

  &--light-pink {
    background: rgba($color-important, $alpha-low);
  }

  &--light-green {
    background-color: $color-primary;
  }

  &--text {
    padding: 0 24px;
  }
}
