@charset "utf-8";

.c-pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__prev {
    font-size: $font-size-l;
    border-radius: $space-s;
    background: $color-grey-6;
    padding: $space-m;

    &--disabled {
      opacity: $alpha-low-common;
      pointer-events: none;
    }

    &:hover {
      @include mq($from: desktop) {
        color: $color-grey-0;
      }
    }
  }

  &__next {
    font-size: $font-size-l;
    border-radius: $space-s;
    background: $color-grey-6;
    padding: $space-m;

    &--disabled {
      opacity: $alpha-low-common;
      pointer-events: none;
    }

    &:hover {
      @include mq($from: desktop) {
        color: $color-grey-0;
      }
    }
  }

  &__item {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    padding: 0 $space-xs;
    float: left;
  }

  &__item-inner {
    display: block;
    padding: $space-xs;
  }

  &__item--current {
    color: $color-grey-0;
  }

  &__item--abbr {
    color: $color-grey-2;
  }

  &\@mobile {
    @include mq($until: desktop) {
      flex-wrap: wrap;

      .c-pagination__prev {
        flex-grow: 1;
        margin-right: $space-s;
      }

      .c-pagination__next {
        flex-grow: 1;
      }

      .c-pagination__list {
        display: flex;
        justify-content: center;
        flex-basis: 100%;
        order: 1;
        text-align: center;
        margin-top: $space-m;
        flex-wrap: wrap;
      }

      .c-pagination__item {

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      .c-pagination__item-inner {
        padding: $space-s;
      }
    }
  }
}
