@charset "utf-8";

.u-text-align {

  &-center {
    text-align: center !important;
  }

  &--center\@desktop {
    @include mq($from: desktop) {
      text-align: center !important;
    }
  }

  &-left {
    text-align: left !important;
  }

  &-right {
    text-align: right !important;
  }
}
