
@mixin withMediaQuery($classname) {

  #{ $classname } {
    @content;
  }

  #{ $classname }\@mobile {
    @include mq($until: desktop) {
      @content;
    }
  }

  #{ $classname }\@desktop {
    @include mq($from: desktop) {
      @content;
    }
  }
}
