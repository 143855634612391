@charset "utf-8";

.s-cos-job-offer-description {

  ul {
    padding-left: $space-m;
  }

  li {
    list-style: disc;
  }

  li + li {
    margin-top: $space-s;
  }
}
