@charset "utf-8";

.c-search-condition-title {
  position: relative;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $color-grey-6;
    padding: $space-l $space-m;
    min-height: 100px;
  }

  &__heading\@desktop {
    @include mq($from: desktop) {
      border-bottom: 0;
      padding: $space-m $space-l;
    }
  }

  &__headline {
    font-size: $font-size-m;
    letter-spacing: $letter-spacing-wide;
    font-weight: $font-weight-normal;
    line-height: $line-height-m;
    color: $color-grey-1;
  }

  &__headline\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-l;
    }
  }

  &__area {
    @include title-3xl;
    color: $color-grey-0;
  }

  &__area\@desktop {
    @include mq($from: desktop) {
      @include title-4xl;
    }
  }

  &__map-search {
    @include title-m;
    color: $color-grey-0;
  }

  &__map-search\@desktop {
    @include mq($from: desktop) {
      @include title-2xl;
    }
  }

  &__job {
    @include title-m;
    color: $color-grey-0;
  }

  &__job\@desktop {
    @include mq($from: desktop) {
      @include title-l;
    }
  }

  &__result\@desktop {
    @include mq($from: desktop) {
      margin-left: auto;
      padding: $space-m $space-l;
      border-left: solid 1px $color-grey-6;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      flex: 0 0 auto;
    }
  }

  &__result-prefecture\@desktop {
    @include mq($from: desktop) {
      margin-left: auto;
      padding: $space-m $space-l;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      flex: 0 0 auto;
      width: $width-desktop-main-column;
    }
  }

  &__result-job-category-top\@desktop {
    @include mq($from: desktop) {
      margin-left: auto;
      padding: $space-m $space-l;
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      flex: 0 0 auto;
      width: 100%;
    }
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }
  }

  &__inner\@mobile {
    @include mq($until: desktop) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $space-s $space-m;
    }
  }

  &__hit {
    color: $color-grey-1;
    padding-bottom: 0;
  }

  &__hit\@desktop {
    padding-bottom: $space-xs;
  }

  &__corresponding {
    @include title-xs;
    margin-right: $space-s;
  }

  &__corresponding\@desktop {
    @include mq($from: desktop) {
      display: block;
      margin-right: 0;
    }
  }

  &__important {
    @include title-l;
    color: $color-important;
  }
}
