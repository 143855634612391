@charset "utf-8";

.c-apply-complete-caption {
  color: $color-grey-1;
  font-size: $font-size-xl;
  text-align: center;
  padding: 0 $space-m;

  &\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-2xl;
      padding: $space-m 0;
    }
  }

  &__txt {
    margin: $space-xs 0 0;

    &\@desktop {
      @include mq($from: desktop) {
        margin: 0;
      }
    }
  }

  &__emphasis {
    font-weight: $font-weight-bold;
  }

  &__important {
    color: $color-important;
  }
}
