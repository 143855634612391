@charset "utf-8";

.o-gutter-row {
  display: flex;
  flex-direction: column;

  & > &__item + &__item {
    margin-top: $space-m;
  }

  &\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-m;
    }
  }

  &\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-m;
    }
  }

  &--xs > &__item + &__item {
    margin-top: $space-xs;
  }

  &--xs\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-xs;
    }
  }

  &--xs\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-xs;
    }
  }

  &--s > &__item + &__item {
    margin-top: $space-s;
  }

  &--s\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-s;
    }
  }

  &--s\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-s;
    }
  }

  &--m > &__item + &__item {
    margin-top: $space-m;
  }

  &--m\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-m;
    }
  }

  &--m\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-m;
    }
  }

  &--l > &__item + &__item {
    margin-top: $space-l;
  }

  &--l\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-l;
    }
  }

  &--l\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-l;
    }
  }

  &--xl > &__item + &__item {
    margin-top: $space-xl;
  }

  &--xl\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-xl;
    }
  }

  &--xl\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-xl;
    }
  }

  &--2xl > &__item + &__item {
    margin-top: $space-2xl;
  }

  &--2xl\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-2xl;
    }
  }

  &--2xl\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-2xl;
    }
  }

  &--3xl > &__item + &__item {
    margin-top: $space-3xl;
  }

  &--3xl\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-3xl;
    }
  }

  &--3xl\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-top: $space-3xl;
    }
  }

  &--margin-xsmall {
    margin-top: $space-xs;
  }

  &--margin-medium {
    margin-top: $space-m;
  }
}
