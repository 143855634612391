@charset "utf-8";

.c-arrowed-news-list {
  border-bottom: 1px solid $color-grey-6;

  &__item {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;

    &:first-child {
      border: none;
    }

    &:hover {
      @include mq($from: desktop) {
        border-color: transparent;
      }
    }
  }

  &__arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: $space-xs;
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transform: translateY(-50%);
    transition: $btn-transition;
  }

  &__link {
    display: block;
    position: relative;
    border-top: 1px solid $color-grey-6;
    color: $color-link;
    padding: $space-m $space-xl $space-m 0;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-card;
        border-radius: $space-s;
        color: $color-grey-0;
        text-decoration: none;
        padding-left: $space-m;

        .c-arrowed-news-list__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__item:hover + &__item > &__link {
    border-top-color: transparent;
  }

  &__detail\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      width: 100%;
    }
  }

  &__date {
    color: $color-grey-2;
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    flex-basis: 120px;
    flex-shrink: 0;
  }

  &__txt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
