@charset "utf-8";

.c-register-title {

  &\@desktop {
    @include mq($from: desktop) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &\@mobile {
    @include mq($until: desktop) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  &__title-area {
    color: $color-grey-0;
    font-size: $font-size-3xl;
    font-weight: $font-weight-bold;
  }

  &__title-area\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-4xl;
    }
  }

  &__btn-area {
    margin: $space-l 0 0;
  }

  &__btn-area-m {
    margin: $space-m 0 0;
  }

  &__btn-area\@desktop {
    @include mq($from: desktop) {
      margin: 0;
      display: flex;
    }
  }

  &__btn {

    &:last-child {
      margin: $space-s 0 0;
    }
  }

  &__btn\@desktop {
    @include mq($from: desktop) {
      width: 300px;

      &:last-child {
        margin: 0 0 0 $space-s;
      }
    }
  }

  &__step {
    width: 100%;
  }
}
