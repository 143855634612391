@charset "utf-8";

.c-job-search-title {
  @include title-xl;
  text-align: center;

  &\@desktop {
    @include mq($from: desktop) {
      @include title-2xl;
      text-align: left;
    }
  }
}
