@charset "utf-8";

.c-mypage-modal-button-group {
  display: flex;

  &--column {
    flex-direction: column;
  }

  &__item {
    flex: 1 1 calc(100% / 2);
    margin-left: $space-s;

    &--column {
      margin-left: 0;
      margin-bottom: $space-s;
    }
  }

  // IE11用
  @media all and (-ms-high-contrast: none) {

    &__item {
      flex: 1 auto;
    }
  }

  &__item:first-child {
    margin-left: 0;
  }
}
