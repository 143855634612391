@charset "utf-8";

.c-interview-list {

  &__item {
    border-bottom: 1px solid $color-grey-6;

    &:first-child {
      border-top: 1px solid $color-grey-6;
    }
  }

  &__link {
    display: grid;
    grid-template-areas:
      "interview-list__item-thumb interview-list__item-head"
      "interview-list__item-body interview-list__item-body";
    grid-template-columns: 80px 1fr;
    grid-template-rows: auto auto;
    grid-gap: $space-s;
    padding: $space-m 0;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      grid-template-areas:
        "interview-list__item-thumb interview-list__item-head"
        "interview-list__item-thumb interview-list__item-body";
      grid-template-columns: 114px 1fr;
      grid-gap: 0 $space-m;
      align-items: flex-start;
      max-width: 680px;
      transition: $btn-transition;

      &:hover {
        @include shadow-card;
        border-radius: $space-s;
        color: $color-grey-0;
        padding-left: $space-m;
        padding-right: $space-m;
      }
    }
  }

  &__item-head {
    grid-area: interview-list__item-head;
  }

  &__item-body {
    grid-area: interview-list__item-body;
  }

  &__item-thumb {
    grid-area: interview-list__item-thumb;
    @include image-box;
    float: left;
    width: 80px;
    height: 80px;
    margin-right: $space-s;
  }

  &__item-thumb\@desktop {
    @include mq($from: desktop) {
      float: none;
      width: 114px;
      height: 114px;
      flex-shrink: 0;
      margin-right: $space-m;
    }
  }

  &__image {
    width: 100%;
  }

  &__company {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
    overflow: hidden;
  }

  &__title {
    color: $color-link;
    font-weight: $font-weight-bold;
    overflow: hidden;
  }

  &__company + &__title {
    margin-top: $space-xs;
  }

  &__title\@mobile {
    @include mq($until: desktop) {
      @include ellipsis-multiple-line(3, $font-size-l, $line-height-s);
    }
  }

  &__title--small {
    @include ellipsis-multiple-line(4, $font-size-l, $line-height-s);
  }

  &__title--small\@desktop {
    @include mq($from: desktop) {
      min-height: auto;
      @include ellipsis-multiple-line(2, $font-size-l, $line-height-s);
    }
  }

  &__text {
    @include ellipsis-multiple-line(3, $font-size-s, $line-height-s);
  }

  &__text\@desktop {
    @include mq($from: desktop) {
      @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
    }
  }

  &__text--small\@desktop {
    @include mq($from: desktop) {
      @include ellipsis-multiple-line(3, $font-size-s, $line-height-s);
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    margin-top: $space-s;
  }

  &__date {
    color: $color-grey-2;
  }
}
