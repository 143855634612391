@charset "utf-8";

.c-rating {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &\@desktop {
    @include mq($from: desktop) {
      flex-wrap: nowrap;
      align-items: center;
    }
  }

  &__label {
    flex: 0 0 127px;
    color: $color-white;
    background-color: $color-grey-3;
    border: solid 1px $color-grey-3;
    border-radius: $space-xs;
    padding: $space-s $space-xs;
    font-size: $font-size-s;
    text-align: center;
    font-weight: $font-weight-bold;
  }

  &__label--strongly {
    background-color: $color-attention;
    border: solid 1px $color-attention;
  }

  &__label--slightly {
    background-color: $color-brown-1;
    border: solid 1px $color-attention;
    color: $color-brown-0;
  }

  &__value {
    margin-top: $space-s;
    display: flex;
    flex-grow: 1;
    order: 1;
    width: 100%;
  }

  &__value\@desktop {
    @include mq($from: desktop) {
      margin: 0 $space-s;
      order: 0;
    }
  }

  &__line {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: $space-m;
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      content: '';
      display: block;
      background-color: $color-grey-6;
      width: 100%;
      height: 1px;
      z-index: 0;
    }

    &:last-child {
      flex-grow: 0;
    }
  }

  &__mark {
    position: relative;
    width: $space-s;
    height: $space-s;
    background-color: $color-grey-5;
    border-radius: 50%;
    z-index: 1;
  }

  &__mark--strongly {
    background-color: $color-white;
    border: solid $space-xs $color-attention;
    border-radius: 50%;
    width: $space-m;
    height: $space-m;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__mark--neutral {
    background-color: $color-white;
    border: solid $space-xs $color-grey-3;
    border-radius: 50%;
    width: $space-m;
    height: $space-m;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
