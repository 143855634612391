@charset "utf-8";

.c-news-list {

  &__post-title {
    color: $color-link;
    font-weight: $font-weight-bold;
    overflow: hidden;
    @include ellipsis-multiple-line(3, $line-height: $line-height-s, $font-size: $font-size-l);
  }

  &__post-title\@desktop {
    @include mq($from: desktop) {
      overflow: hidden;
      @include ellipsis-multiple-line(1, $line-height: $line-height-s, $font-size: $font-size-l);
    }
  }

  &__item {
    border-bottom: 1px solid $color-grey-6;
    background-color: $color-white;
    transition: $btn-transition;

    &:first-child {
      border-top: 1px solid $color-grey-6;
    }

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top;
        color: $color-grey-0;
        padding: 0 $space-m;
        border-radius: $space-s;
        position: relative;
        z-index: 1;

        .c-news-list__post-title {
          color: $color-grey-0;
        }
      }
    }
  }

  &__link {
    align-items: center;
    border-radius: $space-s;
    display: flex;
    padding: $space-m 0;
    position: relative;
  }

  &__thumb {
    flex-shrink: 0;
    height: 80px;
    width: 80px;
    @include image-box;
  }

  &__thumb\@desktop {
    @include mq($from: desktop) {
      height: 48px;
      width: 48px;
    }
  }

  &__img {
    max-width: 100%;
  }

  &__txt-area {
    width: 100%
  }

  &__thumb + &__txt-area {
    padding: 0 0 0 $space-s;
  }

  &__post-data {
    align-items: center;
    color: $color-grey-2;
    display: flex;
    font-size: $font-size-xs;
    justify-content: space-between;
    margin-top: $space-xs;
  }

  &__post-data\@desktop {
    @include mq($from: desktop) {
      justify-content: flex-start;
    }
  }

  &__tag {
    margin: 0 $space-s 0 0;
  }

  &__tag-new {
    left: -$space-s;
    position: absolute;
    top: $space-s;
  }
}
