@charset "utf-8";

.c-textarea {
  background: $color-grey-6;
  border-radius: $space-s;
  color: $color-grey-0;
  font-weight: $font-weight-bold;
  width: 100%;
  padding: $space-m;

  &::placeholder {
    color: $color-placeholder;
    font-weight: $font-weight-normal;
  }

  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px $color-grey-6 inset;
  }

  &:focus {
    @include shadow-input;
    background: $color-white;
  }

  &--small {
    height: 132px;
  }

  &--small\@desktop {
    @include mq($from: desktop) {
      height: 92px;
    }
  }

  &--large {
    height: 216px;
  }

  &--error {
    background: rgba($color-important, 0.25);
  }

  &--white {
    background: $color-white;
  }
}
