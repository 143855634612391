@charset "utf-8";

.c-button-close {
  display: flex;
  justify-content: center;
  align-items: center;
  @include icon-xl;
  background-color: $color-primary;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transition: $fade-in-out-transition;

  &__cross {
    display: flex;
    @include icon-s;
    margin: 0;
    fill: $color-white;
  }

  &--invisible {
    opacity: 0;
    transition: $fade-in-out-transition;
  }
}
