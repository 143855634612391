@charset "utf-8";

.c-user-voice {
  display: flex;
  align-items: flex-start;

  &__person {
    width: 78px;
    flex-shrink: 0;
    text-align: center;
    margin-top: $space-xs;
    margin-right: $space-s;
  }

  &__img-box {
    width: $space-3xl;
    height: $space-3xl;
    @include image-circle;
    display: inline-flex;
  }

  &__img {
    @include icon-3xl;
  }

  &__position {
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
    margin-top: $space-xs;
  }

  &__info {
    font-size: $font-size-s;
    color: $color-grey-1;
    margin-top: $space-xs;
  }

  &__address {
    font-size: $font-size-s;
    color: $color-grey-1;
  }

  &__balloon {
    background-color: $color-grey-6;
    border-radius: $space-s;
    padding: $space-m;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: $space-m;
      left: -$space-m;
      bottom: auto;
      right: auto;
      border-top: $space-s solid transparent;
      border-bottom: $space-s solid transparent;
      border-right: $space-m solid $color-grey-6;
      border-left: $space-s solid transparent;
      height: 0;
      width: 0;
      margin: auto;
    }
  }

  &__balloon\@desktop {
    // ie11用幅指定
    @include mq($from: desktop) {
      flex-basis: calc(100% - (78px + #{$space-s}));
    }
  }

  &__inner {
    overflow: hidden;
    height: calc(3em * #{$line-height-m})
  }

  &__show-more {
    background-color: $color-grey-6;
    position: absolute;
    right: $space-m;
    bottom: $space-m;
    color: $color-link;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;

    &::before {
      content: '...';
      color: $color-grey-2;
    }
  }

  &__inner + &__show-more {
    display: block;
  }

  &__inner--invalidate\@desktop + &__show-more {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__inner--visible {
    overflow-y: visible;
    height: auto;
  }

  &__inner--visible + &__show-more {
    display: none;
  }
}
