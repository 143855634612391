@charset "utf-8";

.c-form-table {
  width: 100%;

  &__item {
    display: flex;
  }

  &__item-switching {
    display: block;
  }

  &__item-switching\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__hour {
    display: block;
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__input-wrapper {
    width: calc(100% - 50px);
    padding-left: $space-s;
  }

  &__input-wrapper\@desktop {
    @include mq($from: desktop) {
      width: calc(100% - 184px);
      padding-left: 0;
    }
  }

  &__input {
    display: flex;
  }

  &__input-wrapper--full\@mobile {
    @include mq($until: desktop) {
      width: 100%;
    }
  }

  &__input\@desktop {
    @include mq($from: desktop) {
      width: 424px
    }
  }

  &__input--full\@desktop {
    @include mq($from: desktop) {
      width: 100%;
    }
  }

  &__input--half\@desktop {
    @include mq($from: desktop) {
      width: 212px
    }
  }

  &__input--interview\@desktop {
    @include mq($from: desktop) {
      width: 437px;
    }
  }

  &__checkbox-wrap {
    flex-wrap: wrap;
    max-width: 100%;
    flex-shrink: 0;
    margin-bottom: - $space-xs;
    margin-right: - $space-xs;
  }

  &__input + .c-form-table__input {
    margin-top: $space-xs;
  }

  &__title {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    color: $color-grey-0;
    flex-shrink: 0;
    width: 58px;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-s;
      width: 184px;
    }
  }

  &__necessary {
    color: $color-important
  }

  &__necessary\@mobile {
    @include mq($until: desktop) {
      display: block;
    }
  }

  &__txt {
    font-size: $font-size-l;
  }

  &__error {
    color: $color-important;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-bottom: $space-xs;
  }

  &__supple {
    color: $color-grey-2;
    font-size: $font-size-s;
    margin-bottom: $space-xs;
  }

  &__link {
    font-size: $font-size-s;
    display: inline-block;
  }

  &__input--fluid {
    width: 100%;
  }

  &__checkbox {
    float: left;
    margin-right: $space-xs;
    margin-bottom: $space-xs;
  }

  &__checkbox--stack\@mobile {
    @include mq($until: desktop) {
      display: flex;
      width: 100%;
      float: none;
      margin-right: 0;
    }
  }

  &__checkbox\@desktop {
    @include mq($from: desktop) {
      margin-right: $space-xs;
    }
  }

  &__checkbox--half\@mobile {
    @include mq($until: desktop) {
      width: calc(50% - #{$space-xs}); // margin-right分をwidthから引き算

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  &__checkbox--txtL\@mobile {
    @include mq($until: desktop) {
      justify-content: left;
    }
  }

  & > &__item + &__item--margin-small {
    margin-top: $space-s;
  }

  &__item-switching > &__title,
  &__item-switching\@desktop > &__title {
    width: 100%;

    @include mq($from: desktop) {
      width: 184px;
    }
  }

  &__item-switching > &__input-wrapper,
  &__item-switching\@desktop > &__input-wrapper {
    width: 100%;
    padding-left: 0;
    margin-top: $space-s;

    @include mq($from: desktop) {
      width: calc(100% - 184px);
      margin-top: 0;
    }
  }
}
