@charset "utf-8";

.s-news-cms {

  p {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
  }

  h2 {
    font-weight: $font-weight-bold;
    font-size: $font-size-m;
    line-height: $line-height-m;
  }

  a {
    color: $color-link;
    font-weight: $font-weight-bold;
    cursor: pointer;

    &:hover {
      @include mq($from: desktop) {
        text-decoration: underline;
      }
    }
  }

  p + h2,
  p + p {
    margin-top: $space-m;
  }
}
