@charset "utf-8";

.c-thumbnail-list {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__item {
    border-bottom: 1px solid $color-grey-6;

    &:first-child {
      border-top: 1px solid $color-grey-6;
    }
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      border: none;
      margin-left: $space-m;
      width: 217px;

      &:first-child {
        border: none;
        margin-left: 0;
      }
    }
  }

  &__link {
    display: flex;
    padding: $space-m 0;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      display: block;
      padding: 0;
    }
  }

  &__image-cover {
    @include image-box;
    flex-basis: 80px;
    flex-shrink: 0;
    height: 80px;
    margin-right: $space-s;
  }

  &__image-cover\@desktop {
    @include mq($from: desktop) {
      width: 216px;
      height: 216px;
      margin-right: 0;
    }
  }

  &__image {
    height: 100%;
  }

  &__title-container {
    overflow: hidden;
    margin-bottom: $space-xs;
  }

  &__title-container\@desktop {
    @include mq($from: desktop) {
      margin: $space-s 0;
    }
  }

  &__title {
    overflow: hidden;
    @include ellipsis-multiple-line(3, $font-size-l, $line-height-s);
    color: $color-link;
    font-weight: $font-weight-bold;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include ellipsis-multiple-line(2, $font-size-l, $line-height-s);
    }
  }

  &__text-area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__text-area\@desktop {
    @include mq($from: desktop) {
      display: block;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    width: 100%;
  }

  &__date {
    color: $color-grey-2;
  }

  &__item:hover {
    @include mq($from: desktop) {

      .c-thumbnail-list__title {
        text-decoration: underline;
      }
    }
  }
}
