@charset "utf-8";

.c-application-form {

  &--preferred__area {
    background: #F7F6F2;
    padding: 10px;
    margin-top: 10px;
    border-radius: 8px;
  }
}
