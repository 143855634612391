@charset "utf-8";

//////////////////////////////////////////////////////
// # BREAK POINTS
//////////////////////////////////////////////////////
$mq-breakpoints: (
  desktop: 481px,
);
// $mq-show-breakpoints: (desktop);

//////////////////////////////////////////////////////
// # SPACES
//////////////////////////////////////////////////////
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 24px;
$space-xl: 32px;
$space-2xl: 40px;
$space-3xl: 48px;

//////////////////////////////////////////////////////
// # COLORS
//////////////////////////////////////////////////////

// # BASIC
$color-primary: #39BFB9;
$color-link: #00807A;
$color-placeholder: rgba(#00807A, 0.5);
$color-important: #FF5176;
$color-attention: #FAAF00;
$color-white: #FFFFFF;

// # GREY
$color-grey-0: #222222;
$color-grey-1: #444444;
$color-grey-2: #888888;
$color-grey-3: #BFBEBB;
$color-grey-4: #E6E4E1;
$color-grey-5: #EBE9E6;
$color-grey-6: #F7F6F2;
$color-grey-7: #FAF7F0;

// # SUB
$color-green-bright: #CDEFED;
$color-brown-0: #996B00;
$color-brown-1: #FAF3E3;

// # SHARE
$color-line: #00B900;
$color-facebook: #3B5998;
$color-twitter: #03ADF3;

// # ICON
$color-icon-search: #319E99;

// # Lead
$color-lead-yellow: #FEEBBF;

// # Message
$color-message-heading-member: #2C9B96;

//////////////////////////////////////////////////////
// # FONT
//////////////////////////////////////////////////////

// # FONT-SIZE
$font-size-xs: 10px;
$font-size-s: 12px;
$font-size-m: 14px;
$font-size-l: 16px;
$font-size-xl: 18px;
$font-size-2xl: 20px;
$font-size-3xl:22px;
$font-size-4xl: 26px;
$font-size-5xl: 32px;

// # FONT-WEIGHT
$font-weight-normal: 400;
$font-weight-bold: 600;

// # LINE-HEIGHT
$line-height-xs: 1.1;
$line-height-s: 1.25;
$line-height-m: 1.5;
$line-height-l: 2;
$line-height-xl: 2.4;
$line-height-2xl: 2.8;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

// # FONT-FAMILY
$global-font: 'Poppins', "helvetica", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "arial", "メイリオ", sans-serif;

// # LETTER-SPACING
$letter-spacing-normal: normal;
$letter-spacing-wide: 0.04em;
$letter-spacing-x-wide: 0.3em;

//////////////////////////////////////////////////////
// # ALPHA
//////////////////////////////////////////////////////
$alpha-low: 0.1;
$alpha-low-common: 0.25;
$alpha-common: 0.5;
$alpha-high-common: 0.75;
$alpha-high: 0.9;

//////////////////////////////////////////////////////
// # WIDTH
//////////////////////////////////////////////////////
$width-desktop-container: 1024px;
$width-desktop-column: 960px;
$width-desktop-main-column: 656px;
$width-desktop-sub-column: 280px;
$width-mobile-container: 100%;
$width-modal-small: 327px;
$tablet-portrait-width: 768px;

//////////////////////////////////////////////////////
// # ZINDEX
//////////////////////////////////////////////////////
$zindex: message_show_button_sp, message_item, message_show_button_pc, c-map__offer, c-map__offer-button, mainvisual, mainvisual_inner, footbar, container_hover, inline_loader, header, header_hover, area_search, overlay, modal, modal_foot, modal_frame, modal_closer, toaster, loader, drawer;

//////////////////////////////////////////////////////
// # TRANSITION
//////////////////////////////////////////////////////
$btn-transition: all 0.3s ease;
$border-transition: all 0.1s ease;
$balloon-transition: opacity 0.2s ease, transform 0.2s ease;
$map-slider-transition: transform 0.3s ease;
$swipe-message-transition: transform 0.2s ease;

$fade-in-out-transition: opacity 0.4s ease-out;
$slide-in-out-transition: right 0.3s ease-out;

//////////////////////////////////////////////////////
// # TRANSFORM
//////////////////////////////////////////////////////
$arrow-rotate: 45deg;
$arrow-rotate-reverse: 180deg;

//////////////////////////////////////////////////////
// # BORDER
//////////////////////////////////////////////////////
$border-important: 2px solid $color-important;
