@charset "utf-8";

.c-celebration-annotation {
  font-size: $font-size-s;
  color: $color-grey-1;
  line-height: $line-height-m;

  &--grey-2 {
    color: $color-grey-2;
  }

  &__title {
    font-weight: $font-weight-bold;
    margin-bottom: $space-xs;
  }
}
