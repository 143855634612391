@charset "utf-8";

.o-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &\@desktop {
    @include mq($from: desktop) {
      min-width: $width-desktop-column;
    }
  }

  &--top\@desktop {
    @include mq($from: desktop) {
      min-width: $width-desktop-container;
    }
  }

  &__body {
    flex: 1 0 auto;
  }

  &__head {
    flex-shrink: 0;
    min-height: 92px;
  }

  &__head\@mobile {
    @include mq($until: desktop) {
      flex-shrink: 0;
      min-height: 76px;
    }
  }

  &__head--breadcrumbless {
    min-height: 72px;
  }

  &__head--breadcrumbless\@mobile {
    @include mq($until: desktop) {
      min-height: 47px;
    }
  }

  &__foot {
    flex-shrink: 0;
  }
}

// ref: https://github.com/philipwalton/solved-by-flexbox/pull/36
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .o-wrapper {
    height: 100%;
  }
}
