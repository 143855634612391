@charset "utf-8";

.c-profile-list {

  &__item {
    text-align: left;
  }

  &__title {
    margin-right: 1em;
    flex: 0 0 auto;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      width: calc(100% / 3);
    }
  }

  &__label {
    margin-right: $space-xs;
    @include title-s;
  }

  &__info {
    flex: 1 1 auto;
    margin-right: $space-m;
    line-height: $line-height-l;
  }

  &__text {
    @include par-l;
    display: block;
    margin-top: $space-s;
    color: $color-grey-1;
    overflow-wrap: anywhere;
  }

  &__text\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__notice {
    @include par-xs;
    display: block;
    color: $color-grey-2;
    line-height: $line-height-m;
  }

  &__important {
    color: $color-important;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transition: $btn-transition;
  }

  &__link {
    border-top: 1px solid $color-grey-6;
    padding: $space-m 0;
    color: $color-grey-0;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      align-items: center;
      transition: $btn-transition;

      &:hover {
        @include shadow-top;
        border-radius: $space-s;
        padding: $space-m;
        color: $color-grey-0;

        .c-profile-list__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__item:last-child > &__link {
    border-bottom: 1px solid $color-grey-6;
  }

  &__item\@desktop:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &__item\@desktop:last-child:hover > &__link {
    @include mq($from: desktop) {
      border-bottom-color: transparent;
    }
  }

  &__id-photo {
    max-width: 300px;
    max-height: 400px;
  }
}
