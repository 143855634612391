@charset "utf-8";

.c-search-link-list {
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-s;
  font-weight: $font-weight-bold;

  &__item {
    line-height: $line-height-2xl;
    margin-right: 1.5em;
  }

  &__item:last-child {
    margin-right: 0;
  }
}
