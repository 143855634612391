@charset "utf-8";

.c-footer-minimum {
  // TODO: c-footer-notificationを非表示にする際に高さを調整する(+26pxしている)
  background: $color-white;
  padding: 0 0 $space-l 0;
  margin: 50px 0;

  &__inner {
    padding: 0 $space-m;
  }

  &__logo {
    width: 174px;
    height: 30px;
    fill: $color-primary;
  }

  &__logo\@desktop {
    @include mq($from: desktop) {
      width: 191px;
      height: 34px;
    }
  }

  &__message {
    color: $color-grey-2;
    font-size: $font-size-xs;
    line-height: $line-height-m;
  }
}
