@charset "utf-8";

.c-violation-report-sub-column-card {
  border-radius: $space-s;
  border: 1px solid $color-primary;
  background: rgba($color-primary, $alpha-low);
  padding: $space-m;

  &__info-area {
    margin-bottom: $space-m;
  }

  &__title {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-m;
  }

  &__description {
    color: $color-grey-2;
    font-size: $font-size-m;
    line-height: $line-height-s;
  }
}
