@charset "utf-8";

.o-flex {
  display: flex;
  align-items: stretch;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: stretch;
    }
  }

  &--align-start\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: flex-start;
    }
  }

  &\@mobile {
    @include mq($until: desktop) {
      display: flex;
      align-items: stretch;
    }
  }

  &--reverse {
    flex-direction: row-reverse;
  }

  &--col {
    flex-direction: column;
  }

  &--col-reverse {
    flex-direction: column-reverse;
  }

  &--justify-space-between {
    justify-content: space-between;
  }

  &--justify-space-between\@desktop {
    @include mq($from: desktop) {
      justify-content: space-between;
    }
  }

  &--justify-center {
    justify-content: center;
  }

  &--justify-end {
    justify-content: flex-end;
  }

  &--justify-start {
    justify-content: flex-start;
  }

  &--align-center {
    align-items: center;
  }

  &--align-center\@desktop {
    @include mq($from: desktop) {
      align-items: center;
    }
  }

  &--basis-125\@desktop {
    @include mq($from: desktop) {
      flex-basis: 125px;
    }
  }

  &__item--grow-1 {
    flex-grow: 1;
  }

  &__item--grow-1\@desktop {
    @include mq($from: desktop) {
      flex-grow: 1;
    }
  }

  &__item--shrink-0 {
    flex-shrink: 0;
  }
}
