@charset "utf-8";

.c-search-job-and-keywords {
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__item-right {
    margin-top: $space-m;
  }

  &__item-left\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 auto;
    }
  }

  &__item-right\@desktop {
    @include mq($from: desktop) {
      flex: 2 1 auto;
      margin-top: 0;
      margin-left: $space-m;
    }
  }

  &--vertical {
    flex-direction: column;
  }

  &--vertical > &__item-right\@desktop {
    margin-top: $space-m;
    margin-left: 0;
  }
}
