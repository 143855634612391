@charset "utf-8";

.c-member-register-card {
  padding: $space-m;
  border-radius: $space-s;
  border: $border-important;
  text-align: center;
  background: $color-white;

  &__title {
    color: $color-important;
    @include title-l;
  }

  &__list {
    display: inline-block;
    margin: $space-m auto 0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
  }

  // IE11用
  @media all and (-ms-high-contrast: none) {

    &__list {
      width: 178.5px;
    }
  }

  &__item {
    text-align: left;
    color: $color-grey-0;
  }

  &__item + &__item {
    margin-top: $space-s;
  }

  &__item--medium + &__item--medium {
    margin-top: $space-m;
  }

  &__number {
    color: $color-important;
    margin-right: $space-xs;
  }

  &__button {
    margin-top: $space-m;
  }
}
