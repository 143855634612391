@charset "utf-8";

.c-banner-list {

  &__list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    padding: 0 $space-xs $space-s 0;
    width: 50%;

    &:nth-child(2n) {
      padding: 0 0 $space-s $space-xs;
    }
  }

  &__img {
    width: 100%;
  }
}
