@charset "utf-8";

input,
input[type="text"],
input[type="search"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="url"],
input[type="tel"] {
  appearance: none;
  -webkit-tap-highlight-color: initial;
}

input {
  padding: 0;
  outline: 0;
  border: none;
  letter-spacing: inherit;
  font: inherit;
}

// ref: https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
