@charset "utf-8";

.c-search-condition-foot {
  padding: $space-m;
  border-top: solid 1px $color-grey-6;
  margin-top: -1px;

  &\@desktop {
    @include mq($from: desktop) {
      border-top: 1px solid $color-grey-6;
      padding: $space-m $space-l;
    }
  }

  &__item + &__item {
    margin-top: $space-m;
  }

  &__text {
    @include par-s;
  }
}
