@charset "utf-8";

.c-favorite-job-offer-card {
  display: flex;
  flex-direction: column;
  @include shadow-card;
  border-radius: $space-s;
  align-items: stretch;
  background-color: $color-white;
  transition: $btn-transition;
  -webkit-tap-highlight-color: transparent;

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;

      &:hover {
        @include card-hover;
      }
    }
  }

  &__image-box {
    @include image-box;
    height: 192px;
    position: relative;
    border-radius: $space-s $space-s 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image-box\@desktop {
    @include mq($from: desktop) {
      width: 282px;
      height: 159px;
      overflow: hidden;
      border-radius: $space-s 0 0 $space-s;
    }
  }

  &__image-box-with-apply-button\@desktop {
    @include mq($from: desktop) {
      width: 303px;
      height: 171px;
      overflow: hidden;
      border-radius: $space-s 0 0 $space-s;
    }
  }

  &__image {
    width: 100%;
  }

  &__image\@desktop {
    @include mq($from: desktop) {
      width: 100%;
    }
  }

  &__tag {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: $space-s;
  }

  &__txt-area {
    padding: $space-m;
  }

  &__txt-area\@desktop {
    @include mq($from: desktop) {
      padding: $space-s $space-m;
      flex: 0 0 calc(100% - 282px);
    }
  }

  &__txt-area-with-apply-button\@desktop {
    @include mq($from: desktop) {
      padding: $space-s $space-m;
      flex: 0 0 calc(100% - 303px);
    }
  }

  &__title-box {
    overflow: hidden;
  }

  &__title {
    @include ellipsis-multiple-line(1, $font-size-l, $line-height-s, $color-white, $color-link);
  }

  &__detail-box {
    overflow: hidden;
  }

  &__detail {
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
    margin-top: $space-xs;
    color: $color-grey-1;
  }

  &__job-title {
    font-size: $font-size-s;
    margin-top: $space-s;
    color: $color-grey-1;
  }

  &__button-group {
    display: flex;
    flex-direction: column;
    margin-top: $space-m - $space-xs;
  }

  &__button-group\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__button-group-with-apply-button\@mobile {
    @include mq($until: desktop) {
      display: flex;
      flex-direction: row;
      margin-top: $space-s;
    }
  }

  &__button-group-with-apply-button\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-direction: row;
      margin-top: $space-xs
    }
  }

  &__button-group-item:last-child {
    margin-top: $space-s;
  }

  &__button-group-item\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 auto;
    }
  }

  &__button-group-item\@desktop:last-child {
    @include mq($from: desktop) {
      margin-left: $space-s;
      margin-top: 0;
    }
  }

  &__button-group-item-with-apply-button {
    flex: 1 1 0;
  }

  &__button-group-item-with-apply-button:last-child {
    margin-left: $space-xs;
  }

  &__button-group-item-single\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 auto;
    }
  }

  &__button-with-apply-button\@mobile {
    @include mq($until: desktop) {
      height: 52px;
      font-size: 15px;
      padding: 16px 8px;
    }
  }

  &__button-with-apply-button\@desktop {
    @include mq($from: desktop) {
      height: 31px;
      font-size: 12px;
      padding: 8px
    }
  }
}
