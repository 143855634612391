@charset "utf-8";

.c-additional-complete-btn-group {
  margin-top: $space-s;

  &__row {
    text-align: center;
    padding-top: $space-l;
  }

  &__split\@desktop {

    @include mq($from: desktop) {

      > a:last-child {
        margin-left: $space-s;
      }
    }
  }
}
