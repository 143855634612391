@charset "utf-8";

.c-job-offer-card {
  @include shadow-card;
  border-radius: $space-s;
  background-color: $color-white;
  transition: $btn-transition;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @include mq($from: desktop) {
      @include card-hover;
      cursor: pointer;
    }
  }

  &__image-box {
    @include image-box;
    border-radius: $space-s $space-s 0 0;
    height: 193px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image-box--noimg {
    height: 100%;
  }

  &__image {
    width: 100%;
  }

  &__txt-area {
    position: relative;
    padding: $space-m;
    flex: 1 1 0%;
  }

  &__title-box {
    display: flex;
  }

  &__tag-new {
    margin: 0 0 $space-s 0;
    box-shadow: none;
  }

  &__tag-like {
    min-width: 40px;
    min-height: 40px;
  }

  &__tag-item {
    display: flex;
    align-items: center;
  }

  &__tag-item-txt {
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
    color: $color-grey-2;
    padding-right: $space-xs;
  }

  &__title-container {
    overflow: hidden;
    margin-bottom: $space-s;
  }

  &__title {
    @include ellipsis-multiple-line(3, $font-size-xl, $line-height:$line-height-s, $color: $color-link);
    margin-bottom: $space-s;
  }

  &__updated_date {
    font-size: $font-size-s;
    color: $color-grey-2;
    margin-top: $space-m;
    text-align: right;
  }

  &__txt-container {
    overflow: hidden;
  }

  &__txt {
    @include ellipsis-multiple-line(4, $font-size-m, $line-height-s);
    color: $color-grey-1;
  }

  &__salary-label {
    font-weight: $font-weight-bold;
    font-size: 11px;
    padding: 4px 8px;
    border: 1px solid $color-grey-3;
    border-radius: 50px;
    color: $color-grey-1;
    line-height: 3;
  }

  &__summary {
    border-top: 1px solid $color-grey-6;
    padding: 0 $space-m;
  }

  &__table {
    border-collapse: collapse;
    font-size: $font-size-s;
    margin: $space-m 0 0;
  }

  &__table-th {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;
    padding: 0 0 $space-xs;
    display: block;
  }

  &__table-td {
    padding-bottom: 12px;
    display: block;
    overflow-wrap: anywhere;
  }

  &__table-td-txt-container {
    overflow: hidden;
  }

  &__table-td-txt {
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
    color: $color-grey-1;
  }

  &__table-td-txt--full {
    max-height: initial;
  }

  &__table-td-txt--full::before,
  &__table-td-txt--full::after {
    content: none;
  }

  &__tag-list {
    border-bottom: 1px solid $color-grey-6;
    padding: $space-m $space-m $space-s $space-m;
  }

  &__tag-list-inner {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: space-between;

    &--celebration-money {
      width: auto;
      margin: $space-s 0 0 0;
    }
  }

  &__tag-head {
    border-bottom: 1px solid $color-grey-6;
    padding: 0 0 $space-m 0;
  }

  &__btn-area {
    border-top: 1px solid $color-grey-6;
    padding: $space-s;
  }

  &__btn-ul {
    display: flex;
    padding: 0 $space-m $space-m;
    @include mq($from: desktop) {
      padding: 0;
      margin-top: $space-s;
    }
  }

  &__btn-ul__list {
    width: 100%;
  }

  &__btn-item {
    flex: 1 1 0;

    &:nth-child(1) {
      margin-right: $space-s;
    }
  }

  &\@desktop {
    @include mq($from: desktop) {
      position: relative;
      padding: $space-m;

      .c-job-offer-card__tag-new {
        left: -$space-s;
      }

      .c-job-offer-card__heading {
        display: flex;
      }

      .c-job-offer-card__image-box {
        border-radius: $space-s;
        height: 158px;
        width: 280px;
        flex-shrink: 0;
      }

      .c-job-offer-card__txt-area {
        padding: 0;
      }

      .c-job-offer-card__image-box + .c-job-offer-card__txt-area {
        padding: 0 0 0 $space-m;
      }

      .c-job-offer-card__title {
        @include ellipsis-multiple-line(3, $font-size-2xl, $line-height-s, $color: $color-link);
      }

      .c-job-offer-card__txt {
        @include ellipsis-multiple-line(4, $line-height:$line-height-s);
      }

      .c-job-offer-card__summary {
        margin: $space-m 0 0 0;
        padding: $space-m 0 0 0;
      }

      .c-job-offer-card__table {
        margin: 0;
      }

      .c-job-offer-card__table-box {
        display: table-cell;
        vertical-align: top;
        padding-right: $space-m;
      }

      .c-job-offer-card__table-th {
        white-space: nowrap;
        padding-right: $space-m;
        width: 40px;
        display: table-cell;
        vertical-align: top;
      }

      .c-job-offer-card__table-td {
        vertical-align: top;
        display: table-cell;
      }

      .c-job-offer-card__table-tr:last-child > .c-job-offer-card__table-th,
      .c-job-offer-card__table-tr:last-child > .c-job-offer-card__table-td {
        padding-bottom: 0;
      }

      .c-job-offer-card__tag-list {
        vertical-align: top;
        border-top: 0;
        padding: 0 0 $space-s 0;
        margin: 0 0 $space-s 0;
      }

      .c-job-offer-card__btn-area {
        padding: $space-m 0 0;
      }

      .c-job-offer-card__btn-keep {
        flex: 0 0 280px;
      }
    }
  }
}
