@charset "utf-8";

.c-setting-radio {

  &__label {
    background: $color-white;
    color: $color-link;
    font-weight: $font-weight-bold;
    font-size: $font-size-l;
    line-height: $line-height-s;
    padding: $space-m $space-m $space-m $space-2xl;
    transition: $btn-transition;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: $space-m;
      width: $space-m;
      height: $space-m;
      border: $space-xs solid $color-grey-5;
      border-radius: 50%;
      background: $color-white;
      transition: $btn-transition;
    }

    &:hover {
      @include mq($from: desktop) {
        background: $color-white;
        font-weight: $font-weight-bold;
        @include shadow-top;
        color: $color-grey-0;
        z-index: index($zindex, container_hover);
      }
    }
  }

  &__input + &__input {
    border-top: 1px solid $color-grey-6;
  }

  &__input:first-of-type > &__label {
    border-radius: $space-s $space-s 0 0;
  }

  &__input:last-of-type > &__label {
    border-radius: 0 0 $space-s $space-s;
  }

  &__btn {
    display: none;
  }

  &__btn:checked + &__label {
    font-weight: $font-weight-bold;
    color: $color-grey-0;

    &::before {
      border-color: $color-primary;
    }
  }

  &__btn:disabled + &__label {
    cursor: default;
    pointer-events: none;
  }

  &--disabled {
    opacity: $alpha-low-common;
  }
}
