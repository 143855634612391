@charset "utf-8";

.c-job-list {

  &__th {
    text-align: left;
    vertical-align: top;
    width: 80px;
    padding: 0 $space-m $space-m 0;
  }

  &__th\@desktop {
    @include mq($from: desktop) {
      width: 130px;
    }
  }

  &__td {
    vertical-align: top;
    padding: 0 0 $space-m;
  }

  &__td\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 $space-xs;
    }
  }

  &__category {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    line-height: $line-height-2xl;
  }

  &__category\@desktop {
    @include mq($from: desktop) {
      line-height: $line-height-s;
    }
  }

  &__category-items {
    overflow: hidden;
  }

  &__category-item {
    float: left;
    line-height: $line-height-2xl;
    margin-right: 1.5em;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
  }

  &__category-item\@desktop {
    @include mq($from: desktop) {
      line-height: $line-height-s;
    }
  }
}
