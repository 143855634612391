@charset "utf-8";

.c-area-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: index($zindex, modal);
  opacity: 1;
  transition: $btn-transition;
  transform: translateY(0%);

  &\@desktop {
    @include mq($from: desktop) {
      position: static;
      z-index: auto;
    }
  }

  &--is-hidden\@mobile {
    @include mq($until: desktop) {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  &__frame\@mobile {
    @include mq($until: desktop) {
      display: flex;
      width: 100vw;
      height: 100%;
    }
  }

  &__body\@mobile {
    @include mq($until: desktop) {
      @include shadow-top;
      background-color: #FFF;
      width: 100%;
      height: calc(100% - #{$space-m});
      border-radius: $space-s $space-s 0 0;
      position: relative;
      top: $space-m;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  @supports (padding-bottom: constant(safe-area-inset-bottom)) {

    &__body\@mobile {
      padding-bottom: constant(safe-area-inset-bottom);
    }
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {

    &__body\@mobile {
      padding-bottom: env(safe-area-inset-bottom);
    }
  }

  &__button-close\@mobile {
    @include mq($until: desktop) {
      position: absolute;
      top: $space-m;
      right: $space-m;
    }
  }

  $content-padding-bottom: 68px;

  &__content\@mobile {
    @include mq($until: desktop) {
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: 100%;
      padding-bottom: $content-padding-bottom;
    }
  }

  @supports (padding-bottom: constant(safe-area-inset-bottom)) {

    &__content\@mobile {
      @include mq($until: desktop) {
        padding-bottom: calc(constant(safe-area-inset-bottom) + #{ $content-padding-bottom });
      }
    }
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {

    &__content\@mobile {
      @include mq($until: desktop) {
        padding-bottom: calc(env(safe-area-inset-bottom) + #{ $content-padding-bottom });
      }
    }
  }

  &__title\@mobile {
    @include mq($until: desktop) {
      @include title-xl;
      padding: $space-m;
      line-height: $line-height-m;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: $color-white;
    padding: $space-s $space-m;

    &::before {
      content: '';
      width: 100%;
      height: $space-m;
      position: absolute;
      left: 0;
      top: -#{$space-m};
      @include gradient-top;
    }
  }

  @supports (padding-bottom: constant(safe-area-inset-bottom)) {

    &__footer {
      padding-bottom: calc(constant(safe-area-inset-bottom) + #{ $space-s });
    }
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {

    &__footer {
      padding-bottom: calc(env(safe-area-inset-bottom) + #{ $space-s });
    }
  }

  &__footer\@desktop {
    @include mq($from: desktop) {
      position: relative;
      justify-content: center;
      padding: 0;

      &::before {
        content: none;
      }
    }
  }

  &__footer--min\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }

  &__footer-inner {
    display: flex;
    align-items: center;
  }

  &__footer-result {
    color: $color-grey-1;
    padding: 0 $space-m 0 0
  }

  &__footer-result\@desktop {
    @include mq($from: desktop) {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  &__corresponding {
    @include title-xs;
    display: block;
    color: $color-grey-1;
    white-space: nowrap;
  }

  &__important {
    @include title-l;
    color: $color-important;
  }

  &__footer-btn-wrap {
    width: 100%;
    margin: 0 auto;
  }

  &__footer-btn-wrap\@desktop {
    @include mq($from: desktop) {
      width: auto;
    }
  }

  &__footer-btn {
    padding: $space-m;
  }

  &__footer-btn--full {
    width: 100%;
  }

  &__footer-btn--full\@desktop {
    @include mq($from: desktop) {
      width: auto;
    }
  }
}
