@charset "utf-8";

.c-tooltip {
  position: relative;
  display: inline-block;

  &__wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: index($zindex, container_hover);
  }

  &__list {
    text-align: left;
    font-size: $font-size-s;
    background: $color-white;
    padding: $space-m;
    border-radius: $space-s;
    @include shadow-top;
    position: relative;
    margin-top: 6px;
    white-space: nowrap;

    &::before {
      position: absolute;
      content: "";
      border-bottom: $space-s solid $color-white;
      border-right: $space-s solid transparent;
      border-left: $space-s solid transparent;
      top: -#{$space-s};
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--right {

    .c-tooltip__wrapper {
      left: auto;
      right: -#{$space-xs};
      transform: none;
    }

    .c-tooltip__list {

      &::before {
        left: auto;
        transform: none;
        right: $space-m;
      }
    }
  }

  &--left {

    .c-tooltip__wrapper {
      right: auto;
      left: -#{$space-xs};
      transform: none;
    }

    .c-tooltip__list {

      &::before {
        right: auto;
        transform: none;
        left: $space-m;
      }
    }
  }

  &__item {
    line-height: $line-height-m;
  }

  &__item + &__item {
    margin-top: $space-m;
  }

  &__wrapper--is-hidden {
    display: none;
  }
}
