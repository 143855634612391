@charset "utf-8";

.c-job-search-mainvisual {
  position: relative;

  @include mq($from: desktop) {
    width: $width-desktop-main-column;
  }

  &__img {
    width: 100%;
  }

  &__img--height-fit {
    height: 100%;
  }

  &__img--object-fit-cover\@mobile {
    @include mq($until: desktop) {
      object-fit: cover;
    }
  }

  &__img\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s $space-s 0 0;
    }
  }

  &__title {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-wide;
    line-height: $line-height-s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    font-size: $font-size-3xl;
    left: $space-m;
    right: $space-m;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-4xl;
      left: $space-xl;
      right: $space-xl;
    }
  }

  &__subtitle {
    color: $color-grey-1;
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
  }

  &__subtitle\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-l;
    }
  }

  &__job {
    @include title-m;
    color: $color-grey-0;
  }

  &__job\@desktop {
    @include mq($from: desktop) {
      @include title-l;
    }
  }
}
