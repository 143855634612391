@charset "utf-8";

.c-featured-job-offer-card {
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  @include shadow-card;
  border-radius: $space-s;
  align-items: stretch;
  transition: $btn-transition;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @include mq($from: desktop) {
      @include card-hover;
    }
  }

  &__image-box {
    @include image-box;
    border-radius: $space-s $space-s 0 0;
    height: 192px;
    max-width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image-box\@desktop {
    @include mq($from: desktop) {
      height: 166px;
    }
  }

  &__image {
    width: 100%;
  }

  &__txt-area {
    position: relative;
    padding: $space-m $space-m 0;
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
  }

  &__title-container {
    overflow: hidden;
    margin-bottom: $space-m;
  }

  &__title {
    @include ellipsis-multiple-line(3, $font-size-l, $line-height-s, $color: $color-link);
  }

  &__summary {
    border-top: 1px solid $color-grey-6;
    margin: $space-m 0 0;
  }

  &__table {
    border-collapse: collapse;
    margin: $space-m 0 0;
  }

  &__table-th {
    color: $color-grey-0;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;
    padding: 0 0 $space-xs;
    display: block;
  }

  &__table-td {
    padding-bottom: 12px;
    display: block;
  }

  &__td-container {
    overflow: hidden;
  }

  &__td-txt {
    color: $color-grey-1;
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
  }

  &__td-txt--full {
    max-height: initial;
  }

  &__td-txt--full::before,
  &__td-txt--full::after {
    content: none;
  }

  &__tag-list {
    overflow: hidden;
    border-top: 1px solid $color-grey-6;
    padding: $space-xs $space-m $space-s;

    &--celebration_money {
      border-top: none;
      margin-top: -$space-xs;
      padding: 0 $space-s $space-s $space-s;
    }
  }

  &__tag-item {
    margin: $space-xs $space-xs 0 0;
    float: left;
  }

  &__tag-new {
    position: absolute;
    top: -$space-s;
    left: $space-m;
  }

  &__btn-area {
    padding: $space-s;
    margin-top: auto;
  }

  &__btn-list {
    display: flex;
    overflow: hidden;
  }

  &__btn-item {
    flex: 1 1 0;
    padding: 0 0 0 $space-xs;

    &:first-child {
      padding: 0 $space-xs 0 0;
    }
  }

  &__contents {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .c-button {
    padding: 8px;
    font-size: 16px;
  }
}
