@charset "utf-8";

.c-footer-contact {
  padding: $space-s $space-m $space-m;
  border-radius: $space-s;
  border: 1px solid $color-primary;
  background: rgba($color-primary, $alpha-low);

  &\@mobile {
    display: block;
    margin-top: $space-xl;
    @include mq($from: desktop) {
      display: none;
    }
  }

  &\@desktop {
    display: none;
    @include mq($from: desktop) {
      flex: 0 0 373px;
      margin-top: 0;
      position: relative;
      display: block;
    }
  }

  &__title {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    line-height: 2;
    color: $color-grey-0;
    margin: 0 0 $space-s;
    text-align: center;
  }

  &__notice {
    font-size: $font-size-s;
    color: $color-grey-2;
    margin-top: $space-s;
  }

  &__text {
    margin-top: $space-m;
  }

  &__submit {
    margin: $space-m auto 0 auto;
    width: 90px;
    white-space: nowrap;
  }

  &__submit\@desktop {
    @include mq($from: desktop) {
      margin: $space-s 0 0 auto;
    }
  }

  &__error {
    font-size: $font-size-s;
    color: $color-important;
    font-weight: $font-weight-bold;
    line-height: $line-height-l;
  }

  &__msg {
    font-size: $font-size-s;
    color: $color-grey-2;
    margin-top: $space-s;
  }

  &__link {
    margin-top: $space-m;
    height: 70px;
  }
}
