@charset "utf-8";

.c-file-upload {

  &__button {
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: $space-m;
  }

  &__row--operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-basis: 100px;
  }

  &__txt {
    color: $color-grey-0;
    font-size: $font-size-m;
  }

  &__delete {
    @include icon-m;
    cursor: pointer;
  }

  &__attachment-icon {
    cursor: pointer;
    background: $color-grey-6;
    padding: $space-s;
    border-radius: 50%;
    height: 40px;
    display: inline-block;
    transition: all 0.3s ease;

    &:hover {
      @include mq($from: desktop) {
        background: $color-white;
        color: $color-grey-0;
        @include shadow-top;
      }
    }

    svg {
      @include icon-l;
    }
  }
}
