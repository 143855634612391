@charset "utf-8";

.c-search-filters {

  &--mt20 {
    margin-top: 20px;
  }

  &__dropdowns {
    display: flex;
    flex-wrap: wrap;
    gap: 12px $space-s;
    align-items: center;
  }

  &__label-all-container {
    display: flex;
    justify-content: space-between;
  }

  &__label-container {
    display: flex;
    flex-wrap: wrap;
    gap: $space-s;
    padding: 20px 0;
  }

  &__label {
    color: $color-grey-1;
    font-size: $font-size-xs;
    display: flex;
    gap: $space-xs;
    align-items: center;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: $line-height-s;
    padding: 6px 10px;
    background-color: rgba(205, 239, 237, 0.6);
    border-radius: 50px;
    cursor: pointer;
  }

  &__close {
    width: 8px;
    height: 8px;
    fill: $color-grey-1;
  }

  &__clear-container {
    cursor: pointer;
    display: flex;
    gap: $space-s;
    align-items: center;
    font-weight: normal;
    color: $color-important;
    font-size: $font-size-s;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .c-filter-button {
    margin-bottom: $space-m;
    border: 1px solid $color-grey-5;
    display: flex;
    justify-content: center;
    width: 100%;
    line-height: 1;
    padding: 12px 0;

    &__icon {
      margin: 0 $space-s 0 0;
      width: 16px;
      height: 16px;
    }
  }

  .c-arrowed-accordion__content {
    background: none;
  }

  .c-arrowed-accordion__item:last-of-type {
    margin-bottom: 80px;
  }

  .js-search-filter-hidden-input {
    display: none;
  }

  .c-accordion-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__input {
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;

      label {
        font-size: $font-size-l;
        line-height: $line-height-s;
        padding: $space-s $space-m;
        letter-spacing: 0.2px;
        transition: $btn-transition;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
      }
    }

    &__show-more {
      text-align: center;
      color: $color-link;
      height: 40px;
      line-height: 40px;
    }
  }

  .c-full-modal {

    .c-search-filters__clear-container {
      position: absolute;
      left: 18px;
      font-size: 14px;
      gap: 6px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .c-full-modal__footer {

    .c-button {
      width: 100%;
    }
  }

  .c-filter-radio {

    &__label {
      font-size: $font-size-l;
      line-height: $line-height-s;
      padding: $space-s $space-m $space-s $space-2xl;
      letter-spacing: 0.2px;
      transition: $btn-transition;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: $space-m;
        width: $space-m;
        height: $space-m;
        border: $space-xs solid $color-grey-5;
        border-radius: 50%;
        background: $color-white;
        transition: $btn-transition;
      }
    }

    &__btn {
      display: none;
    }

    &__btn:checked + label {

      &::before {
        border-color: $color-primary;
      }
    }
  }
}
