@charset "utf-8";

.o-button-group {
  padding-top: $space-l;
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-direction: row;
    }
  }

  &--reverse\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &--space-top-0 {
    padding-top: 0;
  }

  &__item {
    flex: 1 1 0;
    text-align: center;
    margin-top: $space-s;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__item--text {
    text-align: left;
  }

  &__item:first-child {
    margin-top: 0;
  }

  &\@desktop > &__item\@desktop:last-child {
    @include mq($from: desktop) {
      margin-left: $space-s;
    }
  }

  &--reverse\@desktop > &__item\@desktop:first-child {
    @include mq($from: desktop) {
      margin-left: $space-s;
    }
  }
}
