@charset "utf-8";

@mixin image-box {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-grey-7;
  border-radius: $space-s;
}

@mixin image-circle {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-grey-7;
  border-radius: 50%;
}
