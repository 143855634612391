@charset "utf-8";

.c-modal-image {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: index($zindex, modal);
  opacity: 1;
  transition: $btn-transition;
  transform: translateY(0%);

  &--close {
    transform: translateY(200%);
    opacity: 0;
  }

  &__frame {
    position: relative;
    display: flex;
    width: 100vw;
    height: 100%;
    z-index: index($zindex, modal_frame);
    align-items: center;
    justify-content: center;
  }

  &__frame\@desktop {
    @include mq($from: desktop) {
      min-width: $width-desktop-container;
    }

    // tabletでのレイアウト崩れ回避
    @include mq(desktop, $tablet-portrait-width) {
      min-width: $width-desktop-column;
    }
  }

  &__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__body\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-column;
      height: auto;
      position: relative;
    }
  }

  &__button-close {
    position: absolute;
    top: $space-m;
    right: $space-m;
    transition: $btn-transition;
    z-index: index($zindex, modal_frame);

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top-green;
      }
    }
  }

  &__button-close\@desktop {
    @include mq($from: desktop) {
      top: $space-xl;
      right: $space-xl;
    }
  }

  &__content {
    height: 100%;
  }
}
