@charset "utf-8";

.c-foot-bar {
  @include shadow-top;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: $color-white;
  padding: $space-s;
  z-index: index($zindex, footbar);

  &\@desktop {
    @include mq($from: desktop) {
      width: 606px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: $space-s $space-s 0 0;
    }
  }

  &__message {
    text-align: center;
    padding: $space-s;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
  }

  &__emphasized {
    color: $color-important;
  }

  &--is-hidden {
    display: none;
  }

  // 応募画面における固定フッター制御用
  &--is-hidden-application {
    display: none !important;
  }
}

@supports (padding-bottom: constant(safe-area-inset-bottom)) {

  .c-foot-bar {
    padding-bottom: calc(constant(safe-area-inset-bottom) + #{ $space-s });
  }
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {

  .c-foot-bar {
    padding-bottom: calc(env(safe-area-inset-bottom) + #{ $space-s });
  }
}
