@charset "utf-8";

.c-heading {
  color: $color-grey-0;
  font-weight: $font-weight-bold;
  line-height: $line-height-s;
  letter-spacing: $letter-spacing-wide;
  font-size: $font-size-m;

  //-sp 22px
  &--3xl {
    font-size: $font-size-3xl;
  }

  //-pc 26px
  &--3xl\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-4xl;
    }
  }

  //-sp 18px
  &--xl {
    font-size: $font-size-xl;
  }

  //-pc 20px
  &--xl\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-2xl;
    }
  }

  //-20px
  &--2xl {
    font-size: $font-size-xl;
    @include mq($from: desktop) {
      font-size: $font-size-2xl;
    }
  }

  //-16px
  &--l {
    font-size: $font-size-l;
  }

  //-pc16px
  &--m\@desktop {
    @include mq($from: desktop) {
      @include title-l;
    }
  }

  //-12px
  &--s {
    font-size: $font-size-s;
  }

  //-10px
  &--xs {
    font-size: $font-size-xs;
  }

  //-color important
  &--important {
    color: $color-important;
  }

  //-color brown
  &--brown {
    color: $color-brown-0;
  }

  &--white {
    color: $color-white;
  }

  &--space {
    margin: 0 0 $space-s 0;
    @include mq($from: desktop) {
      margin: 0 $space-m 0 0;
    }
  }

  &--w100\@mobile {
    @include mq($until: desktop) {
      width: 100%;
    }
  }
}
