@charset "utf-8";

.c-modal-carousel {
  overflow: hidden;
  position: relative;

  &__slide {
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
  }

  &__slide-inner {
    @include shadow-new;
    margin: $space-m 0;
    position: relative;
  }

  &__slide-inner\@desktop {
    @include mq($from: desktop) {
      margin: $space-m;
    }
  }

  &__slide-img {
    max-width: 100%;
    display: block;
    position: relative;
    @include image-box;
    border-radius: 0;
  }

  &__slide-img\@desktop {
    @include mq($from: desktop) {
      max-width: 928px;
    }
  }

  &__image-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 212px;
    background-color: $color-grey-7;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__image-frame\@desktop {
    @include mq($from: desktop) {
      height: 525px;// 16:9 : 928/525
    }
  }

  &__btn-next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    // ボタン位置調整のためフォントサイズ分差し引く
    top: calc(50% - #{$font-size-m});
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    border-top-left-radius: $space-s;
    border-bottom-left-radius: $space-s;
    background: rgba($color-grey-0, $alpha-common);
    height: $space-xl;
    width: $space-xl;
  }

  &__btn-next\@desktop {
    @include mq($from: desktop) {
      right: $space-m;
    }
  }

  &__btn-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    // ボタン位置調整のためフォントサイズ分差し引く
    top: calc(50% - #{$font-size-m});
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;
    border-top-right-radius: $space-s;
    border-bottom-right-radius: $space-s;
    background: rgba($color-grey-0, $alpha-common);
    height: $space-xl;
    width: $space-xl;
  }

  &__btn-prev\@desktop {
    @include mq($from: desktop) {
      left: $space-m;
    }
  }

  &__caption {
    font-size: $font-size-m;
    color: $color-grey-1;
    text-align: center;
    padding: 0 $space-m;
  }
}
