@charset "utf-8";

textarea {
  display: block;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  appearance: none;
  outline: 0;
  border: none;
  -webkit-tap-highlight-color: initial;
}
