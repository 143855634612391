@charset "utf-8";

.c-search-selectbox {
  display: inline-flex;
  font-size: $font-size-m;

  &__title {
    align-self: center;
    color: $color-grey-2;
    font-weight: $font-weight-bold;
  }

  &__select {
    min-width: 145px;
    margin-left: $space-m;
  }

  &__currency {
    align-self: center;
    margin-left: $space-s;
    color: $color-grey-2;
    font-weight: $font-weight-normal;
  }

  &__new-line\@desktop {
    @include mq($from: desktop) {
      display: inline;
    }
  }
}
