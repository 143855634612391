@charset "utf-8";

.c-balloon {
  padding-top: $space-xs;

  &__inner {
    display: inline-block;
    position: relative;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    color: $color-white;
    text-align: center;
    background: $color-primary;
    border-radius: $space-xs;
    padding: $space-s;

    &::before {
      content: "";
      position: absolute;
      top: -$space-s;
      right: 0;
      left: 0;
      border-top: $space-xs solid transparent;
      border-right: $space-xs solid transparent;
      border-left: $space-xs solid transparent;
      border-bottom: $space-xs solid $color-primary;
      height: 0;
      width: 0;
      margin: auto;
    }
  }

  &__inner--round {
    border-radius: $space-l;
  }

  &__inner--yellow {
    background: $color-attention;
    line-height: $line-height-s;
    padding: $space-s $space-m;

    &::before {
      border-bottom: $space-xs solid $color-attention;
    }
  }

  &__inner--left {

    &::before {
      top: 0;
      right: inherit;
      left: -$space-s + 1;
      bottom: 0;
      border-top: $space-xs solid transparent;
      border-right: $space-xs solid $color-primary;
      border-left: $space-xs solid transparent;
      border-bottom: $space-xs solid transparent;
    }
  }

  &__inner--bottom {
    background: $color-attention;

    &::before {
      top: 25px;
      left: 0;
      bottom: 0;
      border-top: $space-xs solid $color-attention;
      border-right: $space-xs solid transparent;
      border-left: $space-xs solid transparent;
      border-bottom: $space-xs solid transparent;
    }
  }
}
