@charset "utf-8";

.c-inline-link-group {

  &\@desktop {
    flex: 1 1 0;
  }

  &__links {
    margin-top: $space-xl;

    &:first-child {
      margin-top: 0;
    }
  }

  &__links\@desktop {
    @include mq($from: desktop) {
      display: flex;
      margin-top: $space-s;
    }
  }

  &__caption {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    line-height: $line-height-l;
    color: $color-grey-0;
  }

  &__caption\@desktop {
    @include mq($from: desktop) {
      flex: 0 0 154px;
    }
  }

  &__list {
    overflow: hidden;
    font-size: $font-size-s;
    margin-top: $space-s;
  }

  &__list\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__item {
    float: left;
    line-height: $line-height-2xl;
    margin-right: 1.5em;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      line-height: $line-height-l;
      margin-right: 1em;
    }
  }

  &__item:last-child {
    margin-right: 0;
  }
}
