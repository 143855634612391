@charset "utf-8";

.c-about-us {
  width: 100%;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      width: 50%;
    }
  }

  &__img {
    width: 100%;
  }

  &__img--left\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s 0 0 $space-s;
    }
  }

  &__img--right\@desktop {
    @include mq($from: desktop) {
      border-radius: 0 $space-s $space-s 0;
    }
  }

  &__txt-area {
    color: $color-grey-1;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    padding: $space-l $space-m;
    position: relative;
  }

  &__txt-area\@desktop {
    @include mq($from: desktop) {
      padding: $space-3xl $space-xl $space-xl $space-xl;
    }
  }

  &__title {
    color: $color-grey-0;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-2xl;
    }
  }

  &__txt {
    line-height: $line-height-l;
  }

  &__title + &__txt {
    margin-top: $space-m;
  }
}
