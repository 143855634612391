@charset "utf-8";

.c-header {
  position: fixed;
  width: 100vw;// body-scroll-lockのreserveScrollbarGapがwidth: 100%だと効かないのでvw使う
  background-color: $color-primary;
  color: $color-white;
  z-index: index($zindex, header);
  transition: $btn-transition;
  transform: translateY(0%);

  // tabletでのレイアウト崩れ回避
  @include mq(desktop, $tablet-portrait-width) {
    width: 100%;
  }

  &\@desktop {
    @include mq($from: desktop) {
      overflow-x: auto;
    }
  }

  &--hidden {
    transform: translateY(-100%);
  }

  &--static {
    position: static;
    width: 100%;
  }

  &__inner {
    margin: 0 auto;
    width: 100%;
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      position: relative;
      width: $width-desktop-column;
    }
  }

  &__lead {
    font-size: $font-size-xs;
    color: $color-white;
    background-color: $color-primary;
    border-bottom: 1px solid rgba($color-white, $alpha-low-common);
    text-align: center;
    padding: $space-xs 0;
    font-weight: $font-weight-bold;
  }

  &__lead\@desktop {
    @include mq($from: desktop) {
      font-weight: $font-weight-normal;
      border-bottom: 0;
      padding: 0;
      text-align: left;
      margin-top: $space-s;
    }
  }

  &__title {
    padding: $space-s 0 $space-s $space-s;
    letter-spacing: 0;
  }

  // 2022-04 ロゴ刷新に伴うスタイル変更
  &__title-new {
    padding: 5px 0 4px 11px;
    letter-spacing: 0;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      padding: $space-xs 0;
      margin-left: 0;
      width: 332px;
    }
  }

  // 2022-04 ロゴ刷新に伴うスタイル変更
  &__title-new\@desktop {
    @include mq($from: desktop) {
      padding: $space-m 0;
      margin-left: 0;
    }
  }

  &__title-logo {
    width: 113px;
    padding: $space-xs 0;
    fill: $color-white;
    display: block;
  }

  &__title-logo\@desktop {
    @include mq($from: desktop) {
      width: 164px;
      height: 40px;
      padding: 0 0;
    }
  }

  &__title-text {
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
    margin-left: $space-xs;
  }

  &__title-text\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-m;
      margin-left: $space-s;
    }
  }
}
