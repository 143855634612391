@charset "utf-8";

.c-btn-group {
  display: flex;
  justify-content: flex-end;

  &__button {
    display: flex;
    align-items: center;
    background-color: $color-white;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    color: $color-link;
    position: relative;
    padding: $space-s $space-m;
    transition: $btn-transition;
  }

  &__button:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      z-index: index($zindex, header_hover);
    }
  }

  &__button:first-of-type {
    border-radius: $space-s 0 0 $space-s;
  }

  &__button:last-of-type {
    border-radius: 0 $space-s $space-s 0;
  }

  &__button + &__button {
    border-left: 1px  solid $color-grey-5;
  }

  &__icon-cover {
    @include icon-m;
    fill: $color-grey-3;
  }

  &__text {
    margin-left: $space-s;
  }

  &__badge {
    position: absolute;
    display: block;
    top: 2px;
    left: -2px;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    color: $color-white;
    background-color: $color-important;
    border-radius: $space-m;
    height: $space-m;
    line-height: $space-m;
    padding: 0 $space-xs;
    min-width: $space-m;
    text-align: center;
  }

  &__badge\@desktop {
    @include mq($from: desktop) {
      top: -#{$space-xs};
      left: $space-xs;
    }
  }

  &__badge:empty {
    display: none;
  }
}

.c-btn-group-no-login {
  display: flex;
  justify-content: flex-end;

  &__button {
    display: block;
    background-color: $color-white;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    color: $color-link;
    position: relative;
    padding: $space-s;
    transition: $btn-transition;
    border-radius: $space-s;
  }

  &__button:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      z-index: index($zindex, header_hover);
    }
  }

  &__button:last-of-type {
    margin-left: $space-s;
  }

  &__icon-cover {
    @include icon-m;
    fill: $color-grey-3;
  }
}
