@charset "utf-8";

.c-pagetop {
  position: relative;
  width: 100%;
  margin: 0 auto;

  &\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-column;
    }
  }

  &__body {
    background: $color-grey-6;
    width: $space-xl;
    height: $space-xl;
    cursor: pointer;
    border-radius: $space-s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: $space-xl;
    right: $space-m;
  }

  &__body\@desktop {
    @include mq($from: desktop) {
      transition: $btn-transition;
      right: 0;

      &:hover {
        background: $color-white;
        @include shadow-top;
      }
    }
  }

  &__arrowup {
    @include icon-m;
    fill: $color-grey-3;
  }
}
