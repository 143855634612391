@charset "utf-8";

.c-job-mainvisual {
  position: relative;

  @include mq($from: desktop) {
    width: $width-desktop-column;
  }

  &__img {
    width: 100%;
  }

  &__img--height-fit {
    height: 100%;
  }

  &__img\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s;
    }
  }

  &__title {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
    letter-spacing: $letter-spacing-wide;
    line-height: $line-height-xs;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    font-size: $font-size-2xl;
    left: $space-m;
    width: 92%;
    @include mq($from: desktop) {
      line-height: $line-height-s;
    }
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-4xl;
      left: $space-xl;
    }
  }

  &__subtitle {
    color: $color-grey-1;
    font-size: $font-size-m;
    font-weight: $font-weight-normal;
  }

  &__subtitle\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-xl;
    }
  }
}
