@charset "utf-8";

$space-tokens: (
  xs: $space-xs,
  s: $space-s,
  m: $space-m,
  l: $space-l,
  xl: $space-xl,
  '2xl': $space-2xl,
  '3xl': $space-3xl,
);

.o-box {
  display: block;
  padding: 0;

  @include withMediaQuery("&") {
    display: block;
    padding: 0;
  }

  @each $key, $space in $space-tokens {

    @include withMediaQuery("&--#{ $key }") {
      padding: $space;
    }

    @include withMediaQuery("&--#{ $key }-t") {
      padding-top: $space;
    }

    @include withMediaQuery("&--#{ $key }-b") {
      padding-bottom: $space;
    }

    @include withMediaQuery("&--#{ $key }-l") {
      padding-left: $space;
    }

    @include withMediaQuery("&--#{ $key }-r") {
      padding-right: $space;
    }

    @include withMediaQuery("&--#{ $key }-v") {
      padding-top: $space;
      padding-bottom: $space;
    }

    @include withMediaQuery("&--#{ $key }-h") {
      padding-left: $space;
      padding-right: $space;
    }
  }
}
