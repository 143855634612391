@charset "utf-8";

.c-icon {

  &-arrow-m-bottom {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-arrow-m-top {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-arrow-m-left {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-arrow-m-right {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-arrow-m-up {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-arrow-m-down {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-check {
    @include icon-m;
    fill: $color-grey-5;
  }

  &-checked {
    @include icon-m;
    fill: $color-primary;
  }

  &-choose-on {
    @include icon-m;
    fill: $color-grey-5;
  }

  &-choose-off {
    @include icon-m;
    fill: $color-primary;
  }

  &-close {
    @include icon-m;
  }

  &-close-white {
    @include icon-m;
    fill: $color-white;
  }

  &-play-button {
    @include icon-m;
    fill: $color-white;
  }

  &-badge {
    @include icon-xl;
    fill: $color-primary;
  }

  &-book {
    @include icon-xl;
    fill: $color-primary;
  }

  &-call {
    @include icon-xl;
    fill: $color-primary;
  }

  &-celeb {
    @include icon-xl;
    fill: $color-primary;
  }

  &-gear {
    @include icon-xl;
    fill: $color-primary;
  }

  &-home {
    @include icon-xl;
    fill: $color-primary;
  }

  &-id {
    @include icon-xl;
    fill: $color-primary;
  }

  &-input {
    @include icon-xl;
    fill: $color-primary;
  }

  &-mail {
    @include icon-xl;
    fill: $color-primary;
  }

  &-paper {
    @include icon-xl;
    fill: $color-primary;
  }

  &-send {
    @include icon-xl;
    fill: $color-primary;
  }

  &-portrait {
    @include icon-xl;
    fill: $color-primary;
  }

  &-shield {
    @include icon-xl;
    fill: $color-primary;
  }

  &-star-stroke {
    @include icon-xl;
    fill: $color-primary;
  }

  &-star-fill {
    @include icon-xl;
    fill: $color-primary;
  }

  &-star-fill-grey {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-favorite {
    @include icon-m;
    fill: $color-grey-3;
  }

  &-error {
    @include icon-3xl;
  }
}
