@charset "utf-8";

.c-line-card {
  position: relative;
  width: 100%;

  &__bg {
    border-radius: $space-s;
    width: 100%;
    height: auto;
  }

  &__qr\@desktop {
    @include mq($from: desktop) {
      position: absolute;
      top: 44px;
      left: 127px;
      width: 90px;
    }
  }

  &__title {
    color: $color-white;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    position: absolute;
    text-align: center;
    top: $space-m;
    width: 100%;
  }

  &__button-area {
    position: absolute;
    bottom: $space-s;
    padding: 0 $space-m;
    width: 100%;
  }

  &__txt {
    color: $color-white;
    margin: $space-xs 0 0;
    text-align: center;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
  }

  &\@desktop {
    @include mq($from: desktop) {
      width: 249px;
      height: 275px;

      .c-line-card__button {
        width: 219px;
      }
    }
  }
}
