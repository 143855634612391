@charset "utf-8";

.c-search-checkbox {
  display: flex;

  &__input {
    display: none;
  }

  &__mimic {
    padding: $space-m;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    transition: $btn-transition;
    cursor: pointer;
  }

  &__mimic::after {
    content: "";
    width: 1px;
    height: $space-l;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-grey-5;
    display: block;
  }

  &__mimic:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      border-radius: $space-s;
    }
  }

  &__mimic:hover::after {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__mimic\@desktop {
    @include mq($from: desktop) {
      padding: $space-m $space-s;
      background-color: none;
      align-self: center;
    }
  }

  &__mimic\@desktop::after {
    @include mq($from: desktop) {
      height: $space-m;
    }
  }

  &__mimic-inner {
    position: relative;
    display: block;
    width: $space-l;
    height: $space-l;
    border-radius: $space-xs;
    background: $color-grey-5;
  }

  &__mimic-inner\@desktop {
    @include mq($from: desktop) {
      width: $space-m;
      height: $space-m;
    }
  }

  &__link {
    font-weight: $font-weight-bold;
    color: $color-link;
    padding: $space-m;
    width: 100%;
    display: flex;
    align-items: center;
    transition: $btn-transition;
    line-height: $line-height-s;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-s;
      padding: $space-m 0 $space-m $space-s;
    }

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top;
        border-radius: $space-s;
        color: $color-grey-0;
      }
    }
  }

  &__link--disabled {
    @include mq($from: desktop) {
      font-size: $font-size-s;
      padding: $space-m 0 $space-m $space-s;
    }
  }

  &__text {
    padding-right: $space-m;
    transition: $btn-transition;
  }

  &__link\@desktop:hover &__text {
    @include mq($from: desktop) {
      transform: translateX($space-s);
    }
  }

  &__input:checked + &__mimic > &__mimic-inner {
    background: $color-primary;
    border: 1px solid $color-primary;
    position: relative;
    width: $space-l;
    height: $space-l;
    display: block;
  }

  &__input:checked + &__mimic\@desktop > &__mimic-inner {
    @include mq($from: desktop) {
      width: $space-m;
      height: $space-m;
    }
  }

  &__input:checked + &__mimic > &__mimic-inner::before {
    position: absolute;
    left: 5px;
    top: 14px;
    display: block;
    content: '';
    width: 6px;
    height: 2px;
    background: $color-white;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  &__input:checked + &__mimic\@desktop > &__mimic-inner::before {
    @include mq($from: desktop) {
      left: 1px;
      top: 9px;
      width: 5px;
      height: 2px;
    }
  }

  &__input:checked + &__mimic > &__mimic-inner::after {
    display: block;
    position: absolute;
    left: 10px;
    top: 14px;
    content: "";
    width: 10px;
    height: 2px;
    background: #FFFFFF;
    transform: rotate(-53deg);
    transform-origin: left center;
  }

  &__input:checked + &__mimic\@desktop > &__mimic-inner::after {
    @include mq($from: desktop) {
      width: 8px;
      height: 2px;
      left: 5px;
      top: 9px;
      transform: rotate(-45deg);
    }
  }
}
