@charset "utf-8";

.u-hidden {
  display: none !important;

  &\@desktop {
    @include mq($from: desktop) {
      display: none !important;
    }
  }

  &\@mobile {
    @include mq($until: desktop) {
      display: none !important;
    }
  }
}

.u-transparent {
  opacity: 0;
}
