@charset "utf-8";

.c-search-box {
  display: flex;
  @include mq($from: desktop) {
    align-items: flex-start;
  }

  &__top {
    display: block;
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__none_clickable {
    pointer-events: none;
    color: #BFBEBC;
    font-weight: normal;
  }

  &__none_clickable span {
    color: #BFBEBB;
    font-size: 12px;
  }

  &__clickable span {
    font-size: 12px;
    color: #888;
  }

  &__arrow_selected {
    background: $color-grey-6;
  }

  &__input {
    flex-grow: 1;
    position: relative;
    border-radius: $space-s;
    background: $color-grey-6;
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-l;
    height: $space-3xl;//ie11でのテキスト上下ずれ
    padding: $space-s $space-2xl $space-s $space-m;
    transition: $btn-transition;
    width: 100%;

    &-top {
      margin-bottom: $space-m;
      @include mq($from: desktop) {
        margin-right: $space-m;
        margin-bottom: 0;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-placeholder;
      font-weight: $font-weight-normal;
    }

    /* IE 10+ */
    &:-ms-input-placeholder {
      color: $color-placeholder;
      font-weight: $font-weight-normal;
    }

    &:focus {
      @include shadow-input;
      background: $color-white;
    }
  }

  &__button {
    position: relative;
    background: transparent;
    height: $space-3xl;
    width: $space-3xl;
    margin-left: -$space-3xl;
  }

  &__search-icon {
    display: block;
    stroke: $color-icon-search;
    fill: transparent;
    height: $space-l;
    width: $space-l;
    margin: auto;
  }

  &__search-icon\@desktop {
    height: $space-m;
    width: $space-m;
  }

  &__category-list {
    position: relative;

    @include mq($from: desktop) {
      padding: 0;
    }

    &-wrapper {
      position: relative;

      @include mq($from: desktop) {
        display: block;
        margin-right: $space-m;
        width: 100%;
      }

      &-icon {
        position: absolute;
        top: 24px;
        right: 20px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        width: 12px;
        height: 12px;
        fill: $color-grey-1;
        cursor: pointer;
      }
    }

    &-item {
      position: absolute;
      background-color: $color-white;
      z-index: index($zindex, mainvisual_inner);
      width: 100%;
      top: -20px;
      border-radius: 0 0 12px 12px;
      @include shadow-input;

      @include mq($from: desktop) {
        top: 0;
      }

      &-map {
        top: 0;
      }
    }

    .js-search-select-candidate {
      padding: $space-s $space-m;
      @include mq($from: desktop) {
        padding: $space-m;
      }

      &:hover {
        background: $color-grey-6;
        cursor: pointer;
      }
    }
  }
}

.c-search-box-container {
  padding: $space-l;
  position: relative;
  color: $color-link;
  font-weight: $font-weight-bold;

  &::after {
    content: '';
    width: 100%;
    height: $space-m;
    position: absolute;
    left: 0;
    bottom: -#{$space-m};
    @include gradient-bottom;
  }
}

.c-distance-list-wrapper {
  width: 100%;
  margin-bottom: 15px;
  @include mq($from: desktop) {
    width: 70%;
    margin-bottom: 0;
  }
}
