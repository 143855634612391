@charset "utf-8";

.c-video-interview {
  margin-top: $space-m;
  position: relative;

  &__video-wrapper {
    position: relative;
    width: 100%;
    cursor: pointer;

    &::before {
      content: "";
      display: block;
      padding-top: 56.25%; /* videoの幅:高さを16:9に固定 */
    }
  }

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__processing {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    opacity: 0.4;
  }

  &:hover &__play-button {
    opacity: 0.5;
  }

  &__video-item {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: $space-s;
    background: #000;
  }

  &__error {
    margin-top: $space-m;
    color: $color-important;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
  }

  &--preview-error {
    background-color: $color-grey-7;
    color: $color-grey-2;
    padding: 30px;
    border-radius: 8px;

    &__icon {
      margin: auto;
      margin-bottom: 15px;
      width: 66px;
      height: 66px;
      fill: $color-grey-2;
    }

    &__annotation {
      text-align: center;
      font-size: 14px;
    }
  }
}
