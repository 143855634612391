@charset "utf-8";

.c-apply-job-offer-card {

  &__title {
    color: $color-grey-0;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-l;
  }

  &__buttons {
    margin-top: $space-l;
  }

  &__button--large {
    flex-grow: 1;
  }

  &__button + &__button {
    margin-top: $space-s;
  }

  &__button\@desktop + &__button\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
      margin-left: $space-s;
    }
  }

  &__notification {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    line-height: $line-height-l;
    color: $color-grey-0;
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-apply-job-offer-card__title {
        font-size: $font-size-2xl;
      }
    }
  }
}
