@charset "utf-8";

.c-tag {
  display: inline-block;
  border-radius: $space-s;
  background: $color-green-bright;
  color: $color-link;
  font-size: $font-size-s;
  text-align: center;
  line-height: $line-height-l;
  padding: 0 $space-s;
  white-space: nowrap;

  &--height-m {
    line-height: $line-height-m;
  }

  &--new {
    border-radius: $space-l;
    background: $color-important;
    @include shadow-new;
    color: $color-white;
    font-weight: $font-weight-bold;
    font-size: $font-size-xs;
  }

  &--small {
    border-radius: $space-xs;
    font-size: $font-size-xs;
    padding: 2px $space-xs;
    line-height: 1;
  }

  &--grey-4 {
    background: $color-grey-4;
    color: $color-grey-2;
  }

  &--brown-1 {
    background: $color-brown-1;
    color: $color-brown-0;
  }

  &--brown-1-small {
    border: 1px solid $color-brown-0;
    border-radius: $space-xs;
    background: $color-brown-1;
    color: $color-brown-0;
    font-size: $font-size-xs;
    padding: 2px $space-xs;
    line-height: 1;
  }

  &--important-alpha-low {
    background: rgba($color-important, $alpha-low);
    color: $color-important;
  }

  &--attention {
    background: $color-attention;
    color: $color-white;
  }

  &--important {
    background: $color-important;
    color: $color-white;
  }

  &--important-small {
    border: 1px solid $color-important;
    border-radius: $space-xs;
    background: rgba($color-important, $alpha-low);
    color: $color-important;
    font-size: $font-size-xs;
    padding: 2px $space-xs;
    line-height: 1;
  }

  &--white-space-normal {
    white-space: normal;
  }

  &--large {
    padding: $space-s;
    width: 100%;
    border-radius: 0;

    @include mq($from: desktop) {
      text-align: left;
    }
  }

  &__main {
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
    line-height: 15px;
  }

  &__description {
    font-size: $font-size-xs;
    line-height: 13.5px;
  }

  &--mt8 {
    margin-top: 8px;
  }
}
