@charset "utf-8";

.u-width {

  &-auto {
    width: auto !important;
  }

  &-124\@mobile {
    @include mq($until: desktop) {
      width: 124px !important;
    }
  }

  &-50 {
    width: 50px !important;
  }

  &-108 {
    width: 108px !important;
  }

  &-125\@desktop {
    @include mq($from: desktop) {
      width: 125px !important;
    }
  }

  &-80\@mobile {
    @include mq($until: desktop) {
      width: 80px !important;
    }
  }

  &-145\@desktop {
    @include mq($from: desktop) {
      width: 145px !important;
    }
  }

  &-180 {
    width: 180px !important;
  }

  &-320\@desktop {
    @include mq($from: desktop) {
      max-width: 320px !important;
    }
  }

  &-400\@desktop {
    @include mq($from: desktop) {
      width: 400px !important;
    }
  }

  &-410\@desktop {
    @include mq($from: desktop) {
      width: 410px !important;
    }
  }

  &-430\@desktop {
    @include mq($from: desktop) {
      width: 430px !important;
    }
  }

  &-full {
    width: 100% !important;
  }

  &-full\@mobile {
    @include mq($until: desktop) {
      width: 100% !important;
    }
  }
}
