@charset "utf-8";

img {
  vertical-align: middle;
  border: 0;
}

img:not([src]),
img[src=""] {
  visibility: hidden;
}
