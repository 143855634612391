@charset "utf-8";

.c-modal {
  $head-height: 73px;
  $foot-height: 68px;
  $outer-height: 40px * 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: index($zindex, modal);
  opacity: 1;
  transition: $btn-transition;
  transform: translateY(0);

  &--close {
    transform: translateY(200%);
  }

  &__frame {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: index($zindex, modal_closer);
  }

  &__frame\@desktop {
    @include mq($from: desktop) {
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__body {
    @include shadow-top;
    background-color: #FFF;
    width: 100%;
    height: calc(100% - #{ $space-m });
    border-radius: $space-s $space-s 0 0;
    position: relative;
    top: $space-m;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &__body\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-column;
      height: auto;
      border-radius: $space-s;
      max-height: calc(100vh - #{ $space-2xl } * 2);
      top: auto;
    }
  }

  &__button-close {
    position: absolute;
    top: $space-m;
    right: $space-m;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top-green;
      }
    }
  }

  &__content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-bottom: $foot-height;
  }

  @supports (padding-bottom: constant(safe-area-inset-bottom)) {

    &__content {
      padding-bottom: calc(constant(safe-area-inset-bottom) + #{ $foot-height });
    }
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {

    &__content {
      padding-bottom: calc(env(safe-area-inset-bottom) + #{ $foot-height });
    }
  }

  &__content\@desktop {
    @include mq($from: desktop) {
      padding: 0 $space-l $space-l;
      overflow-y: auto;
      height: auto;
      max-height: calc(100vh - (#{ $head-height + $foot-height + $outer-height }));
    }
  }

  &__content-inner {
    padding: 0 $space-m;
  }

  &__content-inner\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }

  &__title {
    @include title-xl;
    padding: $space-m $space-3xl $space-m $space-m;
    line-height: $line-height-m;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include title-2xl;
      padding: $space-l $space-3xl+$space-s $space-l $space-l;
    }
  }

  &__small-title {
    color: $color-grey-0;
    @include title-m;
    padding-left: $space-m;
  }

  &__small-title\@desktop {
    @include mq($from: desktop) {
      padding-left: 0;
    }
  }

  &__footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: $color-white;
    padding-top: $space-s;
    padding-right: $space-m;
    padding-left: $space-m;
    padding-bottom: $space-s;
    transform: translate3d(0, 0, 0);
    z-index: index($zindex, modal_foot);

    &::before {
      content: '';
      width: 100%;
      height: $space-m;
      position: absolute;
      left: 0;
      top: -#{$space-m};
      @include gradient-top;
    }
  }

  @supports (padding-bottom: constant(safe-area-inset-bottom)) {

    &__footer {
      padding-bottom: calc(constant(safe-area-inset-bottom) + #{ $space-s });
    }
  }

  @supports (padding-bottom: env(safe-area-inset-bottom)) {

    &__footer {
      padding-bottom: calc(env(safe-area-inset-bottom) + #{ $space-s });
    }
  }

  &__footer-inner {
    display: flex;
    align-items: center;
  }

  &__footer\@desktop {
    @include mq($from: desktop) {
      position: relative;
      justify-content: center;
    }
  }

  &__footer-result {
    color: $color-grey-1;
    padding: 0 $space-m 0 0
  }

  &__footer-result\@desktop {
    @include mq($from: desktop) {
      position: absolute;
      top: 50%;
      left: $space-m;
      transform: translateY(-50%);
    }
  }

  &__corresponding {
    @include title-xs;
    display: block;
    color: $color-grey-1;
    white-space: nowrap;
  }

  &__important {
    @include title-l;
    color: $color-important;
  }

  &__footer-btn-wrap {
    width: 100%;
    margin: 0 auto;
  }

  &__footer-btn-wrap\@desktop {
    @include mq($from: desktop) {
      width: auto;
    }
  }

  &__footer-btn {
    padding: $space-m;
  }

  &__footer-btn--full {
    width: 100%;
  }

  &__footer-btn--full\@desktop {
    @include mq($from: desktop) {
      width: auto;
    }
  }

  &__footer-btn-horizontal {
    display: flex;
    width: 100%;
  }

  &__footer-btn-horizontal\@desktop {
    @include mq($from: desktop) {
      justify-content: center;
    }
  }

  &__footer-btn-item {
    flex: 1 1 calc(100% / 2);
  }

  &__footer-btn-item:last-child {
    margin-left: $space-s;
  }

  &__footer-btn-item\@desktop {
    @include mq($from: desktop) {
      flex: 0 1 auto;
    }
  }

  &__footer-btn-with-link {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $space-m;
    align-items: center;
  }

  &__footer-btn-with-link\@desktop {
    @include mq($from: desktop) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__footer-btn-with-link\@desktop::after {
    @include mq($from: desktop) {
      display: block;
      content: '';
    }
  }

  &__footer-btn-with-link__link {
    color: $color-link;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;

    &:hover {
      @include mq($from: desktop) {
        text-decoration: underline;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {

  .c-modal {

    &__footer-btn-with-link {
      display: flex;
    }

    &__footer-btn-with-link > * {
      width: calc(100% / 3);
    }
  }
}
