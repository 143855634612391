@charset "utf-8";

.c-map-button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &--reverse\@desktop {
    @include mq($from: desktop) {
      flex-direction: row-reverse;
    }
  }

  &__item {
    position: relative;
    text-align: center;
    margin-top: $space-s;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__item--text {
    text-align: left;
  }

  &__item:first-child {
    margin-top: 0;
  }

  &__keep-btn {
    color: $color-grey-1;
  }

  &__keep-btn\@desktop {
    @include mq($from: desktop) {
      margin: 0 auto;
      width: 145px;
    }
  }

  &--reverse\@desktop > &__item\@desktop:first-child {
    @include mq($from: desktop) {
      margin-left: $space-m;
      width: 100%;
    }
  }

  &--reverse\@desktop > &__item\@desktop:last-child {
    @include mq($from: desktop) {
      position: relative;
    }
  }

  &__balloon {
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__balloon\@desktop {
    @include mq($from: desktop) {
      width: 170px;
      top: 100%;
    }
  }
}
