@charset "utf-8";

.o-orientation-boxes {
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__item {
    flex: 1;
  }

  &__item + &__item {
    margin-left: 0;
    margin-top: $space-s;
  }

  &__item--space-m + &__item--space-m {
    margin-top: $space-m;
  }

  &\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: 0;
      margin-left: $space-s;
    }
  }
}
