@charset "utf-8";

.c-radio {
  display: flex;
  flex-wrap: wrap;
  margin-right: - $space-xs;
  margin-bottom: - $space-xs;

  &__input {
    margin-right: $space-xs;
    margin-bottom: $space-xs;
  }

  &__label {
    color: $color-placeholder;
    font-size: $font-size-l;
    background: $color-grey-6;
    border-radius: $space-s;
    padding: $space-m $space-m $space-m $space-2xl;
    transition: $btn-transition;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    line-height: 1;

    &::before {
      content: '';
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: $space-m;
      width: $space-m;
      height: $space-m;
      border: $space-xs solid $color-grey-5;
      border-radius: 50%;
      background: $color-white;
      transition: $btn-transition;
    }

    &:hover {
      @include mq($from: desktop) {
        background: $color-white;
        font-weight: $font-weight-bold;
        @include shadow-top;
        color: $color-grey-0;

        .c-radio__txt {
          opacity: 1;
        }
      }
    }
  }

  &__btn {
    display: none;
  }

  &__btn:checked + &__label {
    font-weight: $font-weight-bold;
    color: $color-grey-0;

    .c-radio__txt {
      opacity: 1;
    }

    &::before {
      border-color: $color-primary;
    }
  }
}
