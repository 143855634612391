@charset "utf-8";

.c-company-detail {

  &__img {
    width: 100%;
  }

  &__img-area {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 211px;
  }

  &__img-area--noimg {
    height: auto;
  }

  &__txt-area {
    margin-top: $space-l;
  }

  &__table-summary {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    margin-top: $space-l;
  }

  &__table {
    margin-top: $space-l;
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-company-detail__row {
        display: flex;
        flex-direction: row;
      }

      .c-company-detail__img-area {
        flex-basis: 295px;
        flex-shrink: 0;
        height: 166px;
        margin: 0 $space-m 0 0;
      }

      .c-company-detail__txt-area {
        margin: 0;
      }
    }
  }
}
