@charset "utf-8";

.c-image-fit {
  width: 100%;

  &\@desktop {
    @include mq($from: desktop) {
      max-width: 100%;
      width: auto;
    }
  }
}
