@charset "utf-8";

.c-area-select {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__region-list {
    display: flex;
    flex-wrap: wrap;
    padding: $space-m;
  }

  &__region-list\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 $space-m;
    }
  }

  &__region-list--map-search\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 0 $space-m;
    }
  }

  &__region-item {
    flex: 0 1 calc(100% / 2);
    padding-bottom: $space-s;
  }

  // for IE11
  *::-ms-backdrop,
  &__region-item:nth-child(2n + 1) {
    flex: 0 1 calc(50% - #{$space-s});
  }

  &__region-item:nth-child(2n + 1) {
    padding-right: $space-s;
  }

  &__region-item:nth-last-child(-n + 2) {
    padding-bottom: 0;
  }

  &__region-item\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 calc(100% / 8);
      margin-right: $space-m;
      padding-bottom: 0;
      position: relative;
    }
  }

  &__region-item\@desktop:nth-child(2n + 1) {
    @include mq($from: desktop) {
      flex: 1 1 calc(100% / 8);
      padding-right: 0;
    }
  }

  &__region-item\@desktop:last-child {
    @include mq($from: desktop) {
      margin-right: 0;
    }
  }

  &__region-item--map-search\@desktop:last-child {
    @include mq($from: desktop) {
      border-left: 1px solid $color-grey-6;
    }
  }

  &__region-body\@desktop {
    @include mq($from: desktop) {
      display: block;
    }
  }

  &__region-heading {
    @include title-l;
    display: flex;
    justify-content: center;
    background: $color-grey-6;
    color: $color-link;
    border-radius: $space-s;
    padding: $space-m;
  }

  &__region-heading\@desktop {
    @include mq($from: desktop) {
      @include title-m;
      justify-content: flex-start;
      padding: $space-l 0 $space-m 0;
      background: transparent;
      color: #222;
      pointer-events: none;
      cursor: default;
    }
  }

  &__home-around\@desktop {
    @include mq($from: desktop) {
      position: absolute;
      top: 50%;
      left: $space-m;
      font-weight: $font-weight-bold;
      font-size: $font-size-s;
      color: $color-link;
      text-align: center;
      background: $color-grey-6;
      border-radius: $space-s;
      line-height: $line-height-s;
      padding: $space-s;
      display: inline-block;
      transition: $btn-transition;
      pointer-events: auto;
      cursor: pointer;

      &:hover {
        background: $color-white;
        @include shadow-top;
      }
    }
  }
}
