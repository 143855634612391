@charset "utf-8";

.c-small-job-offer-card {
  padding: $space-m 0;

  &__heading {
    display: flex;
  }

  &__image-box {
    @include image-box;
    flex-basis: 120px;
    flex-shrink: 0;
    height: 67px;
    margin-right: $space-s;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__img {
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__title-area {
    flex-basis: auto;
    flex-grow: 1;
  }

  &__title-container {
    overflow: hidden;
  }

  &__title {
    @include ellipsis-multiple-line(3, $font-size-m, $line-height-s);
    color: $color-link;
    font-weight: $font-weight-bold;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include ellipsis-multiple-line(4, $font-size-m, $line-height-s);
    }
  }

  &__link {
    display: block;
    -webkit-tap-highlight-color: transparent;
  }

  &__link:hover &__title {
    @include mq($from: desktop) {
      text-decoration: underline;
    }
  }

  &__detail-container {
    overflow: hidden;
  }

  &__detail {
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
  }

  &__txt {
    margin-top: $space-s;
    color: $color-grey-1;
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
    @include par-s;
  }

  &__tag-list {
    overflow: hidden;
    margin-top: $space-xs;
  }

  &__tag-item {
    line-height: $line-height-l;
    margin-right: $space-xs;
    float: left;

    &:last-child {
      margin-right: 0;
    }
  }
}
