@charset "utf-8";

.c-priority-job-offer-card {
  @include shadow-priority-job-offer-card;
  overflow: hidden;
  position: relative;
  border-radius: $space-s;
  background-color: $color-white;
  transition: $btn-transition;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @include mq($from: desktop) {
      @include card-hover;
    }
  }

  &__border-box {
    border-radius: $space-s;
    border: $space-xs solid rgba($color-attention, 0.5);
    padding: $space-m;
  }

  &__badge {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background: $color-attention;
    color: $color-white;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    line-height: 3;//注目求人の文字の縦位置を調整
    height: 108px;
    width: 108px;
    transform: translate(-50%, -50%) rotateZ(-45deg);
  }

  &__heading {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 148px 1fr;
    grid-template-areas:
      "c-priority-job-offer-card__image-box c-priority-job-offer-card__title-grid"
      "c-priority-job-offer-card__detail-grid c-priority-job-offer-card__detail-grid";
  }

  &__image-box {
    @include image-box;
    height: 83px;
    width: 148px;
    grid-area: c-priority-job-offer-card__image-box;
  }

  &__image-box\@desktop {
    @include mq($from: desktop) {
      height: 158px;
      width: 280px;
    }
  }

  &__img {
    width: 100%;
  }

  &__title-grid {
    grid-area: c-priority-job-offer-card__title-grid;
    padding-left: $space-s;
  }

  &__title-container {
    overflow: hidden;
  }

  &__title {
    @include ellipsis-multiple-line(4, $line-height:$line-height-s, $color: $color-link);
  }

  &__detail-grid {
    grid-area: c-priority-job-offer-card__detail-grid;
  }

  &__detail-container {
    overflow: hidden;
    margin-top: $space-m;
  }

  &__detail {
    @include ellipsis-multiple-line(4, $font-size-s, $line-height-s);
    color: $color-grey-1;
  }

  &__tag {
    margin-right: $space-s;
    padding: 2px $space-xs;
  }

  &__table {
    border-top: 1px solid $color-grey-6;
    width: 100%;
    margin-top: $space-m;
  }

  &__th {
    color: $color-grey-0;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    text-align: left;
    vertical-align: top;
    line-height: $line-height-s;
    white-space: nowrap;
    padding: $space-m $space-m 0 0;
  }

  &__td {
    color: $color-grey-1;
    font-size: $font-size-s;
    vertical-align: top;
    line-height: $line-height-s;
    padding: $space-m 0 0;
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-priority-job-offer-card__badge {
        font-size: $font-size-m;
        height: 118px;
        width: 118px;
      }

      .c-priority-job-offer-card__heading {
        grid-template-columns: 280px 1fr;
        grid-template-areas:
          "c-priority-job-offer-card__image-box c-priority-job-offer-card__title-grid"
          "c-priority-job-offer-card__image-box c-priority-job-offer-card__detail-grid";
      }

      .c-priority-job-offer-card__title-grid {
        padding-left: $space-m;
      }

      .c-priority-job-offer-card__title {
        @include ellipsis-multiple-line(2, $font-size-2xl, $line-height-s, $color: $color-link);
      }

      .c-priority-job-offer-card__detail-grid {
        padding-left: $space-m;
      }

      .c-priority-job-offer-card__detail {
        @include ellipsis-multiple-line(4);
      }

      .c-priority-job-offer-card__tbody {
        display: flex;
      }

      .c-priority-job-offer-card__tr {
        display: block;//ie11でflexの横並びを効かせる
        flex: 1 1 0%;
      }
    }
  }
}
