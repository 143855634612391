@charset "utf-8";

.c-news-detail {

  &__heading {
    padding: $space-l $space-m;
  }

  &__heading\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 $space-l 0;
    }
  }

  &__data {
    display: flex;
    color: $color-grey-1;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    justify-content: space-between;
    margin-top: $space-m;
  }

  &__article {
    padding: $space-xl $space-m $space-m;
    position: relative;
  }

  &__article::before {
    border-bottom: $space-m solid $color-grey-6;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__article\@desktop {
    @include mq($from: desktop) {
      padding: $space-m 0 0 0;
    }
  }

  &__article\@desktop::before {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__share-title {
    color: $color-grey-0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    margin-top: $space-l;
  }
}
