@charset "utf-8";

.c-overflow-text {

  &__inner {
    display: block;
    color: $color-grey-2;
    font-size: $font-size-s;
    line-height: $line-height-m;
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-m, $color: $color-grey-2);
  }

  &__inner--3 {
    @include ellipsis-multiple-line(3, $font-size-s, $line-height-m, $color: $color-grey-2);
  }

  &__inner--5 {
    @include ellipsis-multiple-line(5, $font-size-s, $line-height-m, $color: $color-grey-2);
  }

  &__inner--invalidate\@desktop {
    @include mq($from: desktop) {
      height: auto;
      max-height: 100%;
    }
  }

  &__inner--2m {
    @include ellipsis-multiple-line(2, $font-size-m, $line-height-m, $color: $color-grey-2);
  }

  &__show-more {
    display: block;
    text-align: right;
    color: $color-link;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    background-color: $color-white;
    line-height: $line-height-m;
  }

  &__show-more--opened::before {
    display: none;
  }

  &__inner + &__show-more {
    display: block;
  }

  &__inner--invalidate\@desktop + &__show-more {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__inner--visible {
    height: auto;
    max-height: 100%;
  }

  &__inner--visible::before,
  &__inner--visible::after {
    display: none;
  }

  &__inner--invalidate\@desktop::before,
  &__inner--invalidate\@desktop::after {
    @include mq($from: desktop) {
      display: none;
    }
  }
}
