@charset "utf-8";

.c-featured-facility-card {
  display: flex;
  flex-direction: column;
  @include shadow-card;
  border-radius: $space-s;
  align-items: stretch;
  transition: $btn-transition;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @include mq($from: desktop) {
      @include card-hover;
    }
  }

  &__image-box {
    @include image-box;
    border-radius: $space-s $space-s 0 0;
    height: 192px;
    max-width: 100%;
  }

  &__image-box\@desktop {
    @include mq($from: desktop) {
      height: 166px;
    }
  }

  &__image {
    width: 100%;
  }

  &__txt-area {
    position: relative;
    padding: $space-m $space-m 0;
  }

  &__title-container {
    overflow: hidden;
    margin-bottom: $space-m;
  }

  &__title {
    @include ellipsis-multiple-line(3, $font-size-m, $line-height-s, $color: $color-link);
  }

  &__table-th {
    color: $color-grey-0;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    text-align: left;
    vertical-align: top;
    line-height: $line-height-s;
    padding: 0 $space-m $space-m 0;
    width: 42px;
  }

  &__table-td {
    vertical-align: top;
  }

  &__td-container {
    overflow: hidden;
    margin-bottom: $space-m;
  }

  &__td-txt {
    color: $color-grey-1;
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-s);
  }

  &__td-txt--full {
    max-height: initial;
  }

  &__td-txt--full::before,
  &__td-txt--full::after {
    content: none;
  }

  &__tag-list {
    overflow: hidden;
    border-top: 1px solid $color-grey-6;
    padding: $space-xs $space-m $space-s;
  }

  &__tag-item {
    margin: $space-xs $space-xs 0 0;
    float: left;
  }

  &__tag-new {
    position: absolute;
    top: -$space-s;
    left: $space-m;
  }

  &__btn-list {
    display: flex;
    overflow: hidden;
    border-top: 1px solid $color-grey-6;
    padding: $space-s;
    margin-top: auto;
  }

  &__btn-item {
    flex: 1 1 0;
  }

  &__contents {
    flex-grow: 1;
    flex-shrink: 0;
  }
}
