@charset "utf-8";

.c-job-mail-detail {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-direction: row;
    }
  }

  &__left {
    display: flex;
    flex: 1 1 calc(100% / 2);
  }

  &__right {
    flex: 1 1 calc(100% / 2);
    margin-top: $space-s;
  }

  &__right\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
      margin-left: $space-s;
    }
  }

  &__title {
    @include title-s;
    color: $color-grey-2;
    flex: 0 0 64px;
  }

  &__tags {
    overflow: hidden;
  }

  &__tag {
    display: inline-block;
    text-align: center;
    color: $color-grey-2;
    padding: 2px $space-xs;
    font-size: $font-size-xs;
    line-height: $line-height-s;
  }

  &__tag-list {
    float: left;
    line-height: 0;
    border-bottom: $space-s;
  }

  &__tag-list\@desktop {
    @include mq($from: desktop) {
      margin-bottom: $space-xs;
    }
  }

  &__btn-group {
    margin-top: $space-s;
    display: flex;
    justify-content: center;
  }

  &__btn-group\@desktop {
    @include mq($from: desktop) {
      justify-content: flex-end;
    }
  }

  &__link {
    @include title-s;
    margin: 0 $space-l;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      margin: 0;
    }
  }

  &__link\@desktop:last-child {
    @include mq($from: desktop) {
      margin: 0 0 0 $space-s;
    }
  }
}
