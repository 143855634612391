@charset "utf-8";

.c-checkbox {
  display: inline-block;
  cursor: pointer;
  color: $color-placeholder;
  font-size: $font-size-l;
  text-align: left;
  background: $color-grey-6;
  border-radius: $space-s;
  line-height: $line-height-l;
  padding: $space-s $space-m;
  transition: $btn-transition;

  &__container {
    display: flex;
    align-items: center;
  }

  &__input {
    display: none;
  }

  &__mimic {
    padding: 0 $space-s 0 0;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    transition: $btn-transition;
    cursor: pointer;
  }

  &__mimic-inner {
    position: relative;
    display: block;
    width: $space-m;
    height: $space-m;
    border-radius: $space-xs;
    background: $color-grey-5;
  }

  &__item-name {
    line-height: $line-height-s;
  }

  &__item-required {
    color: $color-important;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
  }

  &:hover {
    @include mq($from: desktop) {
      background: $color-white;
      @include shadow-top;
    }
  }

  &:hover &__item-name {
    @include mq($from: desktop) {
      color: $color-grey-0;
      font-weight: $font-weight-bold;
    }
  }

  &__input:checked {
    background: $color-primary;
  }

  &__input:checked ~ &__item-name {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__input:checked + &__mimic > &__mimic-inner {
    background: $color-primary;
    border: 1px solid $color-primary;
    position: relative;
    width: $space-m;
    height: $space-m;
    display: block;
  }

  &__input:checked + &__mimic > &__mimic-inner::before {
    position: absolute;
    left: 1px;
    top: 9px;
    display: block;
    content: '';
    width: 5px;
    height: 2px;
    background: $color-white;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  &__input:checked + &__mimic > &__mimic-inner::after {
    display: block;
    position: absolute;
    left: 5px;
    top: 9px;
    content: "";
    width: 8px;
    height: 2px;
    background: #FFFFFF;
    transform: rotate(-45deg);
    transform-origin: left center;
  }

  &--no-background {
    background: none;
    padding: 0;
    height: auto;
  }

  &--no-background:hover {
    @include mq($from: desktop) {
      background: none;
      box-shadow: none;
    }
  }

  &--full {
    width: 100%;
  }

  &--large {
    padding: 16px
  }
}
