@charset "utf-8";

.c-additional-complete {
  padding: $space-3xl $space-l $space-l;

  &__text--attention {
    @include title-2xl;
    color: $color-attention;
  }

  &__icon {
    @include icon-xl;
    display: block;
  }

  &__icon--attention {
    fill: $color-attention;
  }
}
