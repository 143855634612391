@charset "utf-8";

.c-paragraph {
  color: $color-grey-1;
  font-weight: $font-weight-normal;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-normal;
  font-size: $font-size-m;

  //- 16px
  &--l {
    font-size: $font-size-l;
    line-height: $line-height-s;
    letter-spacing: $letter-spacing-wide;
  }

  //- 14px line-height2.0
  &--ml {
    line-height: $line-height-l;
  }

  //- 12px
  &--s {
    font-size: $font-size-s;
    line-height: $line-height-s;
  }

  //- 10px
  &--xs {
    font-size: $font-size-xs;
    line-height: $line-height-s;
  }

  &--grey-0 {
    color: $color-grey-0;
  }

  &--grey-2 {
    color: $color-grey-2;
  }

  &--blown-0 {
    color: $color-brown-0
  }

  &--white {
    color: $color-white;
  }

  &--color-link {
    color: $color-link;
  }

  &__important {
    color: $color-important;
    font-weight: $font-weight-bold;
  }

  &__important--green {
    color: $color-link;
    font-weight: $font-weight-bold;
  }

  &__emphasis {
    font-weight: $font-weight-bold;
  }

  &--lh-l {
    line-height: $line-height-l;
  }

  &--ls-x-wide {
    letter-spacing: $letter-spacing-x-wide;
  }
}
