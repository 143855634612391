@charset "utf-8";

.s-job-offer-appeal-content {
  color: $color-grey-1;
  font-weight: $font-weight-normal;
  line-height: $line-height-l;
  letter-spacing: $letter-spacing-normal;
  font-size: $font-size-m;

  h3 {
    margin-top: $space-m;
    padding-bottom: $space-s;
    color: $color-grey-0;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    letter-spacing: $letter-spacing-wide;
    font-size: $font-size-l;
  }

  h3:first-child {
    margin-top: 0;
  }

  h3 + p {
    margin-top: $space-s;
  }

  ul {
    padding-left: $space-m;
  }

  li {
    list-style: disc;
  }

  li + li {
    margin-top: $space-s;
  }
}
