@charset "utf-8";

.c-select-area {
  display: flex;
  align-items: center;
  padding: 20px 0 10px 0;
  justify-content: space-between;

  &__button {
    background-color: $color-grey-6;
    border-radius: 8px;
    border: 1px solid $color-grey-5;
    color: $color-link;
    font-size: $font-size-m;
    font-weight: 600;
    padding: $space-s $space-l $space-s 10px;
    text-align: center;
    display: block;
    cursor: pointer;
    width: 48%;
    transition: $btn-transition;

    &:hover {
      background: $color-white;
      color: $color-grey-0;
      @include shadow-top;
    }
  }

  &-city-search {
    width: 250px;
    height: 48px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 15px;
    text-align: center;
  }
}
