@charset "utf-8";

.c-photo-upload {
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      align-items: stretch;
      width: 232px;
    }
  }

  &__image-box {
    position: relative;
    height: 208px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $space-s;
    @include image-box;
  }

  &__image {
    max-width: 120px;
    max-height: 160px;
  }

  &__txt {
    @include par-s;
    color: $color-grey-2;
    font-weight: $font-weight-bold;
    display: none;
  }

  &__button {
    margin-top: $space-xs;
    cursor: pointer;
  }

  &__input {
    display: none;
  }

  &__image-box--empty {

    .c-photo-upload__txt {
      display: block;
    }
  }
}
