@charset "utf-8";

.c-offers-gallery {
  display: flex;
  flex-wrap: wrap;

  &__item {
    // 横100%から3列の間のmargin-left * 2を引いて1カラムの幅を指定
    width: calc((100% - #{$space-s} * 1) / 2);
    height: 92px;
    margin-left: $space-s;
    @include image-box;

    img {
      width: 100%;
      height: 92px;
      object-fit: contain;
    }
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      width: 192px;
      height: 108px;
      margin-left: $space-m;
      transition: $btn-transition;
    }

    img {
      height: 108px;
    }
  }

  &__item\@desktop:hover {
    @include mq($from: desktop) {
      @include shadow-top;
    }
  }

  &__item:nth-of-type(2n+1) {
    margin-left: 0;
  }

  &__item\@desktop:nth-of-type(2n+1) {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }

  &__item\@desktop:nth-of-type(3n+1) {
    @include mq($from: desktop) {
      margin-left: 0;
    }
  }

  &__item:nth-child(n+3) {
    margin-top: $space-s;
  }

  &__item\@desktop:nth-child(n+3) {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__item\@desktop:nth-child(n+4) {
    @include mq($from: desktop) {
      margin-top: $space-m;
    }
  }

  &__image {
    width: 100%;
  }
}
