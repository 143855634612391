@charset "utf-8";

.c-modal-search-pref {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      margin-top: $space-m;
    }
  }

  &__district {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    padding: $space-m;
  }

  &__district\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 $space-m;
    }
  }

  &__col {
    margin-bottom: $space-m;

    &:first-child > .c-modal--search-pref__district {
      padding: 0 $space-m $space-m;
    }

    &:first-child > .c-modal--search-pref__district\@desktop {
      @include mq($from: desktop) {
        padding: 0 0 $space-m;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__col\@desktop {
    @include mq($from: desktop) {
      margin-bottom: 0;
      margin-left: $space-m;
      flex: 1 1 calc(100% / 7);
    }

    &:first-child {
      @include mq($from: desktop) {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }
}
