@charset "utf-8";

.c-breadcrumb {
  background-color: rgba($color-link, $alpha-low-common);

  &\@desktop {
    @include mq($from: desktop) {
      min-width: 960px;
    }
  }

  &__inner {
    display: flex;
    padding: $space-s;
    overflow-x: auto;
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      width: $width-desktop-column;
      padding: $space-s 0;
      margin: 0 auto;
      overflow-x: auto;
      flex-wrap: nowrap;
    }
  }

  &__inner\@mobile {
    @include mq($until: desktop) {
      border-bottom: 1px solid rgba($color-link, 0);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin: 0 $space-s 0 0;
  }

  &__link {
    color: $color-white;
    font-size: $font-size-xs;
    line-height: $line-height-s;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }

  &__link--current {
    font-weight: $font-weight-normal;
  }

  &__link + &__arrow {
    margin-left: $space-s;
    width: $space-s;
    height: $space-s;
    line-height: 0;
    fill: $color-white;
  }

  &__home {
    display: block;
    width: 10px;
    height: 10px;
  }
}
