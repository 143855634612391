@charset "utf-8";

.c-job-area-search-list {

  &__item {
    margin-top: $space-m;

    &:first-child {
      margin-top: 0;
    }
  }

  &__txt {
    font-weight: $font-weight-bold;
    text-align: center;
    display: block;
    font-size: $font-size-s;
  }

  &__txt\@desktop {
    @include mq($from: desktop) {
      text-align: left;
    }
  }

  &__lower-list {
    display: flex;
    flex-wrap: wrap;
  }

  &__lower-list\@mobile {
    @include mq($until: desktop) {
      justify-content: center;
    }
  }

  &__link {
    display: block;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    color: $color-link;
    line-height: $line-height-s;
    transition: $btn-transition;
    width: 100%;
    margin: $space-s $space-s 0 0;

    &:hover {
      @include mq($from: desktop) {
        text-decoration: underline;
      }
    }
  }

  &__link\@mobile {
    @include mq($until: desktop) {
      background-color: $color-grey-6;
      width: 110px;
      font-size: $font-size-l;
      line-height: 1;
      text-align: center;
      border-radius: $space-s;
      padding: $space-m 0;
      margin: $space-s $space-xs 0;
    }
  }
}
