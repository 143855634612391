@charset "utf-8";

.c-incentive-application {
  width: 100%;
  background: rgba($color-important, $alpha-low);
  padding: $space-l;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: $space-s;

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      align-items: baseline;
    }
  }

  &__text {
    font-weight: $font-weight-bold;
    font-size: $font-size-2xl;
    text-align: center;
  }

  &__button {
    margin: $space-m 0 0;
    text-align: center;

    &--no-margin {
      margin: 0;
    }
  }

  &__button\@desktop {
    @include mq($from: desktop) {
      margin: 0 0 0 $space-m;
    }
  }
}
