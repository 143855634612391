@charset "utf-8";

.c-mypage-btn-group {

  &__row {
    border-top: 1px solid $color-grey-6;
    text-align: center;
    padding-top: $space-l;

    &__text {

      > div {
        margin-bottom: 15px;
      }
    }
  }

  &__row + &__row {
    margin-top: $space-m;
  }

  &__addition {
    padding-top: $space-m;
  }

  &__btn {
    width: 100%;
  }

  &__btn + &__btn {
    margin-top: $space-s;
  }

  &__btnwrap\@desktop {
    @include mq($from: desktop) {
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;

      .c-mypage-btn-group__btn {
        width: auto;
      }

      .c-mypage-btn-group__btn + .c-mypage-btn-group__btn {
        margin-top: 0;
        margin-right: $space-s;
      }
    }
  }

  &__wrapper {
    margin: $space-l $space-m 0 $space-m;
  }

  &__wrapper\@desktop {
    @include mq($from: desktop) {
      margin: $space-l 0 0 0;
    }
  }

  &__btn--justify {
    width: 100%;
    justify-content: center;
  }

  &__btn--justify\@desktop {
    @include mq($from: desktop) {
      width: auto;
      justify-content: normal;
    }
  }

  &__split {
    margin-bottom: 15px;

    > a {
      flex-grow: 1;
    }

    > input {
      flex-grow: 10;
      margin-left: $space-s;
    }
  }

  &__list {
    padding-left: $space-l;
    list-style-type: disc;
    list-style-position: outside;
    text-align: left;
  }
}
