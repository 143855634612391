@charset "utf-8";

.c-sns-btn-group {
  display: flex;
  justify-content: space-between;

  &__item {
    width: 100%;
    margin-right: $space-s;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &\@desktop {
    @include mq($from: desktop) {
      justify-content: flex-start;

      .c-sns-btn-group__item {
        width: auto;
      }
    }
  }
}
