@charset "utf-8";

.c-area-tab {

  &\@desktop {
    @include mq($from: desktop) {
      display: grid;
      grid-template-rows: auto auto auto;
      grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
      grid-template-areas: "c-area-tab__pref c-area-tab__employment c-area-tab__feature" "c-area-tab__content c-area-tab__content c-area-tab__content";
      background: $color-white;
      border-radius: $space-s $space-s 0 0;
    }
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      width: 100%;
    }
  }

  &__pref\@desktop {
    @include mq($from: desktop) {
      grid-area: c-area-tab__pref;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        bottom: -#{$space-m};
        @include gradient-bottom;
      }
    }
  }

  &__employment\@mobile {
    @include mq($until: desktop) {
      position: relative;
      margin-top: $space-m;

      &::before {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        top: -#{$space-m};
        background: $color-grey-7;
      }
    }
  }

  &__employment\@desktop {
    @include mq($from: desktop) {
      grid-area: c-area-tab__employment;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        bottom: -#{$space-m};
        @include gradient-bottom;
      }
    }
  }

  &__feature\@desktop {
    @include mq($from: desktop) {
      grid-area: c-area-tab__feature;
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        bottom: -#{$space-m};
        @include gradient-bottom;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    padding: $space-m;
    transition: $btn-transition;
    color: $color-link;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid $color-grey-6;

    &:hover {
      @include mq($from: desktop) {
        color: $color-grey-0;
      }
    }
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      justify-content: center;
      border-bottom: 0 solid $color-primary;
      font-size: $font-size-l;
      line-height: 1;
      transition: $border-transition;

      &:hover {
        border-bottom: $space-xs solid $color-primary;
      }
    }
  }

  &__not-link\@mobile {
    @include mq($until: desktop) {
      cursor: default;
      pointer-events: none;
      color: $color-grey-0;
    }
  }

  &__icon {
    @include icon-l;
    display: inline-block;
    line-height: $space-l;
    margin-right: $space-s;
    flex: 0 0 $space-l;
  }

  &__text\@mobile {
    @include mq($until: desktop) {
      margin-right: $space-s;
    }
  }

  &__arrow {
    @include icon-m;
    display: inline-block;
    line-height: $space-m;
    margin-left: auto;
    flex: 0 0 auto;
    fill: $color-grey-3;
  }

  &__content\@desktop {
    @include mq($from: desktop) {
      grid-area: c-area-tab__content;
      width: 100%;
      display: none;
      padding: $space-l $space-m $space-l $space-m;
    }
  }

  &__content--no-padding\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }

  &__item--active + &__content\@desktop {
    @include mq($from: desktop) {
      display: block;
    }
  }

  &__item--active > &__link\@desktop {
    @include mq($from: desktop) {
      border-bottom: $space-xs solid $color-primary;
      color: $color-grey-0;
    }
  }

  &__tag-list {
    margin-left: auto;
    margin-right: $space-m;
    font-weight: $font-weight-normal;
    flex: 0 0 auto;
  }

  &__tag-list + &__arrow {
    margin-left: 0;
  }

  &__tag-list-item {
    margin-left: $space-xs;
  }
}
