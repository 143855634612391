@charset "utf-8";

$space-tokens: (
  xs: $space-xs,
  s: $space-s,
  l: $space-l,
  xl: $space-xl,
  '2xl': $space-2xl,
  '3xl': $space-3xl,
);

.o-orientation-gutter {
  display: flex;
  flex-direction: column;

  &__item + &__item {
    margin-left: 0;
    margin-top: $space-m;
  }

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: 0;
      margin-left: $space-m;
    }
  }

  @each $key, $space in $space-tokens {

    &--#{$key} > &__item + &__item {
      margin-top: $space;
    }

    &--#{$key}\@desktop > &__item + &__item {
      @include mq($from: desktop) {
        margin-top: 0;
        margin-left: $space;
      }
    }
  }
}
