@charset "utf-8";

.c-footer-notification {

  &__box {
    background-color: #39BFB9;
    position: absolute;
    width: 100%;
    height: 26px;
  }

  &__message {
    padding: 3px;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
  }
}
