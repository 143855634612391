@charset "utf-8";

.c-mypage-modal-ellipsis {
  overflow: hidden;

  &__text {
    @include ellipsis-multiple-line(1, $line-height:$line-height-s);
    color: $color-grey-1;
  }
}
