@charset "utf-8";

.register-page .c-form-table {

  &__checkbox {
    margin: 0 8px 8px 0;
    @include mq($from: desktop) {
      margin: 4px 8px 4px 0;
    }

    &--stack\@mobile {
      float: left;
      width: auto;
    }

    &-agree {
      height: 100%;
      margin: 0;
      display: flex;
      @include mq($from: desktop) {
        height: 20px;
      }
    }
  }

  &__txt {
    padding: 4px 0 8px;
  }

  &__item {
    display: block;
  }

  &__title {
    font-size: $font-size-l;
    width: 100%;
    padding: 0 0 8px 0;
    @include mq($from: desktop) {
      font-size: $font-size-m;
      margin: 0 0 6px;
      padding: 0;
    }

    &-wrapper {
      display: block;
      align-self: center;
    }

    &-peek {
      @include mq($from: desktop) {
        align-self: flex-start !important;
        margin-top: 0;
      }
    }

    &-note {
      font-size: $font-size-s;
      font-weight: $font-weight-normal;
      color: $color-grey-2;
      padding-bottom: $space-xs;
      @include mq($from: desktop) {
        font-size: $font-size-xs;
      }
    }
  }

  &__input {
    display: block;
    @include mq($from: desktop) {
      width: 100%;
      display: flex;
    }

    &-name {
      display: flex;
    }
  }

  &__input-wrapper {
    width: 100%;
    padding: 0;
  }

  &__input--half\@desktop {
    width: 100%;
  }

  &__supple {
    font-size: $font-size-s;
    font-weight: $font-weight-normal;
    margin: -8px 0 8px 0;
    padding: 2px 0 0 0;
    @include mq($from: desktop) {
      font-size: $font-size-xs;
      margin: -4px 0 8px;
      padding: 0;
    }
  }

  &__necessary\@mobile {
    display: inline;
  }
}

.register-page {

  .dsp-flex {
    display: flex;
  }

  .o-container__column\@desktop {
    @include mq($from: desktop) {
      width: 656px;
    }
  }

  .o-gutter-column__item {
    width: 100%;
  }

  .o-gutter-column--xs > .o-gutter-column__item + .o-gutter-column__item {
    margin: 0;
    @include mq($from: desktop) {
      margin-left: 8px;
    }
  }

  .c-radio {
    margin: 0;

    &__input {
      margin: 0 8px 8px 0;

      &:last-child {
        margin: 0 8px 0 0;
      }
      @include mq($from: desktop) {
        margin: 0 8px 0 0;
      }
    }
  }

  .c-form {

    &-title {
      display: block;

      &__btn-area\@desktop {
        justify-content: space-between;
      }
    }

    &-fieldset__checkbox {
      margin: -4px 0 -4px 0;
    }

    &-fieldset-agree {
      display: block;
      margin: 8px 0;
    }

    &-submit__button-area {
      margin: 8px 0 16px;
    }
  }

  .c-checkbox {

    &__container-agree {
      align-items: flex-start;
    }

    &__item-required {
      font-size: 14px;
    }
  }

  .c-arrowed-accordion__content-inner {
    padding: 8px 0;
  }

  .c-bordered-list {
    margin: 20px 0 16px;
    @include mq($from: desktop) {
      margin: 28px 0 20px;
    }
  }

  .c-txt-button-group {
    display: block;
    @include mq($from: desktop) {
      display: flex;
    }
  }

  .c-txt-button-group__button {
    margin: 12px 0 0 0;
    @include mq($from: desktop) {
      margin: 0 0 0 8px;
    }
  }

  .c-button {

    &--small {
      padding: 10px;
      @include mq($from: desktop) {
        padding: 8px;
      }
    }

    &--full-mobile {
      width: 100%;
      @include mq($from: desktop) {
        width: auto;
      }
    }
  }

  .c-register-title__btn .c-button {
    padding: 12px;
  }

  .c-selectbox-wrapper {
    display: block;
    @include mq($from: desktop) {
      display: flex;
    }
  }

  .nowrap-text {
    white-space: nowrap;
  }

  .c-text-link {
    background: none;
  }

  .c-text-link--disabled,
  .c-text-link:disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  .js-preferred-row-date__target {
    position: relative;
    padding: 12px 0 0 0;
  }

  .js-preferred-date-remove-row {
    position: absolute;
    right: 0;
    bottom: -102px;
    @include mq($from: desktop) {
      bottom: -48px;
    }
  }

  .js-preferred-time-add-row {
    position: absolute;
    bottom: -12px;
    @include mq($from: desktop) {
      bottom: 4px;
    }
  }

  .js-preferred-time-remove-row {
    display: block;
    text-align: right;
    margin: 8px 0 16px;
  }

  .js-preferred-row-time-dist {
    background: $color-white;
  }

  .js-form-manager__error-msg {
    margin: 4px 0 8px;
  }
}

.timeOption-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 0 0;
}

.add_date-wrapper {
  display: flex;
  align-items: center;
  margin: 12px 0 0 0;
  padding: 12px 0 0 0;
  border-top: 1px solid $color-grey-6;
}
