@charset "utf-8";

.c-job-count-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__count {
    float: left;
  }

  &__options {
    float: right
  }

  &__contents {
    padding: 0 $space-m;
  }

  &__contents\@desktop {
    @include mq($from: desktop) {
      background-color: inherit;
      padding: 0 0 $space-m 0;
    }
  }
}

.c-offers-sort-area {

  &__title {
    position: relative;
    background-color: $color-white;

    &::after {
      content: "";
      width: 100%;
      height: $space-m;
      position: absolute;
      left: 0;
      bottom: -$space-m;
      @include gradient-bottom;
    }
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: inherit;

      &::after {
        display: none;
      }
    }
  }

  &__title--no-padding\@mobile {
    @include mq($until: desktop) {
      padding-top: 0;
    }
  }

  &__title--no-padding\@desktop {
    @include mq($from: desktop) {
      display: block;
      padding-top: 0;

      &::after {
        display: none;
      }
    }
  }

  &__title-text {
    padding: $space-l $space-m 0;
    @include title-xl;
    text-align: center;
  }

  &__title-text\@desktop {
    @include mq($from: desktop) {
      padding: 0;
      text-align: left;
    }
  }

  &__contents {
    background-color: inherit;
    padding: $space-l $space-m;
  }

  &__contents\@desktop {
    @include mq($from: desktop) {
      background-color: inherit;
      padding: 0;
    }
  }

  &__title\@desktop + &__contents\@desktop {
    @include mq($from: desktop) {
      padding-top: $space-l;
    }
  }

  &__contents--no-padding-top\@desktop {
    @include mq($from: desktop) {
      padding: 0 0 $space-l;
    }
  }

  &__contents--no-padding-bottom\@desktop {
    @include mq($from: desktop) {
      padding-bottom: 0;
    }
  }

  &__button {
    background-color: $color-grey-7;
    margin: $space-l -#{$space-m};
    padding: $space-m;
  }

  &__button\@desktop {
    @include mq($from: desktop) {
      padding: 0;
      margin: $space-l 0;
    }
  }

  &__contents + &__pagination {
    margin-top: $space-m;
  }

  &__contents\@desktop + &__pagination\@desktop {
    @include mq($from: desktop) {
      margin-top: $space-l;
    }
  }
}
