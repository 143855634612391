@charset "utf-8";

.c-search-accordion {

  &:first-child {
    border-top: 1px solid $color-grey-6;
  }

  &__title {
    @include title-l;
    padding: $space-m;
    color: $color-link;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-6;
  }

  &__icon {
    @include icon-m;
    display: block;
    fill: $color-grey-3;
    transition: $btn-transition;
    flex: 0 0 auto;
  }

  &__content {
    overflow: hidden;
    transition: $btn-transition;
    height: 0;
  }

  &__title--open {
    border-bottom: 0;
  }

  &__title--open > &__icon {
    transform: rotate($arrow-rotate-reverse);
  }

  &__title--open + &__content {
    display: block;
  }
}
