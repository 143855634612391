@charset "utf-8";

.c-resume-btn-group {

  &__row {
    border-top: 1px solid $color-grey-6;
    text-align: center;
    padding-top: $space-l;

    &__text {

      > div {
        margin-bottom: 15px;
      }
    }
  }

  &__btn {
    width: 100%;
  }

  &__btnwrap\@desktop {
    @include mq($from: desktop) {
      display: flex;
      justify-content: center;

      .c-resume-btn-group__btn {
        width: auto;
      }
    }
  }
}
