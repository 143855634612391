@charset "utf-8";

.c-map {
  width: 100%;
  position: relative;
  overflow: hidden;

  &__item {
    width: 100%;
    display: block;
    height: 289px;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      height: 580px;
    }
  }

  &__item--small\@desktop {
    @include mq($from: desktop) {
      height: 350px;
    }
  }

  &__item--large\@mobile {
    @include mq($until: desktop) {
      // ios-safariでファーストビュー内にGoogleMap＋タイトルを表示する高さ
      height: calc(100vh - 251px);
      //小さい端末でも求人詳細内のGoogleMapの高さよりも大きくし、操作性を担保するための高さ
      min-height: 400px;
    }
  }

  &__offer {
    position: absolute;
    //GoogleMaps上のロゴを隠さない位置に設定
    bottom: 25px;
    left: 0;
    width: 100%;
    z-index: index($zindex, c-map__offer);
    transition: $btn-transition;
    transform: translateY(0);
  }

  &__offer--hide {
    transform: translateY(160px);
  }

  &__offer-button {
    z-index: index($zindex, c-map__offer-button);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateY(-50%);
    cursor: pointer;
    height: $space-xl;
    width: $space-xl;

    // swiperが自動付与するclass名
    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  &__offer-button--next {
    top: 50%;
    right: 0;
    border-top-left-radius: $space-s;
    border-bottom-left-radius: $space-s;
    background: rgba($color-grey-0, $alpha-common);
  }

  &__offer-button--prev {
    top: 50%;
    left: 0;
    border-top-right-radius: $space-s;
    border-bottom-right-radius: $space-s;
    background: rgba($color-grey-0, $alpha-common);
  }

  &__offer-container {
    padding: $space-s 0;
  }

  &__offer-wrapper {
    display: flex;
  }

  &__change-conditions-button {
    @include shadow-map-slider;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: index($zindex, c-map__offer);
    font-size: $font-size-m;
    padding: $space-xs $space-s;
  }

  &__change-conditions-button\@desktop {
    @include mq($from: desktop) {
      padding: $space-s $space-m;
    }
  }

  &__move-to-job-offer-list-button {
    @include shadow-map-slider;
    position: absolute;
    top: 10px;
    right: 175px;
    z-index: index($zindex, c-map__offer);
    font-size: $font-size-m;
    padding: $space-xs $space-s;
  }

  &__move-to-job-offer-list-button\@desktop {
    @include mq($from: desktop) {
      padding: $space-s $space-m;
      right: 195px;
    }
  }

  &__title {
    background-color: $color-white;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      background-color: inherit;
    }
  }

  &__title-text {
    padding: $space-l $space-m 0;
    @include title-xl;
    text-align: center;
  }

  &__title-text\@desktop {
    @include mq($from: desktop) {
      padding: 0;
      text-align: left;
    }
  }

  &__contents {
    background-color: $color-white;
    padding: $space-l $space-m;
  }

  &__contents\@mobile {
    @include mq($until: desktop) {
      background-color: $color-white;
      padding: 0;
    }
  }

  &__contents\@desktop {
    @include mq($from: desktop) {
      background-color: inherit;
      padding: 0;
    }
  }

  &__no-contents {
    background-color: $color-white;
    padding: $space-3xl $space-xl;
  }

  &__no-contents\@desktop {
    @include mq($from: desktop) {
      padding: $space-l;
    }
  }

  &__title\@desktop + &__contents\@desktop {
    @include mq($from: desktop) {
      padding-top: $space-l;
    }
  }

  &--shadow\@desktop {
    @include mq($from: desktop) {
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04), 0 8px 32px 0 rgba(0, 0, 0, 0.16);
    }
  }
}
