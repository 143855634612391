@charset "utf-8";

.c-banner {
  transition: $btn-transition;
  width: 100%;
  display: block;
  overflow: hidden;
  border-radius: $space-s;

  &:hover {
    @include mq($from: desktop) {
      opacity: $alpha-high-common;
    }
  }

  &--shadow {
    @include shadow-top;
  }

  &__image {
    width: 100%;
    height: auto;
  }

  // google ad manager
  &--gam {
    width: 320px;
    margin: 0 auto;
  }

  &--gam\@desktop {
    @include mq($from: desktop) {
      width: 280px;
    }
  }

  &__image--scout\@desktop {
    @include mq($from: desktop) {
      max-width: 728px;
    }
  }
}
