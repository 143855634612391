@charset "utf-8";

.c-agreement {
  color: $color-grey-1;
  font-weight: $font-weight-normal;
  line-height: $line-height-m;
  letter-spacing: $letter-spacing-normal;
  font-size: $font-size-m;

  &__title {
    font-weight: $font-weight-bold;
    color: $color-grey-0;
    font-size: $font-size-m;
  }

  &__item {
    margin-top: 0;

    &--s {
      margin-top: $space-s;
    }

    &--m {
      margin-top: $space-m;
    }

    &--l {
      margin-top: $space-l;
    }

    &--xl {
      margin-top: $space-xl;
    }
  }

  &__list {
    counter-reset: num;
    padding-left: $font-size-m;
  }

  &__list--dot {
    list-style: disc;
    padding-left: $font-size-m;
  }

  &__order {
    text-indent: -$font-size-m;
    padding-left: $font-size-m;
  }

  &__order--dot {
    padding-left: $font-size-m;
  }

  &__order::before {
    counter-increment: num;
    content: counter(num)'.';
    display: inline-block;
    width: $font-size-m;
  }

  &__order--parentheses {
    text-indent: -$font-size-m;
    padding-left: $font-size-m;
  }

  &__order--parentheses::before {
    counter-increment: num;
    content: '('counter(num)')';
    display: inline-block;
    width: $font-size-m;
  }

  &__caption {
    color: $color-grey-2;
    font-size: $font-size-s;
  }
}
