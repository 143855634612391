@charset "utf-8";

.c-message-detail {
  display: flex;
  flex-direction: column;

  &__item {
    width: 272px;
  }

  &__item + &__item {
    margin-top: $space-m;
  }

  &__item-box {
    overflow: hidden;
    border-radius: $space-m $space-m $space-m 0;
  }

  &__item__inner-box {
    overflow: hidden;
    border-radius: $space-m $space-m 0 0;
  }

  &__heading {
    background: $color-attention;
    color: $color-white;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: center;
    padding: $space-s;

    &--attention {
      background: $color-important;
    }

    &--inactive {
      background: $color-grey-3;
    }
  }

  &__txt {
    background: $color-grey-6;
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-l;
    padding: $space-m;
  }

  &__licence-list {
    margin-top: $space-s;
    margin-bottom: $space-s;
  }

  &__preferred {
    background: $color-primary;
    color: $color-white;
    font-size: $font-size-m;
    line-height: $line-height-l;
    padding: 0 $space-m $space-m;
  }

  &__preferred--inner {
    background: $color-white;
    font-size: $font-size-m;
    line-height: $line-height-l;
    padding: $space-m;
    border-radius: 8px;
    position: relative;
  }

  &__preferred__message--disabled {
    background-color: $color-white;
    height: 100%;
    opacity: 0.75;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__preferred__reply {
    background: $color-grey-6;
    font-size: $font-size-m;
    padding: 0 $space-m $space-m;
  }

  &__info {
    color: $color-grey-2;
    font-size: $font-size-s;
    margin-top: $space-xs;
  }

  &__info-member {
    font-weight: $font-weight-bold;
    margin-left: $space-xs;
  }

  &__item--member {
    align-self: flex-end;
  }

  &__item--member &__heading {
    background: $color-message-heading-member;
  }

  &__item--member &__item-box {
    border-radius: $space-m $space-m 0 $space-m;
  }

  &__item--member &__txt {
    background: $color-primary;
    color: $color-white;
  }

  &__item--member &__info {
    text-align: right;
  }

  &__attachment-list {
    background: $color-message-heading-member;
    padding: 0 $space-m;

    &--customer {
      background-color: $color-important;
    }
  }

  &__attachment-item {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-top: 1px solid $color-primary;
    color: $color-white;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    padding: $space-s 0;

    &--customer {
      border-top-color: #FFEEF1;
    }
  }

  &__attachment-name {
    line-height: $line-height-l;

    &--customer path {
      fill: $color-important;
    }
  }

  &__attachment-preview {
    line-height: $line-height-l;
  }

  &__attachment-link {
    color: $color-white;
  }

  &__attachment-icon {
    display: block;
    @include icon-l();
    margin-right: $space-s;
    float: left;
  }

  &__item--success &__heading {
    background: $color-important;
  }

  &__item--fail &__heading {
    background: $color-grey-3;
  }

  &__annotation {
    color: $color-grey-2;
    font-size: $font-size-s;
    line-height: $line-height-l;
    margin: auto;
    padding: $space-xl 0;
    text-align: center;
  }

  &__disabled-label {
    background: $color-grey-3;
    color: $color-white;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    padding: $space-s;
    text-align: center;
    border-radius: $space-s $space-s 0 0;
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-message-detail__item {
        width: 424px;
      }
    }
  }
}
