@charset "utf-8";

.c-search-check-list {
  border-top: solid 1px $color-grey-6;

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: -$space-s;
      border: none;
    }
  }

  &__item {
    border-bottom: solid 1px $color-grey-6;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      width: calc(100% / 4);
      border: none;
      padding: 0 $space-s;
    }
  }

  // for IE11
  *::-ms-backdrop,
  &__item\@desktop {
    @include mq($from: desktop) {
      width: 25%;
    }
  }

  &__checkbox\@desktop {
    @include mq($from: desktop) {
      border-bottom: 1px solid $color-grey-6;
      height: 100%;
    }
  }

  &__item\@desktop:nth-child(-n+4) &__checkbox\@desktop {
    @include mq($from: desktop) {
      border-top: solid 1px $color-grey-6;
    }
  }

  &__num {
    margin-left: auto;
    font-size: $font-size-xs;
    color: $color-grey-2;
    flex: 0 0 auto;
  }

  &__icon {
    height: $space-m;
    width: $space-s;
    display: block;
    fill: $color-grey-3;
    margin-left: auto;
    flex: 0 0 auto;
  }

  &__num + &__icon {
    margin-left: $space-xs;
    flex: 0 0 auto;
  }

  &__link\@desktop:hover &__num {
    @include mq($from: desktop) {
      visibility: hidden;
    }
  }
}
