@charset "utf-8";

.c-check-mark-white {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $color-white;
  position: relative;
  display: inline-block;

  &__t-5 {
    top: 5px;
  }

  &::before {
    content: '';
    width: 10px;
    height: 2px;
    background-color: $color-primary;
    position: absolute;
    top: 9px;
    left: 7px;
    transform: rotate(-45deg);
  }

  &::after {
    content: '';
    width: 7px;
    height: 2px;
    background-color: $color-primary;
    position: absolute;
    top: 11px;
    left: 3px;
    transform: rotate(45deg);
  }
}
