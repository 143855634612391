@charset "utf-8";

@mixin ellipsis-multiple-line($line-number: 2, $font-size: $font-size-m, $line-height: $line-height-m, $bg-color: $color-white, $color: inherit) {
  font-size: $font-size;
  max-height: calc(#{$font-size} * #{$line-height} * #{$line-number});
  line-height: $line-height;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    background: $bg-color;
    position: absolute;
  }

  &::before {
    content: "...";
    top: calc(#{$font-size} * #{$line-height} * (#{$line-number} - 1));
    right: 0;
    color: $color;
  }

  &::after {
    content: "";
    height: 100%;
    width: 100%;
  }
}
