@charset "utf-8";

.c-box-group {
  height: 100%;
  display: flex;

  &__button {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: $space-s;
    color: $color-white;
    transition: $btn-transition;
    border-left: 1px solid rgba($color-white, $alpha-low-common);
  }

  &__button\@desktop:last-child {
    @include mq($from: desktop) {
      border-right: 1px solid rgba($color-white, $alpha-low-common);
    }
  }

  &__button--large {
    flex-grow: 1;
    height: 145px;
    background-color: $color-grey-6;
    border-radius: $space-s;
  }

  &__button--large + &__button--large {
    margin-left: $space-s;
  }

  &__icon {
    display: block;
    fill: $color-white;
    @include icon-m;
  }

  &__icon--large {
    fill: $color-primary;
    @include icon-xl;
  }

  &__text {
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    margin-top: $space-xs;
    white-space: nowrap;
  }

  &__text\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-m;
      font-weight: $font-weight-bold;
    }
  }

  &__text--large {
    font-size: $font-size-l;
    color: $color-grey-0;
    margin-top: $space-m;
  }

  &__button\@desktop:hover {
    @include mq($from: desktop) {
      background-color: $color-white;
      color: $color-link;
    }
  }

  &__button\@desktop:hover &__icon {
    @include mq($from: desktop) {
      fill: $color-link;
    }
  }

  &__badge {
    position: absolute;
    display: block;
    top: 2px;
    left: -2px;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    color: $color-white;
    background-color: $color-important;
    border-radius: $space-m;
    height: $space-m;
    line-height: $space-m;
    padding: 0 $space-xs;
    min-width: $space-m;
    text-align: center;
  }

  &__badge\@desktop {
    @include mq($from: desktop) {
      top: -#{$space-xs};
      left: $space-xs;
    }
  }

  &__badge:empty {
    display: none;
  }
}
