@charset "utf-8";

.c-header-nav {
  color: $color-white;
  margin-left: auto;

  &__item + &__item {
    margin-left: $space-m;
  }

  &__text-link {
    color: $color-white;
  }

  &__text-link:hover {
    @include mq($from: desktop) {
      text-decoration: underline;
    }
  }

  &__member {
    margin-left: $space-m;
  }

  &__list {
    color: $color-white;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    border-bottom: 0;
    padding: 0;
    margin: $space-s 0 0 0;
  }
}
