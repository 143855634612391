@charset "utf-8";

.c-mission-support {
  padding-top: $space-2xl;
  position: relative;

  &__title {
    color: $color-important;
    @include title-2xl;
    padding: 0 $space-m;
  }

  &__img\@mobile {
    @include mq($until: desktop) {
      max-width: 100vw;
      min-height: 180px;
    }
  }

  &__txt-area {
    padding: 0 $space-m;
  }

  &__small-title {
    @include title-m;
    margin: $space-l 0 0;
  }

  &__txt {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-l;
    margin: $space-l 0 0;

    &:first-of-type {
      margin: $space-xs 0 0;
    }
  }

  &__map-inner {
    width: 280px;
    height: 350px;
    margin: 0 auto $space-s;
    text-align: center;
  }

  &__img-area {
    margin-top: $space-l;
    min-height: 179px;
  }

  &__graph {
    height: 320px;
  }

  &__row:last-child {
    display: flex;
    flex-direction: column;

    .c-mission-support__img-area {
      order: 2;
      margin-top: 0;
    }
  }

  &::before {
    border-bottom: $space-m solid $color-grey-6;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &\@desktop {
    @include mq($from: desktop) {
      padding-top: 0;

      &::before {
        display: none;
      }

      .c-mission-support__title {
        padding: 0;
      }

      .c-mission-support__row {
        display: flex;
        margin-top: $space-3xl;
      }

      .c-mission-support__txt-area {
        padding: 0 $space-l;
      }

      .c-mission-support__img-area {
        margin-top: 0;
        flex: 0 0 420px;
        display: flex;
        align-items: center;
        height: 420px;
      }

      .c-mission-support__small-title {
        margin: 0;
      }

      .c-mission-support__row:nth-of-type(2) .c-mission-support__txt-area {
        padding-right: $space-l;
        padding-left: 0;
      }

      .c-mission-support__row:last-child {
        flex-direction: row;
      }

      .c-mission-support__row:last-child .c-mission-support__img-area {
        order: 0;
        margin-top: 0;
      }

      .c-mission-support__map-inner {
        width: 420px;
        margin: 0 auto;
      }
    }
  }
}
