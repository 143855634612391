@charset "utf-8";

.c-useful-link {

  &__link {
    display: flex;
    align-items: center;
    border-top: 1px solid $color-grey-6;
    color: $color-link;
    padding: $space-m 0;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-card;
        border-radius: $space-s;
        color: $color-grey-0;
        text-decoration: none;
        padding-left: $space-m;
      }
    }
  }

  &__item:last-of-type > &__link {
    border-bottom: 1px solid $color-grey-6;
  }

  &__item:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
    }
  }

  &__item:hover + &__item:last-of-type > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: $color-grey-6;
    height: $space-3xl;
    width: $space-3xl;
    margin-right: $space-s;
  }

  &__txt {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
  }
}
