@charset "utf-8";

.c-alert {
  border-radius: $space-s;
  padding: $space-m;

  &--danger {
    background: rgba($color: $color-important, $alpha: 0.1);
    color: $color-important;
  }

  &--warning {
    // not implemented
  }

  &--relative {
    position: relative;
  }

  &__title {
    text-align: center;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-xs;

    &--left {
      text-align: left;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__txt {
    color: $color-grey-1;
    font-weight: $font-weight-normal;
    line-height: $line-height-m;
    letter-spacing: $letter-spacing-normal;
    font-size: $font-size-s;
    margin-bottom: $space-xs;
  }
}
