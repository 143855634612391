@charset "utf-8";

.c-user-voice-detail {

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $space-m;
  }

  &__person {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__img-box {
    @include image-circle;
    height: $space-3xl;
    width: $space-3xl;
    margin-right: $space-s;
    float: left;
  }

  &__info {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    margin-bottom: $space-s;
    word-break: keep-all;
  }

  &__position {
    color: $color-grey-0;
    font-size: $font-size-s;
  }

  &__satisfaction {
    flex-shrink: 0;
    clear: both;
  }

  &__satisfaction-title {
    color: $color-grey-0;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    text-align: center;
    margin-bottom: $space-s;
  }

  &__satisfaction-star {
    margin-left: $space-xs;
    float: left;

    &:first-child {
      margin: 0;
    }
  }

  &__icon-star {
    display: inline-block;
    height: $space-m;
    width: $space-m;
  }

  &__icon-star--off {
    opacity: $alpha-low-common;
  }

  &__balloon {
    position: relative;
    border-radius: $space-s;
    background: $color-grey-6;
    padding: $space-m;
  }

  &__balloon::before {
    content: "";
    position: absolute;
    top: -$space-m;
    left: $space-m;
    bottom: auto;
    right: auto;
    border-top: $space-s solid transparent;
    border-bottom: $space-s solid $color-grey-6;
    border-right: $space-s solid transparent;
    border-left: $space-s solid transparent;
    height: 0;
    width: 0;
    margin: auto;
  }

  &__voice-title {
    color: $color-grey-0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
    margin-top: $space-m;

    &:first-child {
      margin: 0;
    }
  }
}
