@charset "utf-8";

.c-search-retry {

  &__list {
    margin-top: $space-l;
  }

  &__list-item {
    color: $color-grey-0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-top: $space-m;
  }

  &__list-item:first-child {
    margin: 0;
  }

  &__list-item\@desktop {
    font-size: $font-size-l;
  }

  &__input-area {
    border-top: 1px solid $color-grey-6;
    padding-top: $space-l;
    margin-top: $space-l;
  }

  &__txt {
    color: $color-grey-0;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
  }

  &__txt\@desktop {
    font-size: $font-size-l;
  }

  &__input {
    margin-top: $space-m;
  }
}
