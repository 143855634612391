@charset "utf-8";

.c-mypage-modal-form {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: center;
    }
  }

  &__title {
    @include title-m;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      width: 116px;
    }
  }

  &__input {
    flex-grow: 1;
    margin-top: $space-s;
  }

  &__input\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__preview {
    width: 80px;
    margin-left: auto;
    margin-top: $space-s;
  }

  &__preview\@desktop {
    @include mq($from: desktop) {
      margin-left: $space-m;
      margin-top: 0;
    }
  }

  &__requiered {
    color: $color-important;
  }

  &__within {
    color: $color-grey-1;
    font-size: $font-size-s;
    display: block;
    font-weight: $font-weight-normal;
  }

  &__error {
    color: $color-important;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-bottom: $space-s;
  }
}
