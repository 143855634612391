@charset "utf-8";

.c-job-offer-nearby-start {
  align-items: center;
  display: flex;
  margin: $space-xl 0 $space-xl 0;
  font-weight: bold;
  color: $color-grey-1;

  &::before {
    background-color: $color-grey-3;
    content: "";
    flex-grow: 1;
    height: 1px;
    margin-right: 15px;
  }

  &::after {
    background-color: $color-grey-3;
    content: "";
    flex-grow: 1;
    height: 1px;
    margin-left: 15px;
  }
}

.c-job-offer-nearby-end {
  margin: $space-xl 0 $space-xl 0;
  border-top: 1px solid $color-grey-3;
}
