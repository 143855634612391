@charset "utf-8";

select {
  appearance: none;
  outline: 0;
  border: 0;
  color: inherit;
  letter-spacing: inherit;
  font: inherit;
  -webkit-tap-highlight-color: initial;
}

select::-ms-expand {
  display: none;
}
