@charset "utf-8";

.c-link-feature {
  padding: $space-l $space-m;

  &\@desktop {
    @include mq($from: desktop) {
      padding: 0;
    }
  }

  &__item {
    margin: $space-l 0 0;
  }

  &__item:first-child {
    margin: 0;
  }

  &__title {
    color: $color-grey-0;
    font-size: $font-size-2xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    text-align: center;
    margin-bottom: $space-m;
    white-space: nowrap;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      text-align: left;
    }
  }

  &__txt {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
  }

  &__annotation {
    @include par-xs;
    margin-top: $space-s;
    color: $color-grey-2;
    line-height: $line-height-m;
  }

  &__cover {
    margin-top: $space-xl;
  }

  &__cover\@desktop {
    @include mq($from: desktop) {
      display: flex;
      text-align: left;

      .c-link-feature__item {
        flex-basis: 100%;
        margin: 0 0 0 $space-l;
      }

      .c-link-feature__item:first-child {
        margin: 0;
      }
    }
  }
}
