@charset "utf-8";

.c-segment-mask {
  position: relative;
  overflow: hidden;

  &__content {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0, rgba(255, 255, 255, 0.99) 99%, $color-white 100%);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    &--work_environment {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.1) 25%, rgba(255, 255, 255, 0.825) 27%, rgba(255, 255, 255, 0.925) 99%, $color-white 100%);
      z-index: 1;
    }
  }

  &__inner {
    text-align: center;
    position: absolute;
    top: 490px;
    width: 100%;

    &--work_environment {
      top: 216px;
    }
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      top: 440px;
    }
  }

  &__inner--work_environment\@desktop {
    @include mq($from: desktop) {
      top: 184px;
    }
  }

  &__innertxt {
    font-size: $font-size-2xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
  }

  &__innerbtn {
    margin: $space-l;
    width: 166px;
  }
}
