@charset "utf-8";

.c-scout-appeal-badge {
  font-size: $font-size-s;
  line-height: $line-height-m;

  &__inner {
    padding: $space-s;
    border-radius: $space-s;
    background-color: rgba($color-attention, $alpha-low);
    min-width: 100%;
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      min-width: 608px;
    }
  }

  &__icon {
    width: 30px;
  }

  &__title {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
  }

  &__txt {
    color: $color-grey-2;
    font-size: $font-size-s;
  }
}
