@charset "utf-8";

.c3-chart-arc text {
  fill: #FFF;
  font-size: $font-size-l;
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
}

.c3-chart-arc path {
  stroke: transparent;
}

.c3-axis-y-label {
  transform: translateX(20px);
}

.c3 svg {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "Hiragino Sans", "helvetica", "arial", "メイリオ", sans-serif;
}
