@charset "utf-8";

.c-text-link {
  color: $color-link;
  font-weight: $font-weight-bold;
  cursor: pointer;

  &:hover {
    @include mq($from: desktop) {
      text-decoration: underline;
    }
  }

  &--important {
    color: $color-important;
  }

  &--disabled {
    opacity: $alpha-low-common;
    pointer-events: none;
  }

  &--transparent {
    background-color: transparent;
  }
}
