@charset "utf-8";

.c-inquiry-sub-column-card {
  border-radius: $space-s;
  border: 1px solid $color-primary;
  background: rgba($color-primary, $alpha-low);
  text-align: center;
  padding: $space-m;

  &__info-area {
    border-bottom: 1px solid $color-primary;
    padding-bottom: $space-m;
    margin-bottom: $space-m;
  }

  &__title {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-xs;
  }

  &__tel {

    &\@mobile {
      @include mq($until: desktop) {
        margin-bottom: $space-xs;
      }
    }

    &\@desktop {
      @include mq($from: desktop) {
        font-weight: $font-weight-bold;
        font-size: $font-size-2xl;
        padding: $space-m;
      }
    }
  }

  &__time {
    color: $color-grey-2;
    font-size: $font-size-xs;
    line-height: $line-height-s;
  }

  &\@desktop {
    @include mq($from: desktop) {
      display: inline-flex;

      .c-inquiry-card-side__info-area {
        border: none;
        border-right: 1px solid $color-primary;
        text-align: left;
        padding: 0 $space-l 0 0;
        margin: 0 $space-l 0 0;
      }

      .c-inquiry-card-side__btn-area {
        display: flex;
        align-items: center;
      }
    }
  }
}
