@charset "utf-8";

body {
  min-height: 100%;
  height: 100%;
  min-width: 320px;
  width: 100%;
  letter-spacing: 0.01em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'palt';
  word-wrap: break-word;
  font-kerning: normal;
  display: flex;
  flex-direction: column;
}
