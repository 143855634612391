@charset "utf-8";

html {
  height: 100%;
  width: 100%;
  font-family: $global-font;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'palt';
  -webkit-overflow-scrolling: touch;
}
