@charset "utf-8";

.o-frame {
  width: 100%;
  // sp:縦223/横343*100で縦横比が保たれるように設定
  padding-top: 65%;
  position: relative;

  &\@desktop {
    @include mq($from: desktop) {
      // pc:縦320/横606*100で縦横比が保たれるように設定
      padding-top: 52%;
    }
  }

  &__item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;

    // https://github.com/medley-inc/job-medley/blob/4018259faa5a933675dd487b98b7ab9e22be0dab/client/users/scss/_vendors.lazysizes.scss#L7
    // リサイズ回避のため動画の最低縦横サイズを確保しておく
    min-width: 100%;
    min-height: 100%;
  }
}
