@charset "utf-8";

@mixin shadow-top {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04), 0 8px 32px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-low {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04), 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-top-red {
  box-shadow: 0 8px 24px 0 rgba(255, 81, 118, 0.5);
}

@mixin shadow-top-green {
  box-shadow: 0 8px 24px 0 rgba(57, 191, 185, 0.5);
}

@mixin shadow-top-yellow {
  box-shadow: 0 8px 24px 0 rgba(250, 175, 0, 0.5);
}

@mixin shadow-drawer {
  box-shadow:
    0 1px 1px 0 rgba(0, 0, 0, 0.08),
    0 4px 16px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-input {
  box-shadow:
    0 8px 32px 0 rgba(0, 0, 0, 0.16),
    0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

@mixin shadow-card {
  box-shadow:
    0 8px 32px 0 rgba(0, 0, 0, 0.16),
    0 1px 1px 0 rgba(0, 0, 0, 0.04);
}

@mixin shadow-new {
  box-shadow:
    0 1px 1px 0 rgba(0, 0, 0, 0.08),
    0 4px 16px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-priority-job-offer-card {
  box-shadow: 0 16px 42px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-resume-list-balloon {
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.16);
}

@mixin shadow-map-slider {
  box-shadow:
    0 1px 1px 0 rgba(0, 0, 0, 0.08),
    0 2px 8px 0 rgba(0, 0, 0, 0.16);
}
