@charset "utf-8";

.c-search-list {

  &__item {
    display: flex;
    border-top: 1px solid $color-grey-6;
  }

  &__item:last-child {
    border-bottom: 1px solid $color-grey-6;
  }

  &__link {
    display: flex;
    align-items: center;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    color: $color-link;
    padding: $space-m;
    border-top: solid 1px $color-grey-6;
    line-height: $line-height-s;
    transition: $btn-transition;
    width: 100%;
  }

  &__link--large {
    padding: $space-m 0;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-s;
      padding: $space-m 0;
    }
  }

  &__pref {
    transition: $btn-transition;
    margin-right: $space-s;
  }

  &__num {
    margin-left: auto;
    font-size: $font-size-xs;
    color: $color-grey-2;
    flex: 0 0 auto;
  }

  &__icon {
    height: $space-m;
    width: $space-s;
    display: block;
    fill: $color-grey-3;
    margin-left: auto;
    flex: 0 0 auto;
  }

  &__num + &__icon {
    margin-left: $space-xs;
    flex: 0 0 auto;
  }

  &__link:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      color: $color-grey-0;
      border-radius: $space-s;
    }
  }

  &__link--large:hover &__icon {
    @include mq($from: desktop) {
      transition: $btn-transition;
      transform: translateX(-#{$space-s});
    }
  }

  &__link:hover &__num {
    @include mq($from: desktop) {
      visibility: hidden;
    }
  }

  &__link:hover &__pref {
    @include mq($from: desktop) {
      transform: translateX($space-s);
    }
  }

  &--horizontal\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-left: -$space-s;
    }
  }

  &__item--horizontal\@desktop {
    @include mq($from: desktop) {
      flex: 0 0 calc(100% / 4);
      padding: 0 $space-s;
    }
  }

  &__item--horizontal-large\@desktop {
    @include mq($from: desktop) {
      width: calc(100% / 3);
      flex: 0 0 auto;
      padding: 0 $space-s;
    }
  }

  &__item--horizontal\@desktop > &__link\@desktop {
    @include mq($from: desktop) {
      border-top: solid 1px transparent;
      border-bottom: solid 1px $color-grey-6;
      transition: $btn-transition;
    }
  }

  &__item--horizontal-large\@desktop > &__link\@desktop {
    @include mq($from: desktop) {
      border-top: solid 1px transparent;
      border-bottom: solid 1px $color-grey-6;
      transition: $btn-transition;
    }
  }

  &__item--horizontal\@desktop:nth-child(-n+4) &__link\@desktop {
    @include mq($from: desktop) {
      border-top: solid 1px $color-grey-6;
    }
  }

  &__item--horizontal-large\@desktop:nth-child(-n+3) &__link\@desktop {
    @include mq($from: desktop) {
      border-top: solid 1px $color-grey-6;
    }
  }

  &__item:last-child > &__link {
    border-bottom: solid 1px $color-grey-6;
  }

  &__item:last-child > &__link:hover {
    @include mq($from: desktop) {
      border-bottom: solid 1px transparent;
    }
  }

  &__item:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top: solid 1px transparent;
    }
  }
}
