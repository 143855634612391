@charset "utf-8";

.c-sort-tab {
  display: flex;

  &__item {
    font-weight: $font-weight-bold;
    text-align: center;
    line-height: $line-height-s;
    margin: 0 $space-m;
  }

  &__text {
    display: inline-block;
    border-bottom: 0 solid $color-primary;
    color: $color-link;
    padding: $space-m 0;
    transition: $border-transition;
  }

  &__item--current {

    .c-sort-tab__text {
      border-bottom: $space-xs solid $color-primary;
      color: $color-grey-0;
    }
  }

  &__item\@mobile {
    @include mq($until: desktop) {
      width: 100%;
      margin: 0;

      .c-sort-tab__text {
        display: block;
      }
    }
  }

  &__item:hover {
    @include mq($from: desktop) {

      .c-sort-tab__text {
        border-bottom: $space-xs solid $color-primary;
      }
    }
  }

  &--full {
    width: 100%;

    .c-sort-tab__item {
      flex: 1 1 0;
    }
  }

  &--shadow\@mobile {
    @include mq($until: desktop) {
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        bottom: -#{$space-m};
        @include gradient-bottom;
      }
    }
  }
}
