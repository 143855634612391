@charset "utf-8";

.c-result-number {
  color: $color-grey-1;
  font-size: $font-size-l;
  font-weight: $font-weight-bold;

  &__txt {
    font-size: $font-size-xs;
    padding-right: $space-xs;
  }

  &__txt\@desktop {
    @include mq($from: desktop) {
      display: block;
      padding-right: 0;
    }
  }

  &__important {
    color: $color-important;
  }
}
