@charset "utf-8";

.c-map-job-offer-card {
  @include shadow-map-slider;
  flex-shrink: 0;
  border-radius: $space-s;
  background-color: $color-white;
  transition: $map-slider-transition;
  -webkit-tap-highlight-color: transparent;
  padding: $space-s + $space-xs;

  &__title-text {
    color: $color-link;
    font-weight: $font-weight-bold;
    overflow: hidden;
    @include ellipsis-multiple-line(1, $font-size-m, $line-height-s);
  }

  &__salary-container {
    padding: $space-s 0;
    display: flex;
  }

  &__salary-text {
    font-size: $font-size-xs;
    line-height: $line-height-s;
    color: $color-grey-0;
  }

  &__access-container {
    padding: $space-s 0 0 0;
    border-top: 1px solid $color-grey-6;
  }

  &__access-text {
    overflow: hidden;
    @include ellipsis-multiple-line(1, $font-size-xs, $line-height-s);
  }

  &__photo {
    // iphoneSEサイズでも収まる幅を設定
    width: 56px;
    height: 31px;
    margin-right: $space-s;
    @include image-box;
  }

  &__photo-image {
    width: 100%;
  }

  &__empty-title {
    font-size: $font-size-m;
  }

  &__empty-text {
    padding-top: $space-s;
    font-size: $font-size-s;
    color: $color-grey-2;
  }
}
