@charset "utf-8";

@keyframes jumpjump {

  0% {
    top: 50%;
    left: 0;
  }

  5% {
    top: calc(50% + 2px);
    width: 12px;
    left: -1px;
    height: 9px;
    filter: blur(0.3px);
  }

  10% {
    top: calc(50% + 2px);
    width: 10.5px;
    left: -0.5px;
    height: 9.5px;
    filter: blur(0.3px);
  }

  15% {
    top: calc(50% - 4px);
    width: 9.5px;
    left: 0.5px;
    height: 11.5px;
    filter: blur(0.3px);
  }

  22% {
    top: calc(50% - 8px);
    width: 9.5px;
    left: 0.5px;
    height: 11.5px;
    filter: blur(0.3px);
  }

  29% {
    top: calc(50% - 4px);
    width: 9.5px;
    left: 0.5px;
    height: 11.5px;
    filter: blur(0.3px);
  }

  34% {
    top: 50%;
    left: 0;
  }

  40% {
    top: calc(50% + 2px);
    width: 11.5px;
    left: -0.5px;
    height: 9.5px;
    filter: blur(0.3px);
  }

  43% {
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
  }

  100% {
    top: 50%;
    left: 0;
  }
}
