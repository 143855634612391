@charset "utf-8";

.c-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($color-grey-7, $alpha-high);
  z-index: index($zindex, overlay);
  top: 0;
  left: 0;
  opacity: 1;
  transform: scale(1);
  transition: $fade-in-out-transition;

  &--invisible {
    opacity: 0;
    display: none;
  }
}
