@charset "utf-8";

.o-text-align {
  text-align: left;

  &--centered {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &--right\@desktop {
    @include mq($from: desktop) {
      text-align: right;
    }
  }

  &--left-pc\@desktop {
    @include mq($from: desktop) {
      text-align: left;
    }
  }
}
