@charset "utf-8";

.c-message-list {
  position: relative;

  &__show-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: index($zindex, message_show_button_sp);
    width: 80px;
  }

  &__show-button\@desktop {
    @include mq($from: desktop) {
      position: absolute;
      z-index: index($zindex, message_show_button_pc);
      right: 8px;
      top: 15px;
    }
  }

  &__show-button-link\@mobile {
    @include mq($until: desktop) {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-primary;
      color: $color-white;
      font-size: $font-size-m;
      height: 100%;
      width: 100%;
      text-align: center;
    }
  }

  &__item {
    border-bottom: 1px solid $color-grey-6;
    position: relative;
    z-index: index($zindex, message_item);

    &:first-child {
      border-top: 1px solid $color-grey-6;
    }
  }

  &__link {
    position: relative;
    padding: $space-m;
    display: flex;
    align-items: center;
    background-color: $color-white;
    z-index: 1;
    transform: translateX(0);
    transition: $swipe-message-transition;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      transition: $btn-transition;
      padding: $space-m 0;
      background-color: transparent;
      // タブレットでの表示時にはスワイプでの操作を禁止する
      transform: translateX(0) !important;

      &:hover {
        @include mq($from: desktop) {
          @include shadow-card;
          border-radius: $space-s;
          color: $color-grey-0;
          padding-left: $space-m;
          padding-right: $space-m;
        }
      }
    }
  }

  &__image-cover {
    @include image-box;
    width: 72px;
    height: 72px;
    margin-right: $space-s;
    flex-shrink: 0;
  }

  &__image {
    height: 100%;
  }

  &__company {
    color: $color-grey-0;
    font-weight: $font-weight-bold;
    font-size: $font-size-s;
    overflow: hidden;
  }

  &__title {
    color: $color-link;
    font-weight: $font-weight-bold;
    overflow: hidden;
    @include ellipsis-multiple-line(1, $font-size-l, $line-height-s);
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      //メッセージ一覧で非表示ボタンを横に表示するため
      width: 440px;
    }
  }

  &__company + &__title {
    margin-top: $space-xs;
  }

  &__text-area {
    flex-grow: 1;
  }

  &__text {
    overflow: hidden;
    margin-top: $space-xs;
    color: $color-grey-2;
    @include ellipsis-multiple-line(2, $font-size-s, $line-height-m);
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    margin-top: $space-xs;
  }

  &__date {
    @include par-s;
    color: $color-grey-2;
  }

  &__job-title {
    @include par-s;
    color: $color-grey-2;
  }

  &__image-wrapper {
    position: relative;
  }

  &__badge {
    display: block;
    position: absolute;
    top: -$space-xs;
    left: -$space-xs;
    background: $color-important;
    width: $space-m;
    height: $space-m;
    border-radius: 50%;
  }
}
