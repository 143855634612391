@charset "utf-8";

.c-blocked-list {

  &__item {
    align-items: center;
    border-bottom: 1px solid $color-grey-6;
    display: flex;
    justify-content: space-between;
    padding: $space-m 0;
  }

  &__item:first-child {
    border-top: 1px solid $color-grey-6;
  }

  &__job-offer {
    margin-right: $space-s;
    width: 100%;
  }

  &__name {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
  }

  &__address {
    color: $color-grey-2;
    font-size: $font-size-xs;
    margin-top: $space-xs;
  }
}
