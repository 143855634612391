@charset "utf-8";

.c-toggle-checkbox {

  &__input {
    display: none;
  }

  &__input + &__label,
  &__input + &__label::before,
  &__input + &__label::after {
    transition: $btn-transition;
  }

  &__input + &__label {
    background-color: $color-grey-6;
    display: inline-block;
    position: relative;
    border-radius: $space-s;
    cursor: pointer;
    font-weight: $font-weight-bold;
    width: 98px;
    height: 32px;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top;
      }
    }
  }

  &__input + &__label::before {
    display: block;
    content: attr(data-off-label);
    position: absolute;
    top: 50%;
    right: $space-s;
    transform: translateY(-50%);
    color: $color-grey-1;
    font-size: $font-size-s;
  }

  &__input + &__label::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $space-s;
    width: $space-m;
    height: $space-m;
    border-radius: $space-xs;
    background-color: $color-grey-3;
  }

  &__input:checked + &__label {
    background-color: $color-important;
  }

  &__input:checked + &__label:hover {
    @include mq($from: desktop) {
      @include shadow-top-red;
    }
  }

  &__input:checked + &__label::before {
    content: attr(data-on-label);
    left: $space-s;
    right: auto;
    color: $color-white;
  }

  &__input:checked + &__label::after {
    left: 98px - $space-l;
    background-color: $color-white;
  }
}
