@charset "utf-8";

.c-information-card {
  background: rgba($color: $color-primary, $alpha: 0.1);
  border: 1px solid $color-primary;
  border-radius: $space-s;
  color: $color-grey-1;
  font-size: $font-size-m;
  line-height: $line-height-m;
  padding: $space-l $space-m;
  text-align: center;

  &\@desktop {
    @include mq($from: desktop) {
      padding: $space-l;
      text-align: left;
    }
  }

  &--important {
    background: rgba($color: $color-important, $alpha: 0.1);
    border: 1px solid $color-important;
  }

  &__item {
    margin-top: $space-s;
  }

  &__txtarea {
    text-align: left;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      display: flex;
      margin-top: $space-m;
    }
  }

  &__btnarea {
    margin: $space-s 0 0;
  }

  &__btnarea\@desktop {
    @include mq($from: desktop) {
      flex-basis: 277px;
      flex-shrink: 0;
      margin: 0 0 0 $space-m;
      text-align: center;
    }
  }

  &__btnarea--variable\@desktop {
    @include mq($from: desktop) {
      flex-shrink: 0;
      margin: 0 0 0 $space-m;
      text-align: center;
    }
  }

  &__btntxt {
    display: inline-block;
    font-size: $font-size-s;
    margin-top: $space-m;
  }

  &__btntxt\@desktop {
    margin-top: $space-s;

    @include mq($from: desktop) {

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__list {
    list-style: decimal;
  }

  &__list-item {
    text-align: left;
    margin-left: $space-m;
  }

  &__list-item + &__list-item {
    margin-top: $space-m;
  }
}
