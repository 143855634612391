@charset "utf-8";

.c-line-button {
  display: flex;
  align-items: center;
  padding: $space-s;
  background: $color-line;
  color: $color-white;
  font-weight: $font-weight-bold;
  font-size: $font-size-l;
  text-align: center;
  border-radius: $space-s;
  line-height: $line-height-s;
  transition: $btn-transition;
  width: auto;
  cursor: pointer;
  -webkit-tap-highlight-color: initial;

  &:hover {
    @include mq($from: desktop) {
      background: $color-line;
      color: $color-white;
      @include shadow-top;
    }
  }

  &__icon {
    width: $space-xl;
    height: $space-xl;
    display: block;
    fill: $color-white;
  }

  &__text {
    flex: 1 0 auto;
  }

  &__start--fit\@desktop {
    @include mq($from: desktop) {
      width: 225px;
      margin: 0 auto;
    }
  }

  &--full {
    width: 100%;
  }
}
