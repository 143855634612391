@charset "utf-8";

.c-half-align-block {
  display: flex;
  flex-direction: column;

  &\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__item:last-of-type {
    margin-top: $space-l;
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__item\@desktop:last-of-type {
    @include mq($from: desktop) {
      margin: 0 0 0 $space-l;
    }
  }

  &__inner {
    padding: $space-l $space-m;
    background-color: $color-white;
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      padding: $space-l $space-l $space-m;
      width: 100%;
      border-radius: $space-s;
    }
  }

  &__title {
    text-align: center;
    @include par-s;
    color: $color-grey-1;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      text-align: left;
    }
  }

  &__title-image {
    max-width: 100%;
    max-height: $space-xl;
    width: auto;
    height: 100%;
  }

  &__text {
    text-align: left;
    margin-top: $space-m;
  }

  &__contents {
    margin-top: $space-m;
  }

  &__button {
    text-align: center;
    margin-top: $space-m;
  }
}
