@charset "utf-8";

.c-form-submit {

  &__button {
    cursor: pointer;
    white-space: inherit;
  }

  &__link {
    font-size: $font-size-s;
    text-align: center;
    line-height: $line-height-m;
    margin-top: $space-m;
  }

  &__txt {
    color: $color-grey-2;
    font-size: $font-size-s;
    line-height: $line-height-s;
    margin-top: $space-s;

    &--before-submit {
      margin-bottom: $space-s;
    }
  }

  &__recaptcha {
    margin: $space-m auto $space-s auto;
    text-align: center;
  }

  &__recaptcha-error-message {
    color: #D92B2B;
    padding: $space-m;
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-form-submit__button-area {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: $space-m;
      }

      .c-form-submit__button-area::before {
        display: block;
        content: '';
      }

      .c-form-submit__link {
        margin-top: 0;
        text-align: left;
        align-self: center;
      }

      .c-form-submit__txt {
        text-align: center;
        align-self: center;
      }
    }
  }
}

@media all and (-ms-high-contrast: none) {

  .c-form-submit {

    &\@desktop {
      @include mq($from: desktop) {

        .c-form-submit__button-area {
          display: table;
          table-layout: fixed;
          width: 100%;
        }

        .c-form-submit__button-area > * {
          display: table-cell;
        }

        .c-form-submit__button-area > * + * {
          padding-left: $space-m;
        }
      }
    }
  }
}
