@charset "utf-8";

.c-facility-card {
  @include shadow-card;
  border-radius: $space-s;
  background-color: $color-white;
  transition: $btn-transition;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @include mq($from: desktop) {
      @include card-hover;
    }
  }

  &__wrapper {
    padding: 0;
  }

  &__wrapper\@desktop {
    @include mq($from: desktop) {
      padding: $space-m;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
  }

  &__heading\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
    }
  }

  &__image-box {
    @include image-box;
    border-radius: $space-s $space-s 0 0;
    height: 193px;
  }

  &__image-box\@desktop {
    @include mq($from: desktop) {
      border-radius: $space-s;
      height: 158px;
      width: 280px;
      flex-shrink: 0;
    }
  }

  &__image {
    width: 100%;
  }

  &__txt-area {
    position: relative;
    flex: 1 1 auto;
    padding: $space-m $space-m 0 $space-m;
  }

  &__txt-area\@desktop {
    @include mq($from: desktop) {
      padding: 0 $space-m;
      margin-left: $space-m;
    }
  }

  &__title-container {
    overflow: hidden;
    margin-bottom: $space-m;
  }

  &__title {
    @include ellipsis-multiple-line(2, $line-height:$line-height-s, $font-size: $font-size-m);
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      @include ellipsis-multiple-line(2, $line-height:$line-height-s, $font-size: $font-size-2xl);
    }
  }

  &__txt-container {
    overflow: hidden;
  }

  &__txt {
    font-size: $font-size-s;
    color: $color-grey-1;
  }

  &__job-list {
    margin-top: $space-s;
    overflow: hidden;
  }

  &__job-list-item {
    font-size: $font-size-s;
    color: $color-grey-1;
    float: left;
    margin-right: 1em;
    line-height: $line-height-s;
  }

  &__button {
    padding: $space-m;
  }

  &__button\@desktop {
    @include mq($from: desktop) {
      padding: $space-m 0 0 0;
    }
  }

  &__job-offer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__job-offer\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__job-title {
    @include title-s;
    color: $color-link;
    margin-right: 1rem;
  }

  &__job-title\@desktop {
    @include mq($from: desktop) {
      flex: 0 0 180px;
    }
  }

  &__salary {
    @include par-s;
    color: $color-grey-1;
    margin-right: $space-l;
    margin-top: $space-s;
  }

  &__salary\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }

  &__list-link {
    padding: $space-m;
    display: flex;
    align-items: center;
    border-top: 1px solid $color-grey-6;
    position: relative;
    transition: $btn-transition;
    -webkit-tap-highlight-color: initial;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top;
        padding: $space-m $space-l;
        border-top: 1px solid transparent;
        border-radius: $space-s;
      }
    }
  }

  &__list-arrow {
    position: absolute;
    top: 50%;
    right: $space-m;
    transform: translateY(-50%);
    width: $space-s;
    height: $space-m;
    display: block;
    fill: $color-grey-3;
    transition: $btn-transition;
  }

  &__item:hover + &__item > &__list-link {
    @include mq($from: desktop) {
      border-top-color: transparent;
    }
  }

  &__list-link:hover > &__list-arrow {
    @include mq($from: desktop) {
      right: $space-l;
    }
  }
}
