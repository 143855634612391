@charset "utf-8";

.c-inner-arrowed-list {

  &__item {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;
    border-top: 1px solid $color-white;

    &:first-child {
      border: none;
    }

    &:hover {
      @include mq($from: desktop) {
        border-color: transparent;
      }
    }
  }

  &__arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: $space-m;
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transform: translateY(-50%);
    transition: $btn-transition;
  }

  &__link {
    display: block;
    position: relative;
    color: $color-link;
    padding: $space-m $space-3xl $space-m $space-m;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-card;
        background: $color-white;
        border-radius: $space-s;
        color: $color-grey-0;
        text-decoration: none;
        padding-left: $space-l;

        .c-inner-arrowed-list__arrow {
          right: $space-xl;
        }
      }
    }
  }

  &__item:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
    }
  }

  &__title {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    transition: $btn-transition;
    letter-spacing: 0.001em;
  }

  &__counter {
    color: $color-grey-2;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
  }

  &__title + &__counter {
    margin-left: $space-xs;
  }
}
