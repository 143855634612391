.c-modal-job-type {

  &-nav {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    &__shadow {
      position: relative;

      &::after {
        content: '';
        width: 100%;
        height: $space-m;
        position: absolute;
        left: 0;
        bottom: -#{$space-m};
        @include gradient-bottom;
      }
    }

    &__wrapper {
      padding: 0 $space-s;
      display: table;
    }

    &__item {
      display: table-cell;
      white-space: nowrap;
    }

    &__link {
      display: block;
      border-bottom: 0 solid $color-primary;
      font-size: $font-size-l;
      padding: $space-m;
      font-weight: $font-weight-bold;
      color: $color-link;
      line-height: 1;
      transition: $border-transition;

      &:hover {
        @include mq($from: desktop) {
          border-bottom: $space-xs solid $color-primary;
          color: $color-grey-0;
        }
      }

      &--active {
        border-bottom: $space-xs solid $color-primary;
        color: $color-grey-0;
      }
    }
  }

  &-content {
    display: none;

    &--active {
      display: block;
    }
  }
}
