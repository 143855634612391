@charset "utf-8";

.o-gutter-column {
  display: flex;
  flex-direction: row;

  & > &__item + &__item {
    margin-left: $space-m;
  }

  &\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-left: $space-m;
    }
  }

  &\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }

  &--xs > &__item + &__item {
    margin-left: $space-xs;
  }

  &--xs\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-left: $space-xs;
    }
  }

  &--xs\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-left: $space-xs;
    }
  }

  &--s > &__item + &__item {
    margin-left: $space-s;
  }

  &--s\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-left: $space-s;
    }
  }

  &--s\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-left: $space-s;
    }
  }

  &--m > &__item + &__item {
    margin-left: $space-m;
  }

  &--m\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }

  &--m\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-left: $space-m;
    }
  }

  &--l > &__item + &__item {
    margin-left: $space-l;
  }

  &--l\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-left: $space-l;
    }
  }

  &--l\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-left: $space-l;
    }
  }

  &--xl > &__item + &__item {
    margin-left: $space-xl;
  }

  &--xl\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-left: $space-xl;
    }
  }

  &--xl\@mobile > &__item + &__item {
    @include mq($until: desktop) {
      margin-left: $space-xl;
    }
  }

  &--center {
    align-items: center;
  }

  &--flex-start {
    align-items: flex-start;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}
