@charset "utf-8";

.u-flex-grow {

  &-1\@mobile {
    @include mq($until: desktop) {
      flex-grow: 1 !important;
    }
  }
}
