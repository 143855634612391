@charset "utf-8";

.c-footer-message {

  &__inner {
    color: $color-grey-2;
    font-size: $font-size-xs;
    line-height: $line-height-m;
    @include ellipsis-multiple-line(5, $font-size-xs, $line-height-m, $color: $color-grey-2);
  }

  &__inner\@desktop {
    @include mq($from: desktop) {
      overflow-y: hidden;
      height: auto;
      max-height: 100%;
    }
  }

  &__inner\@desktop::before,
  &__inner\@desktop::after {
    content: none;
  }

  &__show-more {
    display: block;
    text-align: right;
    color: $color-link;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
    background-color: $color-white;
    line-height: $line-height-m;
  }

  &__show-more--opened::before {
    display: none;
  }

  &__emphasis {
    font-weight: $font-weight-bold;
  }

  &__inner + &__show-more {
    display: block;
  }

  &__inner\@desktop + &__show-more {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__inner--visible {
    overflow-y: auto;
    height: auto;
    max-height: 100%;
  }

  &__inner--visible::before,
  &__inner--visible::after {
    content: none;
  }
}
