@charset "utf-8";

.c-mission-thought-title {
  position: relative;

  &::before {
    border-bottom: $space-m solid $color-grey-6;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &\@desktop {
    @include mq($from: desktop) {
      margin-left: -$space-3xl;
      margin-right: -$space-3xl;
    }

    &::before {
      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  &__image {
    width: 100%;
    min-height: 196px;
    padding-top: $space-m;
  }

  &__image\@desktop {
    @include mq($from: desktop) {
      height: 416px;
    }
  }
}
