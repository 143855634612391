@charset "utf-8";

.c-button {
  font-weight: $font-weight-bold;
  font-size: $font-size-l;
  color: $color-link;
  text-align: center;
  background: $color-grey-6;
  border-radius: $space-s;
  line-height: $line-height-s;
  padding: $space-m;
  display: inline-block;
  transition: $btn-transition;
  width: auto;
  cursor: pointer;

  &:hover {
    @include mq($from: desktop) {
      background: $color-white;
      color: $color-grey-0;
      @include shadow-top;
    }
  }

  &--full {
    width: 100%;
  }

  &--full\@mobile {
    @include mq($until: desktop) {
      width: 100%;
    }
  }

  &--small {
    font-size: $font-size-s;
    padding: $space-s;
  }

  &--small-height {
    line-height: 0.25;
  }

  &--primary {
    color: $color-white;
    background: $color-primary;

    &:hover {
      @include mq($from: desktop) {
        background: $color-primary;
        color: $color-white;
        @include shadow-top-green;
      }
    }
  }

  &--important {
    color: $color-white;
    background: $color-important;

    &:hover {
      @include mq($from: desktop) {
        background: $color-important;
        color: $color-white;
        @include shadow-top-red;
      }
    }
  }

  &--attention {
    color: $color-white;
    background: $color-attention;
    font-size: $font-size-xl;
    // 他のボタンとの高さを合わせるためにsquareが持つ余計な高さを差し引く
    padding: ($space-m - $space-xs) $space-s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      @include mq($from: desktop) {
        background: $color-attention;
        color: $color-white;
        @include shadow-top-yellow;
      }
    }
  }

  &--one-click {
    font-size: $font-size-l;
    padding: 16px 8px;
  }

  &--white {
    background: $color-white;
  }

  &--large {
    color: $color-white;
    font-size: $font-size-xl;
    // 他のボタンとの高さを合わせるためにsquareが持つ余計な高さを差し引く
    padding: ($space-m - $space-xs) $space-s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top-red;
      }
    }
  }

  &__square {
    color: $color-important;
    background: $color-white;
    font-size: $font-size-xs;
    padding: $space-s;
    margin-left: $space-m;
    border-radius: $space-s;
  }

  &--like {
    max-width: 40px;
    max-height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-grey-4;
    background-color: $color-white;
    margin-left: $space-s;
    z-index: 1;
  }

  &__like-icon {
    display: contents;
    stroke: $color-grey-2;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: $color-white;

    svg {
      padding: 3px 0 0 0;
      min-height: 22px;
      min-width: 24px;
    }
  }

  &--keep {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ($space-m - $space-xs) $space-s;
    font-size: $font-size-xl;
  }

  &__keep-icon {
    @include icon-m;
    display: inline-block;
    fill: $color-grey-3;
    margin-right: $space-xs;
    line-height: 1;
  }

  &__keep-icon--hold {
    fill: $color-attention !important;
    stroke: $color-attention !important;
  }

  &__keep-icon--hold + &__keep-text {
    color: $color-grey-0;
    letter-spacing: 0.01rem;// edgeでのテキスト折り返しを回避するため
  }

  &--details {
    padding: ($space-m - $space-xs) $space-s;
    font-size: $font-size-xl;
  }

  &--arrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $space-m;
  }

  &__arrow-up {
    flex: 0 0 auto;
    @include icon-m;
    fill: $color-grey-3;
  }

  &--fb {
    background: $color-facebook;
    color: $color-white;

    &:hover {
      @include mq($from: desktop) {
        background: $color-facebook;
        @include shadow-top;
      }
    }
  }

  &--tw {
    background: $color-twitter;
    color: $color-white;

    &:hover {
      @include mq($from: desktop) {
        background: $color-twitter;
        @include shadow-top;
      }
    }
  }

  &--line {
    background: $color-line;
    color: $color-white;

    &:hover {
      @include mq($from: desktop) {
        background: $color-line;
        color: $color-white;
        @include shadow-top;
      }
    }
  }

  &__social-icon {
    @include icon-l;
    display: block;
    margin: auto;
    fill: $color-white;
  }

  &:disabled,
  &--disabled {
    pointer-events: none;
    opacity: $alpha-low-common;

    &:hover {
      @include mq($from: desktop) {
        box-shadow: none;
        cursor: default;
      }
    }
  }

  &__small-text {
    font-size: $font-size-s;
  }

  &--no-radius\@mobile {
    @include mq($until: desktop) {
      border-radius: 0;
    }
  }

  // ボタン内テキストが 2 段の場合に、ボタンの合計 height を 46px に調整するための padding
  &__double-text {
    padding-top: 0;
    padding-bottom: 6px;
  }

  &__double-text--upper {
    font-size: $font-size-s;
  }

  &__setting--active {
    background: rgba($color-primary, $alpha-low);
    font-weight: $font-weight-normal;
    width: 100%;
    height: 52px;
    padding: 0 $space-s;

    &:hover {
      @include mq($from: desktop) {
        background: rgba($color-primary, $alpha-low);
        color: $color-link;
        @include shadow-top;
      }
    }

    &:disabled {
      background: rgba($color-primary, $alpha-low);
      font-weight: $font-weight-normal;
      pointer-events: none;
      opacity: 1;
    }
  }

  &--wide {
    padding-left: $space-l;
    padding-right: $space-l;
  }

  &--top-m {
    margin-top: $space-m;
  }
}

@media all and (-ms-high-contrast: none) {

  .c-button--keep {
    white-space: nowrap;
  }
}

.o-button-group__item {

  .c-button--like {
    margin: auto;
  }
}
