@charset "utf-8";

.u-pointer-events {

  &-auto {
    pointer-events: auto !important;
  }

  &-none {
    pointer-events: none !important;
  }
}
