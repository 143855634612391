@charset "utf-8";

.u-height {

  &-auto {
    height: auto !important;
  }

  &-auto\@desktop {
    @include mq($from: desktop) {
      height: auto !important;
    }
  }
}
