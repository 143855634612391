@charset "utf-8";

.c-grouped-link-box {
  display: flex;
  align-items: stretch;
  width: 100%;
  line-height: 2;

  &__link {
    display: flex;
    padding: $space-s $space-m;
    align-items: center;
    flex-grow: 1;
    background-color: $color-white;
    transition: $btn-transition;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: center;
      padding: $space-s 0;
    }
  }

  &__link\@desktop:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      border-radius: $space-s;
      position: relative;
    }
  }

  &__link--fit\@mobile {
    @include mq($until: desktop) {
      padding: $space-s 0;
    }
  }

  &__main {
    color: $color-link;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    transition: $btn-transition;

    &--keyword {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &\@desktop {
        @include mq($from: desktop) {
          width: calc(($width-desktop-container - $space-l * 2 - $space-s * 3 ) / 4 - $space-xl);
        }
      }

      &\@mobile {
        @include mq($until: desktop) {
          width: calc(100vw - #{ $space-xl } - #{ $space-m });
        }
      }
    }
  }

  &__main\@desktop {
    @include mq($from: desktop) {
      font-size: $font-size-s;
      white-space: nowrap;
    }
  }

  &__counter {
    color: $color-grey-2;
    font-size: $font-size-xs;
    font-weight: $font-weight-bold;
  }

  &__icon {
    display: block;
    background-color: $color-white;
    margin-left: auto;
    transition: $btn-transition;
  }

  &__main + &__counter {
    margin-left: $space-s;
  }

  &__checkbox + &__icon {
    align-self: stretch;
    padding: $space-m;
    border-left: 1px solid $color-grey-6;
  }

  &__link\@desktop:hover > &__main {
    @include mq($from: desktop) {
      transform: translateX($space-s);
    }
  }

  &__link\@desktop:hover > &__counter {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__link\@desktop > &__icon {
    @include mq($from: desktop) {
      opacity: 0;
    }
  }

  &__link\@desktop:hover > &__icon {
    @include mq($from: desktop) {
      opacity: 1;
      transform: translateX(-$space-s);
    }
  }

  &__icon\@desktop:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      border-radius: $space-s;
      position: relative;
    }
  }

  &__icon-inner {
    display: flex;
    height: $space-m;
    align-items: center;

    &--hidden {
      display: none;
    }
  }

  &__icon-icon {
    display: flex;
    fill: $color-grey-3;
    width: $space-s;
    height: $space-m;
  }

  &__checkbox {
    display: flex;
    padding: $space-s $space-m;
    align-items: center;
    flex-grow: 1;
    background-color: $color-white;
    transition: $btn-transition;
    cursor: pointer;

    &--search-panel {
      padding: $space-s 0 $space-s $space-s;
    }
  }

  &__checkbox\@desktop:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      border-radius: $space-s;
      position: relative;
    }
  }

  // checkedの状態でも非活性のように見せる特殊ケースに対処するためのmodifier
  &__checkbox--disabled-visually {
    opacity: 0.25;
    pointer-events: none;
    cursor: default;
  }

  &__checkbox + &__icon\@desktop {
    @include mq($from: desktop) {
      align-self: stretch;
      padding: calc(#{ $space-m } - #{ $space-xs });
      border-left: 1px solid $color-grey-6;
      border-right: 1px solid $color-grey-6;
    }
  }

  &__checkbox--search-panel + &__icon\@desktop {
    @include mq($from: desktop) {
      display: flex;
      padding: $space-s;
      width: 100%;
    }
  }

  &__checkbox-input {
    display: none;
  }

  &__checkbox-input:checked {
    background: $color-primary;
  }

  &__checkbox--disabled-visually > &__checkbox-input:checked {
    background: transparent;
  }

  &__checkbox-mimic {
    padding: 0 $space-s 0 0;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    transition: $btn-transition;
    cursor: pointer;
  }

  &__checkbox-mimic-inner {
    position: relative;
    display: block;
    width: $space-l;
    height: $space-l;
    border-radius: $space-xs;
    background: $color-grey-5;
  }

  &__checkbox-mimic-inner\@desktop {
    @include mq($from: desktop) {
      width: $space-m;
      height: $space-m;
      // IE11
      @media all and (-ms-high-contrast: none) {
        width: 21px;
        height: 17px;
      }
    }
  }

  &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner {
    background: $color-primary;
    border: 1px solid $color-primary;
    position: relative;
    width: $space-l;
    height: $space-l;
    display: block;
  }

  &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner\@desktop {
    @include mq($from: desktop) {
      width: $space-m;
      height: $space-m;
      // IE11
      @media all and (-ms-high-contrast: none) {
        width: 21px;
        height: 17px;
      }
    }
  }

  &__checkbox--disabled-visually > &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner {
    background: $color-grey-5;
    border: transparent;
  }

  &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner::before {
    position: absolute;
    left: 5px;
    top: 13px;
    display: block;
    content: '';
    width: 5px;
    height: 2px;
    background: $color-white;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  &__checkbox--disabled-visually > &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner::before {
    content: none;
  }

  &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner\@desktop::before {
    @include mq($from: desktop) {
      left: 2px;
      top: 9px;
    }
  }

  &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner::after {
    display: block;
    position: absolute;
    left: 9px;
    top: 13px;
    content: "";
    width: 8px;
    height: 2px;
    background: #FFFFFF;
    transform: rotate(-45deg);
    transform-origin: left center;
  }

  &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner\@desktop::after {
    @include mq($from: desktop) {
      left: 5.5px;
      top: 9px;
    }
  }

  &__checkbox--disabled-visually > &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner::after {
    content: none;
  }

  &--small {

    &__checkbox-mimic-inner {
      width: 16px;
      height: 16px;
    }

    &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner {
      width: 16px;
      height: 16px;
    }

    &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner::before {
      left: 2px;
      top: 9px;
    }

    &__checkbox-input:checked + &__checkbox-mimic > &__checkbox-mimic-inner::after {
      left: 5.5px;
      top: 9px;
    }
  }
}
