@charset "utf-8";

.c-careersheet-form {

  &__row {
    padding-top: $space-l;

    &:first-child {
      padding-top: 0;
    }
  }

  &__row\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__title {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      min-width: 185px;
      padding-right: $space-s;
      line-height: $line-height-m;
    }
  }

  &__necessary {
    color: $color-important;
  }

  &__input {
    margin-top: $space-s;
    width: 100%;
  }

  &__input\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__error-message {
    color: $color-important;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-top: $space-xs;
  }

  &__error-message:empty {
    margin-top: 0;
  }
}
