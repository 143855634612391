@charset "utf-8";

.c-recommended-point {
  border-top: 1px solid $color-grey-6;
  padding-top: $space-m;

  &__image-box {
    height: 64px;
    width: 64px;
    margin-right: $space-m;
    overflow: hidden;
    border-radius: $space-s;
    flex-shrink: 0;
    position: relative;
  }

  &__image-box--circle {
    align-self: flex-start;
    overflow: hidden;
    border-radius: 100%;
  }

  &__image {
    max-width: 100%;
  }

  &__jm-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
    margin-bottom: $space-m;
  }

  &__header-title {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    margin-bottom: $space-s;
  }

  &__header-body {
    color: $color-grey-2;
    font-size: $font-size-s;
    line-height: 1.25;
  }

  &__balloon {
    position: relative;
    border-radius: $space-s;
    background-color: rgba(57, 191, 185, 0.1);
    padding: $space-m;
  }

  &__balloon::before {
    content: "";
    position: absolute;
    top: -$space-m;
    left: $space-m;
    bottom: auto;
    right: auto;
    border-top: $space-s solid transparent;
    border-bottom: $space-s solid rgba(57, 191, 185, 0.1);
    border-right: $space-s solid transparent;
    border-left: $space-s solid transparent;
    height: 0;
    width: 0;
    margin: auto;
  }

  &__txt {
    margin-top: $space-m;
  }

  &__inner {
    margin-top: $space-l;

    &:first-child {
      margin-top: 0;
    }
  }

  &__white-space {
    white-space: pre-line;
  }

  &__footer {
    text-align: right;
    margin-top: $space-m;
  }

  &__updated-at {
    color: $color-grey-2;
    font-size: $font-size-s;
    line-height: 1.25;
  }
}
