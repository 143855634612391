@charset "utf-8";

.c-link-list {

  &__item {
    text-align: left;
  }

  &__title {
    margin-right: 1em;
    flex: 0 0 auto;
  }

  &__label {
    margin-right: $space-xs;
    @include title-l;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transition: $btn-transition;
  }

  &__link {
    border-top: 1px solid $color-grey-6;
    padding: $space-m 0;
    color: $color-link;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      flex-direction: row;
      align-items: center;
      transition: $btn-transition;

      &:hover {
        @include shadow-top;
        border-radius: $space-s;
        padding: $space-m;
        color: $color-link;

        .c-link-list__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__item:last-child > &__link {
    border-bottom: 1px solid $color-grey-6;
  }

  &__item\@desktop:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &__item\@desktop:last-child:hover > &__link {
    @include mq($from: desktop) {
      border-bottom-color: transparent;
    }
  }
}
