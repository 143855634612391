@charset "utf-8";

.o-card-grid {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: $space-l;

  &\@desktop {
    @include mq($from: desktop) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: $space-m;
    }
  }

  &--3\@desktop {
    @include mq($from: desktop) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: $space-m;
    }
  }

  &--align-baseline\@desktop {
    @include mq($from: desktop) {
      align-items: baseline;
    }
  }
}

@media all and (-ms-high-contrast: none) {

  .o-card-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: - $space-m;
    margin-bottom: - $space-m;

    & > * {
      width: calc(50% - #{ $space-m });
      margin-right: $space-m;
      margin-bottom: $space-m;
    }
  }

  .o-card-grid--3\@desktop {

    & > * {
      @include mq($from: desktop) {
        width: calc(33.3% - #{ $space-m });
        margin-right: $space-m;
        margin-bottom: $space-m;
      }
    }
  }
}
