@charset "utf-8";

.c-user-voice-list {

  &__link {
    padding: $space-m $space-s $space-m 0;
    font-weight: $font-weight-bold;
    transition: $btn-transition;
    border-bottom: 1px solid $color-grey-6;
    display: block;
  }

  &__link-inner {
    display: flex;
    align-items: center;
    transition: $btn-transition;
  }

  &__link:hover {
    @include mq($from: desktop) {
      @include shadow-top;
      border-radius: $space-s;
      border-bottom: 1px solid transparent;
    }
  }

  &__item:first-child > &__link {
    border-top: 1px solid $color-grey-6;
  }

  &__img-box {
    width: $space-3xl;
    height: $space-3xl;
    @include image-circle;
    display: inline-flex;
    flex: 0 0 $space-3xl;
  }

  &__title-cover {
    margin-left: $space-s;
    color: $color-link;
    overflow: hidden;
  }

  &__title {
    display: block;
    @include ellipsis-multiple-line(2);
  }

  &__occupation {
    @include title-s;
    color: $color-grey-2;
    margin-left: $space-s;
  }

  &__link:hover &__link-inner {
    @include mq($from: desktop) {
      padding: 0 $space-m
    }
  }
}
