@charset "utf-8";

// lazySizesConfig.lazyClass で複数クラスが指定できないので js- で例外的にstyleを付与する
// https://github.com/aFarkas/lazysizes#js-api
.js-lazy-image {
  display: flex !important;
  width: auto !important;
  max-height: 100px !important;
  margin: 0 auto !important;
}

// loaderが画像読み込み完了前にサイズ変わって見えてしまうのを回避するため、画像読み込み完了直前までloaderのwidthをautoにしておく
.js-lazy-image-loading {
  display: flex !important;
  width: auto !important;
  max-height: 100px !important;
  margin: 0 auto !important;
}

// loaderのサイズと読み込まれる画像のサイズが大きく異なる場合にloaderの表示サイズおよび位置をコントロールするためmin-height上書きする
.c-mission-support__img-area > .js-lazy-image,
.c-mission-support__img-area > .js-lazy-image-loading,
.c-mission-thought-title > .js-lazy-image,
.c-mission-thought-title > .js-lazy-image-loading {
  min-height: 100px !important;
}
