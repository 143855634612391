@charset "utf-8";

.c-horizontal-card-list {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &\@desktop {
    @include mq($from: desktop) {
      overflow-x: initial;
      -webkit-overflow-scrolling: auto;
    }
  }

  &__wrapper {
    display: flex;
    padding: $space-l $space-m $space-xl;
  }

  &__wrapper\@desktop {
    @include mq($from: desktop) {
      display: block;
      padding: $space-l;
    }
  }

  &__item {
    flex: 0 0 298px;
    white-space: nowrap;
    padding-right: $space-m;

    &--alone {
      flex: initial;
      padding-right: 0;
    }
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 auto;
      padding-right: 0;
    }
  }

  &__item > &__body {
    white-space: normal;
  }

  &__wrapper\@desktop > &__item + &__item {
    @include mq($from: desktop) {
      margin-top: $space-m;
    }
  }
}
