@charset "utf-8";

.c-member-merits {
  padding: $space-m $space-m $space-l;
  border-top: $border-important;
  border-bottom: $border-important;
  background-color: $color-white;

  &__box-cover {
    margin-top: $space-l;
  }

  &__box-cover\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__box {
    text-align: center;
  }

  &__box + &__box {
    border-top: 1px solid $color-white;
  }

  &__box\@desktop {
    @include mq($from: desktop) {
      flex: 1 1 0;
      margin-left: $space-m;
      background-color: $color-grey-6;
      border-radius: $space-s;
    }
  }

  &__box\@desktop + &__box\@desktop {
    @include mq($from: desktop) {
      border-top: 0;
    }
  }

  &__box\@desktop:first-child {
    @include mq($from: desktop) {
      margin-left: 0;
    }
  }

  &__title {
    @include title-2xl;
    color: $color-important;
    text-align: center;
  }

  &__box-title {
    background-color: $color-grey-6;
    transition: $btn-transition;
    @include title-l;
    color: $color-important;
    display: flex;
    padding: $space-m;
    align-items: center;
  }

  &__box:first-child > &__box-title {
    @include mq($until: desktop) {
      border-radius: $space-s $space-s 0 0;
    }
  }

  &__box:last-child > &__box-title {
    @include mq($until: desktop) {
      border-radius: 0 0 $space-s $space-s;
    }
  }

  &__box\@mobile:last-child > &__box-title--open {
    @include mq($until: desktop) {
      border-radius: 0;
    }
  }

  &__box-title\@desktop {
    @include mq($from: desktop) {
      flex-direction: column;
      border-radius: $space-s;
      padding: $space-m 0;
    }
  }

  &__detail {
    background-color: $color-grey-6;
    @include par-m;
    text-align: left;
    color: $color-grey-1;
    height: 0;
    overflow: hidden;
    transition: $btn-transition;
  }

  &__detail-inner {
    padding: 0 $space-m $space-m;
  }

  &__detail-inner-main {
    font-weight: $font-weight-bold;
  }

  &__detail-inner-note {
    color: $color-grey-2;
    margin-top: $space-s;
  }

  &__text {
    padding-right: $space-m;
    margin-left: $space-s;
    text-align: left;
  }

  &__text\@desktop {
    @include mq($from: desktop) {
      margin: $space-s $space-s 0;
      text-align: center;
    }
  }

  &__icon {
    display: block;
    @include icon-xl;
    fill: $color-important;
  }

  &__icon--small {
    @include icon-m;
    margin-left: auto;
    transition: $btn-transition;
  }

  &__icon--small\@desktop {
    @include mq($from: desktop) {
      margin: $space-m 0 0;
    }
  }

  &__box-title--close > &__icon--small {
    transform: rotate(0deg);
  }

  &__box-title--open > &__icon--small {
    transform: rotate(180deg);
  }

  &__box-title--open + &__detail {
    height: auto;
  }

  &__box\@mobile:last-child > &__detail {
    @include mq($until: desktop) {
      border-radius: 0 0 $space-s $space-s;
    }
  }

  &__foot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: $space-m;
    flex-direction: column;
  }

  &__foot\@desktop {
    @include mq($from: desktop) {
      flex-direction: column;
    }
  }

  &__foot-button {
    width: 100%;
  }

  &__foot-button\@desktop {
    @include mq($from: desktop) {
      width: 205px;
    }
  }

  &__foot-link {
    font-size: $font-size-s;
    margin-top: $space-s;
  }

  &__foot-link\@desktop {
    @include mq($from: desktop) {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: $font-size-s;
      margin-top: 0;
    }
  }

  &\@desktop {
    @include mq($from: desktop) {
      padding: $space-m;
      border-radius: $space-s;
      border: $border-important;

      .c-member-merits__box-title {
        border-radius: $space-s $space-s 0 0;
      }

      .c-member-merits__text {
        padding-right: 0;
      }

      .c-member-merits__detail {
        height: auto;
        border-radius: 0 0 $space-s $space-s;
      }
    }
  }
}
