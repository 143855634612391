@charset "utf-8";

.c-job-offer-and-member-trend {

  .c3-axis-x path,
  .c3-axis-x line {
    display: none;
  }

  .c3-axis-x text {
    font-size: $font-size-xs;
  }

  &__item {
    border-top: 1px solid $color-grey-6;
    padding-top: $space-m;
  }

  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__row-item + &__row-item {
    margin-left: $space-m;
  }

  &__count {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    text-align: center;
    align-items: flex-end;
    white-space: nowrap;
  }

  &__count-header {
    white-space: nowrap;
  }

  &__count-strong {
    font-size: 52px;
    font-weight: $font-weight-bold;
    color: $color-attention;
  }

  &__rate {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: center;
    white-space: nowrap;
  }

  &__rate-strong {
    display: inline-block;
    font-size: 40px;
    font-weight: $font-weight-bold;
  }

  &__city {
    display: flex;
    justify-content: center;
  }

  &__own-city,
  &__other-city {
    width: 100%;
  }

  &__own-city + &__other-city,
  &__other-city + &__other-city {
    border-left: 1px solid $color-grey-5;
  }
}
