@charset "utf-8";

.c-guide-summary {
  margin: 0 auto;

  &\@desktop {
    @include mq($from: desktop) {
      width: 626px;
      line-height: $line-height-m;
    }
  }

  &__logo {
    width: 225px;
    min-height: 40px;
    margin: 0 auto;
  }

  &__image {
    width: 100%;
  }

  &__title {
    @include title-2xl;
    margin-top: $space-l;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      text-align: center;
    }
  }

  &__small-title {
    @include title-m;
  }

  &__text {
    @include par-m;
    color: $color-grey-1;
  }

  &__title + &__text {
    margin-top: $space-l;
  }

  &__section {
    margin-top: $space-l;
  }

  &__info {
    color: $color-grey-1;
    font-size: $font-size-m;
    width: 100%;
    line-height: $line-height-m;
  }

  &__info-row {
    vertical-align: top;
  }

  &__info-title {
    text-align: left;
    display: block;
    width: 100%;
    padding-top: $space-m;
  }

  &__info-title\@desktop {
    @include mq($from: desktop) {
      // IE11崩れ防止用幅指定
      width: 260px;
      display: table-cell;
    }
  }

  &__info-text {
    text-align: left;
    display: block;
    padding-top: $space-s;
  }

  &__info-text\@desktop {
    @include mq($from: desktop) {
      display: table-cell;
      padding-top: $space-m;
    }
  }
}
