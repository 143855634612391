@charset "utf-8";

.c-mypage-form-table {

  &__row {
    padding-top: $space-l;

    &:first-child {
      padding-top: 0;
    }
  }

  &__row\@desktop {
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__row--flex-wrap {
    flex-wrap: wrap;
  }

  &__row--narrow {
    padding-top: $space-xs;
  }

  &__row--wide {
    padding-top: $space-l;
  }

  &__title {
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
  }

  &__title--grey-0 {
    color: $color-grey-0;
  }

  &__title\@desktop {
    @include mq($from: desktop) {
      min-width: 185px;
      flex: 1;
      padding-right: $space-s;
      line-height: $line-height-m;
    }
  }

  &__necessary {
    color: $color-important;
  }

  &__input {
    margin-top: $space-s;
    width: 100%;

    .c-mypage-form-table__row {
      margin-top: $space-xs;
    }

    .c-mypage-form-table__row:first-child {
      margin-top: 0;
    }
  }

  &__input\@desktop {
    @include mq($from: desktop) {
      margin-top: 0;
    }
  }

  &__horizontal {
    align-items: flex-start;
    display: flex;
  }

  &__horizontal--centered {
    align-items: center;
  }

  &__item + &__item {
    margin-left: $space-xs;
  }

  &__item--full {
    width: 100%;
  }

  &__item--smaller {
    width: 100px;
  }

  &__item--small {
    width: 130px
  }

  &__item\@desktop {
    @include mq($from: desktop) {
      width: 210px;
    }
  }

  &__radio-wrap {
    margin-top: -$space-xs;
  }

  &__radio {
    margin: $space-xs $space-xs 0 0;
  }

  &__checkbox-wrap {
    margin-top: -$space-xs;
  }

  &__checkbox {
    margin: $space-xs 0 0 0;
  }

  &__textarea {
    height: 144px;
  }

  &__textarea--large {
    height: 238px;
  }

  &__email {
    color: $color-link;
    font-weight: 600;
    font-size: $font-size-xl;
  }

  &__agreement {
    text-align: center;
    width: 100%;
    background-color: #FDEEF1;
    border-radius: 4px;
    padding-bottom: $space-m;
  }

  &__agreement-checkbox {
    width: 0;
    height: 0;
  }

  &__agreement--label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding-right: $space-l;
    padding-left: $space-l;
  }

  &__agreement--checkbox-mimic {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  &__agreement--checkbox-mimic-inner {
    display: block;
    flex-shrink: 0;
    width: $space-m;
    height: $space-m;
    border-radius: 4px;
    background: #FFFFFF;
    margin-right: 8px;
  }

  // 以下:checkedを使っているのは、チェックマークの表示（before afterの擬似要素を利用）
  &__agreement-checkbox:checked + &__agreement--label > &__agreement--checkbox-mimic > &__agreement--checkbox-mimic-inner {
    background: $color-primary;
    border: 1px solid $color-primary;
    position: relative;
    display: block;
  }

  &__agreement-checkbox:checked + &__agreement--label > &__agreement--checkbox-mimic > &__agreement--checkbox-mimic-inner::before {
    position: absolute;
    left: 2px;
    top: 9px;
    display: block;
    content: '';
    width: 5px;
    height: 2px;
    background: $color-white;
    transform: rotate(45deg);
    transform-origin: right center;
  }

  &__agreement-checkbox:checked + &__agreement--label > &__agreement--checkbox-mimic > &__agreement--checkbox-mimic-inner::after {
    display: block;
    position: absolute;
    left: 5.5px;
    top: 9px;
    content: "";
    width: 8px;
    height: 2px;
    background: #FFFFFF;
    transform: rotate(-45deg);
    transform-origin: left center;
  }

  &__agreement--checkbox-item-name {
    display: block;
    font-size: $font-size-s;
  }

  &__agreement--error-space {
    padding-top: $space-m;
    margin: 0 auto;
  }

  &__comment {
    margin-top: $space-s;
    color: $color-grey-2;
  }

  &__comment-narrow {
    margin-top: $space-xs;
    color: $color-grey-2;
  }

  &__comment-link {
    color: $color-link;
  }

  &__note {
    color: $color-grey-2;
    font-size: $font-size-m;
  }

  &__important {
    color: $color-important;
  }

  &__text {
    margin: 0 $space-s;

    &:first-child {
      margin: 0 $space-s 0 0;
    }

    &:last-child {
      margin: 0 0 0 $space-s;
    }
  }

  &__additional-text {
    display: block;
    @include par-s;
    margin-top: $space-s;
    color: $color-grey-2;
  }

  &__additional-text\@desktop {
    @include mq($from: desktop) {
      width: 170px;
    }
  }

  &__error-message {
    color: $color-important;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    margin-top: $space-xs;
  }

  &__error-message:empty {
    margin-top: 0;
  }
}
