@charset "utf-8";

.c-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background-color: $color-white;
  border-radius: $space-xs;
  z-index: index($zindex, loader);

  &--inline {
    position: relative;
    z-index: index($zindex, inline_loader);
  }

  &--inner-centered {
    position: absolute;
  }

  &--shadow {
    box-shadow: 0 1px 6px 0 rgba($color-grey-0, 0.2);
  }

  &__text {
    position: absolute;
    bottom: 10px;
    color: $color-primary;
    display: table;
    width: 100%;
    text-align: center;
  }

  &__inner {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
  }

  &__list {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: absolute;
    top: 50%;
    width: 10px;
    height: 10px;
    background-color: $color-primary;
    border-radius: 50%;
    animation: jumpjump 1.2s infinite;
  }

  // safari/iOS5のバグ回避のためmodal内の子要素は全てtranslate3dがあてられるので、modal内にinlineでloaderを
  // 配置する際にtranslateXが上書きされないように!importantを付与する
  // ref: https://github.com/medley-inc/job-medley/commit/d3df12305102e549022970723097b7f555229edd
  &__item:nth-child(1) {
    transform: translateX(0) !important;
  }

  &__item:nth-child(2) {
    transform: translateX(20px) !important;
    animation-delay: 0.12s;
  }

  &__item:nth-child(3) {
    transform: translateX(40px) !important;
    animation-delay: 0.24s;
  }
}
