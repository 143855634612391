@charset "utf-8";

.c-company-list {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid $color-grey-6;

  &\@desktop {
    @include mq($from: desktop) {
      border-top: 1px solid $color-grey-6;
      border-left: 1px solid $color-grey-6;
    }
  }

  &__item {
    border-right: 1px solid $color-grey-6;
    border-bottom: 1px solid $color-grey-6;
    width: calc(100% / 2);

    &:nth-child(2n) {
      border-right: 0;
    }

    &\@desktop {
      @include mq($from: desktop) {
        border-bottom: 1px solid $color-grey-6;
        width: calc(100% / 3);

        &:nth-child(2n) {
          border-right: 1px solid $color-grey-6;
        }
      }
    }
  }

  &__link {
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    vertical-align: middle;
    width: 100%;
    padding: $space-m;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .c-company-list {
    overflow: hidden;
  }
}
