@charset "utf-8";

.c-mypage-nav {

  &__item {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;
  }

  &__arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: $space-xs;
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transform: translateY(-50%);
    transition: $btn-transition;
  }

  &__link {
    display: block;
    position: relative;
    border-top: 1px solid $color-grey-6;
    color: $color-link;
    padding: $space-m $space-xl $space-m 0;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-card;
        border-radius: $space-s;
        color: $color-grey-0;
        text-decoration: none;
        padding-left: $space-m;

        .c-mypage-nav__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__item:first-of-type > &__link {
    border-top: none;
  }

  &__item:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
    }
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-mypage-nav__arrow {
        display: none;
      }

      .c-mypage-nav__link {
        border: none;
        margin-bottom: $space-m;
        padding: $space-xs 0;

        &:hover,
        &--current {
          box-shadow: none;
          border-radius: 0;
          border-left: $space-xs solid $color-primary;
          color: $color-grey-0;
          text-decoration: none;
          padding-left: $space-s;
        }
      }

      .c-mypage-nav__item:last-of-type > .c-mypage-nav__link {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }
}
