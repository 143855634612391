@charset "utf-8";

.c-information {
  display: flex;
  align-items: center;
  border-radius: $space-s;
  background: $color-green-bright;
  padding: $space-s;
  border: 1px solid $color-primary;

  &--important {
    border: 1px solid $color-important;
    background: rgba($color-important, $alpha-low);
  }

  &__icon {
    display: inline-block;
    margin-right: $space-s;
    width: $space-l;
    height: $space-l;
  }

  &__text {
    display: inline-block;
    color: $color-grey-1;
    font-weight: $font-weight-normal;
    font-size: $font-size-s;
    text-align: left;
  }
}
