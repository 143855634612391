@charset "utf-8";

.c-inquiry-card {
  border-radius: $space-s;
  border: 1px solid $color-primary;
  background: rgba($color-primary, $alpha-low);
  text-align: center;
  padding: $space-l;

  &--important {
    background: rgba($color: $color-important, $alpha: 0.1);
    border: 1px solid $color-important;
  }

  &__info-area {
    border-bottom: 1px solid $color-primary;
    padding-bottom: $space-l;
    margin-bottom: $space-l;
  }

  &__info-area--important {
    border: none;
    padding: 0;
    margin: 0;
  }

  &__btn-area--important {
    margin-top: $space-xs;
  }

  &__title {
    color: $color-grey-0;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-xs;
  }

  &__txt {
    color: $color-grey-1;
    font-weight: $font-weight-normal;
    line-height: $line-height-m;
    letter-spacing: $letter-spacing-normal;
    font-size: $font-size-m;
    margin-bottom: $space-xs;
  }

  &__tel {
    color: $color-grey-0;
    font-size: $font-size-4xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-s;
    margin-bottom: $space-s;
  }

  &__time {
    color: $color-grey-2;
    font-size: $font-size-xs;
    line-height: $line-height-s;
  }

  &\@desktop {
    @include mq($from: desktop) {
      display: inline-flex;

      .c-inquiry-card__info-area {
        border: none;
        border-right: 1px solid $color-primary;
        text-align: left;
        padding: 0 $space-l 0 0;
        margin: 0 $space-l 0 0;
      }

      .c-inquiry-card__info-area--important {
        border: none;
        padding: 0;
        margin: 0;
      }

      .c-inquiry-card__btn-area {
        display: flex;
        align-items: center;
      }
    }
  }
}
