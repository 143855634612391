@charset "utf-8";

.c-step-box {
  width: 100%;
  height: 26px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq($from: desktop) {
    width: 545px;
  }

  &__item {
    position: relative;
    list-style-type: none;
    text-align: center;
    text-transform: uppercase;
    width: 20%;
    color: $color-grey-3;
    font-weight: $font-weight-bold;
    counter-increment: steps;
  }

  &__item::before {
    position: absolute;
    z-index: 1;
    width: 26px;
    height: 26px;
    margin: 0 auto 0 -13px;
    line-height: 26px;
    font-size: $font-size-s;
    text-align: center;
    border-radius: 50%;
    background-color: $color-grey-5;
    content: counter(steps);
  }

  // for IE11
  *::-ms-backdrop,
  &__item::before {
    margin-left: 39px;
  }

  &__item::after {
    position: absolute;
    z-index: 0;
    top: 13px;
    left: -50%;
    width: 100%;
    height: 2px;
    content: '';
    background-color: $color-grey-5;
  }

  &__item:first-child::after {
    content: none;
  }

  &__item--active,
  &__item--complete {
    color: $color-grey-3;
  }

  &__item--active::before,
  &__item--complete::before {
    background-color: $color-primary;
    color: $color-white;
  }

  &__item--active::after,
  &__item--complete::after {
    background-color: $color-primary;
  }
}
