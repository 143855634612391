@charset "utf-8";

.c-txt-button-group {
  display: flex;
  align-items: center;

  &__button {
    margin-left: $space-s;
    flex-shrink: 0;
  }

  &__button\@desktop {
    @include mq($from: desktop) {
      margin-left: $space-m;
    }
  }
}
