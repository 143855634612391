@charset "utf-8";

.c-search-condition-list {

  &__item {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;
  }

  &__item--space {
    padding: $space-m 0;
    border-top: 1px solid $color-grey-6;
  }

  &__icon {
    fill: $color-primary;
    height: $space-l;
    width: $space-l;
    margin-right: 1em;
    flex: 0 0 $space-l;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transition: $btn-transition;
  }

  &__search {
    border-top: 1px solid $color-grey-6;
    padding: $space-m 0;
    color: $color-link;
    position: relative;
    display: block;
    align-items: center;
    transition: $btn-transition;
    @include mq($from: desktop) {
      display: flex;
    }
  }

  &__link {
    border-top: 1px solid $color-grey-6;
    padding: $space-m 0;
    color: $color-link;
    position: relative;
    display: flex;
    align-items: center;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-top;
        border-radius: $space-s;
        padding: $space-m;
        color: $color-grey-0;

        .c-search-condition-list__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__name {
    white-space: nowrap;
  }

  &__item:first-child > &__link {
    border-top-color: transparent;
  }

  &__item:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
    }
  }

  &__tags {
    overflow: hidden;
    margin-left: auto;
    margin-right: $space-m;
    margin-bottom: - $space-xs;
    padding-left: $space-m;
    font-weight: $font-weight-normal;
  }

  &__tags-item {
    float: left;
    margin-left: $space-xs;
    margin-bottom: $space-xs;
  }

  &__tags-item:empty {
    display: none;
  }

  &__caption {
    color: $color-grey-2;
    font-size: $font-size-s;
    font-weight: $font-weight-normal;
    display: block;
  }
}
