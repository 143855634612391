@charset "utf-8";

.c-company-search {

  &\@desktop {
    @include mq($from: desktop) {
      display: flex;
      flex-direction: row-reverse;
      padding: $space-m 0 $space-m $space-m;
      border-top: solid 1px $color-grey-6;
      margin-top: -1px;
    }
  }

  &__change\@desktop {
    @include mq($from: desktop) {
      display: flex;
      align-items: center;
      flex: 1 1 50px;
    }
  }

  &__input {
    padding: $font-size-l;
  }

  &__input\@desktop {
    @include mq($from: desktop) {
      flex: 7 1 475px;
      padding: 0;
    }
  }

  &__link\@mobile {
    @include mq($until: desktop) {
      display: flex;
      align-items: center;
      padding: $space-m;
      transition: $btn-transition;
      color: $color-link;
      border-bottom: 1px solid $color-grey-6;
      font-weight: $font-weight-bold;
    }
  }

  &__link\@desktop {
    @include mq($from: desktop) {
      @include title-s;
      color: $color-link;
      margin-left: $space-l;

      &:hover {
        color: $color-grey-0;
        text-decoration: underline;
      }
    }
  }

  &__icon {
    @include icon-l;
    display: inline-block;
    line-height: $space-l;
    margin-right: $space-s;
    flex: 0 0 $space-l;
  }

  &__arrow {
    @include icon-m;
    display: inline-block;
    line-height: $space-m;
    margin-left: auto;
    flex: 0 0 auto;
    fill: $color-grey-3;
  }
}
