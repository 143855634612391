@charset "utf-8";

@mixin card-hover {
  box-shadow:
    0 8px 48px 0 rgba(0, 0, 0, 0.16),
    0 1px 1px 0 rgba(0, 0, 0, 0.04);
  transform: scale(1.025);
  z-index: 1;

  // カード自体にcursor: ponter;が付与されていると内包するボタン要素でのtap-highlight-colorが効かないので、hover時のみcursor: pointer;を付与する
  cursor: pointer;
}
