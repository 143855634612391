@charset "utf-8";

.c-profile-lead {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $space-m;
  background-color: $color-white;
  border-radius: $space-s;
  text-align: center;

  &--celebration {
    background: url('../images/pictures/bg_guide_incentive_sp.png') no-repeat top center rgba(255, 237, 241, 1);
    background-size: 100% auto;
  }

  &--celebration\@desktop {
    @include mq($from: desktop) {
      background: url('../images/pictures/bg_guide_incentive_pc.png') no-repeat top center rgba(255, 237, 241, 1);
      background-size: 100% auto;
    }
  }

  &__closer {
    position: absolute;
    display: block;
    top: $space-s;
    right: $space-s;
    width: $space-s;
    height: $space-s;
    fill: $color-grey-3;
  }

  &__closer > svg {
    display: block;
  }

  &__button__arrow {
    display: inline-block;
    margin-left: 2px;
    fill: $color-white;
    width: $space-s;
    height: $space-s;
    transition: $btn-transition;
    transform-origin: bottom;
  }

  &__item--open > &__button__arrow {
    transform: rotate(180deg);
  }

  &__item + &__item {
    padding-top: $space-m;
  }

  &__item\@desktop + &__item\@desktop {
    padding-top: $space-s;
  }

  &__title {
    color: $color-important;
    font-size: $font-size-xl;
    font-weight: $font-weight-bold;
    line-height: $line-height-m;
  }

  &__text {
    color: $color-grey-1;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
  }

  &__text--celebration {
    color: $color-important;
  }

  &__link-list {
    height: 0;
    overflow: hidden;
    transition: $btn-transition;
  }

  &__link-list-wrapper {
    padding-top: $space-m;
  }

  &__link-list-inner {
    border-radius: $space-s;
    transition: $btn-transition;
    background-color: $color-grey-6;
  }

  &__link-item {
    transition: $btn-transition;
  }

  &__link-item:hover {
    @include mq($from: desktop) {
      background-color: $color-white;
      @include shadow-top;
    }
  }

  &__link-item:first-child {
    border-top-right-radius: $space-s;
    border-top-left-radius: $space-s;
  }

  &__link-item:last-child {
    border-bottom-right-radius: $space-s;
    border-bottom-left-radius: $space-s;
  }

  &__link-item + &__link-item {
    border-top: 1px solid $color-white;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: $space-m;
  }

  &__link-icon {
    display: block;
    flex-shrink: 0;
    width: $space-l;
    height: $space-l;
    fill: $color-primary;
  }

  &__link-title {
    display: block;
    padding-right: $space-s;
    color: $color-link;
    font-size: $font-size-l;
    font-weight: $font-weight-bold;
    text-align: left;
  }

  &__link-icon + &__link-title {
    margin-left: $space-s;
  }

  &__link-counter {
    margin-left: auto;
    margin-right: 0;
    color: $color-important;
    font-size: $font-size-s;
    font-weight: $font-weight-bold;
    white-space: nowrap;
  }
}
