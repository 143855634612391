@charset "utf-8";

.c-arrowed-list {

  &__item {
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    text-align: left;
    line-height: $line-height-s;

    &:first-child {
      border: none;
    }

    &:hover {
      @include mq($from: desktop) {
        border-color: transparent;
      }
    }
  }

  &__item--large {
    font-size: $font-size-l;
  }

  &__arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: $space-xs;
    fill: $color-grey-3;
    height: $space-m;
    width: $space-s;
    transform: translateY(-50%);
    transition: $btn-transition;
  }

  &__link {
    display: block;
    position: relative;
    border-top: 1px solid $color-grey-6;
    color: $color-link;
    padding: $space-m $space-xl $space-m 0;
    transition: $btn-transition;

    &:hover {
      @include mq($from: desktop) {
        @include shadow-card;
        border-radius: $space-s;
        color: $color-grey-0;
        text-decoration: none;
        padding-left: $space-m;

        .c-arrowed-list__arrow {
          right: $space-m;
        }
      }
    }
  }

  &__item:last-of-type > &__link {
    border-bottom: 1px solid $color-grey-6;
  }

  &__item:hover + &__item > &__link {
    @include mq($from: desktop) {
      border-top-color: transparent;
    }
  }
}
