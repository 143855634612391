@charset "utf-8";

.c-background {
  background-color: $color-white;

  &--main {
    background-color: $color-grey-7;
  }

  &\@desktop {
    @include mq($from: desktop) {
      background-color: $color-white;
    }
  }
}
