@charset "utf-8";

.c-offers-column {

  &__map {
    text-align: center;
  }

  &__map\@desktop {
    @include mq($from: desktop) {
      text-align: left;
    }
  }

  &__map-inner {
    position: relative;
    width: 280px;
    min-width: 280px;
    height: 320px;
    min-height: 320px;
    margin: 0 auto;
    text-align: center;

    &__image {
      width: 100%;
    }
  }

  &__map-caption {
    font-size: $font-size-s;
    line-height: $line-height-m;
  }

  &__detail {
    position: relative;
    margin-top: $space-l;
  }

  &__ellipsis-inner {
    color: $color-grey-1;
    font-size: $font-size-m;
    line-height: $line-height-m;
    @include ellipsis-multiple-line(3, $font-size-m, $line-height-m, $color: $color-grey-1);
  }

  &__ellipsis-inner--invalidate\@desktop {
    @include mq($from: desktop) {
      overflow-y: visible;
      height: auto;
      max-height: 100%;
    }
  }

  &__show-more {
    display: block;
    text-align: right;
    color: $color-link;
    font-size: $font-size-m;
    font-weight: $font-weight-bold;
    background-color: $color-white;
    line-height: $line-height-m;
  }

  &__ellipsis-inner + &__show-more {
    display: block;
  }

  &__ellipsis-inner--invalidate\@desktop + &__show-more {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__ellipsis-inner--invalidate\@desktop::before,
  &__ellipsis-inner--invalidate\@desktop::after {
    @include mq($from: desktop) {
      display: none;
    }
  }

  &__ellipsis-inner--visible {
    overflow-y: visible;
    height: auto;
    max-height: 100%;
  }

  &__ellipsis-inner--visible::before,
  &__ellipsis-inner--visible::after {
    display: none;
  }

  &__ellipsis-inner + &__show-more:hover {
    @include mq($from: desktop) {
      text-decoration: underline;
    }
  }

  &__recruit {
    margin-top: $space-l;
  }

  &\@desktop {
    @include mq($from: desktop) {

      .c-offers-column__row {
        display: flex;
        flex-direction: row-reverse;
      }

      .c-offers-column__map {
        padding: 0 0 0 $space-l;
      }

      .c-offers-column__detail {
        margin: 0;
      }
    }
  }
}
